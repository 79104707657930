import React, { useState, useEffect, PropsWithChildren } from 'react';
import { debounce } from 'lodash';
import { device } from '../styles/constants';

const mediaCheck = (query: string) => window.matchMedia(query).matches;

type Media = {
  isPC: boolean;
  isTablet: boolean;
  isMobile: boolean;
};

const MediaContext = React.createContext<Media>({
  isPC: true,
  isTablet: false,
  isMobile: false,
});

const MediaProvider: React.FC<PropsWithChildren> = (props) => {
  const [result, setResult] = useState({
    isPC: true,
    isTablet: false,
    isMobile: false,
  });

  useEffect(() => {
    const check = () => {
      const isPC = mediaCheck(device.exceptTablet);
      const isMobile = mediaCheck(device.mobile);
      const isTablet = !isPC && !isMobile;
      if (
        result.isPC !== isPC ||
        result.isTablet !== isTablet ||
        result.isMobile !== isMobile
      ) {
        setResult({ isPC, isTablet, isMobile });
      }
    };
    const onResize = debounce(check, 200);

    window.addEventListener('resize', onResize);
    check();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [result]);

  return (
    <MediaContext.Provider
      value={{
        ...result,
      }}
    >
      {props.children}
    </MediaContext.Provider>
  );
};

export { MediaContext, MediaProvider };
