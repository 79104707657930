import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

export const ASSETS = {
  NAV: {
    DATA: 'icon-nav-data.svg',
    AGREEMENT: 'icon-nav-agreement.svg',
    OTHER: 'icon-nav-other.svg',
    SUPPORT: 'icon-nav-support.svg',
    HELP: 'icon-nav-help.svg',
    SETTING: 'icon-nav-setting.svg',
    PURCHASE: 'icon-nav-purchase.svg',
    LOGOUT: 'icon-nav-logout.svg',
    DEVICE: 'icon-nav-device.svg',
  },
  PROCEDURE: {
    IDENTIFICATION: 'icon-sim-identification2.svg',
    CHECK: 'icon-check.svg',
    MNP: 'icon-sim-mnp2.svg',
    TRACKING: 'icon-sim-tracking.svg',
  },
  COMMON: {
    POINT: 'icon-point.svg',
    DATA: 'icon-nav-data.svg',
    ATTENTION: 'icon-attention.svg',
    ADD: 'icon-add.svg',
    DELETE: 'icon-delete.svg',
    INFORMATION: 'icon-information.svg',
    CAMPAIGN: 'icon_campaign.svg',
    IDENTIFICATION: 'icon-sim-identification.svg',
    MNP: 'icon-sim-mnp.svg',
    ENTRYCODE: 'icon-signup-entrycode.svg',
    ID: 'icon-signup-id.svg',
    CREDITCARD: 'icon-signup-creditcard.svg',
    MAIL: 'icon-signup-mail.svg',
    PURCHASE: 'icon-nav-purchase.svg',
    COPY: 'icon-filecopy.svg',
    LINE: 'icon-line.svg',
    DOC_CHECK: 'icon-doc_check.svg',
    DEVICE: 'icon-nav-device.svg',
    CHECK: 'icon-check.svg',
  },
};

const IconContainer = styled.div`
  display: inline-flex;
  text-align: center;
  align-self: center;
  width: 1em;
  height: 1em;
  > svg {
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const icons = require.context('../../assets/icons', true, /\.svg$/);

interface IconProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
}

const Icon = ({ name, className, style }: IconProps) => {
  const LoadedIcon = icons(`./${name}`).default;
  return (
    <IconContainer className={className} style={style}>
      <LoadedIcon />
    </IconContainer>
  );
};

Icon.ASSETS = ASSETS;

export default Icon;
