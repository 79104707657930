import React from 'react';
import useLogout from '../../hooks/useLogout';
import { GlobalConfig } from '../../constants';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import { SimpleLayout } from '../Layout';
import Boxes from '../Common/Boxes';
import CustomerCenter from '../CustomerCenter';

interface Props {
  isGuest?: boolean;
}

const SuspensionError: React.FC<Props> = ({ isGuest }) => {
  const logout = useLogout();
  return (
    <SimpleLayout>
      <Texts.H1>サービス停止中</Texts.H1>
      <Boxes.Red>
        <Texts.Warning>
          お客様のy.u mobileサービスを強制停止中です。
          {'\n'}
          停止に関するお問い合わせやサービスの解約はカスタマーセンターにお問い合わせください。
        </Texts.Warning>
      </Boxes.Red>
      <Texts.H2>お問い合わせ</Texts.H2>
      <Boxes.White>
        <CustomerCenter />
        <Texts.Body1>
          お支払い方法の変更は
          <Texts.Link href={`${GlobalConfig.supportUrl}/payment`}>
            こちら
          </Texts.Link>
          から
        </Texts.Body1>
      </Boxes.White>
      {!isGuest && (
        <Button.Layout>
          {/* @ts-ignore */}
          <Button.Primary onClick={logout}>ログアウト</Button.Primary>
        </Button.Layout>
      )}
    </SimpleLayout>
  );
};

SuspensionError.defaultProps = {
  isGuest: false,
};

export default SuspensionError;
