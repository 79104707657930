import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { META_CONFIG } from './constants';

const Login = () => {
  const router = useRouter();
  // @ts-ignore
  const config = META_CONFIG[router.pathname] || META_CONFIG.default;

  return (
    <Head>
      <title>{config.title}</title>
      {config.description && (
        <meta name="description" content={config.description} />
      )}
      <link key="canonical" rel="canonical" href={config.canonical} />
      <meta key="robots" name="robots" content={config.robots} />
    </Head>
  );
};

export default Login;
