// @ts-nocheck
import { useReducer, useCallback } from 'react';
import crypto from 'crypto';
import fetch from 'isomorphic-unfetch';
// @ts-ignore
import { encrypt } from '@u-next/image-encrypt';
import { GlobalConfig } from '../constants';

const initialState = {
  uploading: false,
  uploadErrors: [],
  uploadTokens: [],
};

// @ts-ignore
// @ts-ignore
const reducer = (state, action) => {
  switch (action.type) {
    case 'started':
      return { ...initialState, uploading: true };
    case 'finished': {
      return {
        ...state,
        uploading: false,
        uploadTokens: action.tokens,
      };
    }
    case 'error': {
      return {
        ...state,
        uploading: false,
        uploadErrors: action.errors,
      };
    }
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

// @ts-ignore
const useSecureUpload = (rsaPublicKey) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const upload = useCallback(
    async (bufferOrArrayOfBuffers, resourceLifetimeSec) => {
      // @ts-ignore
      dispatch({ type: 'started' });
      // @ts-ignore
      const encryptAndUpload = (inBuf) => {
        const outBuf = Buffer.from(encrypt(inBuf, rsaPublicKey));
        const checkSum = crypto.createHash('sha256').update(outBuf).digest();
        const uploadBuf = Buffer.concat([checkSum, outBuf]);
        const uploadPromise = new Promise((resolve, reject) =>
          fetch(
            `${GlobalConfig.imageServerUrl}/upload?lifetimeInSec=${resourceLifetimeSec}`,
            {
              method: 'POST',
              // @ts-ignore
              headers: {
                'Content-Length': uploadBuf.length,
                'Content-Type': 'application/octet-stream',
              },
              body: uploadBuf,
            }
          )
            .then((response) => {
              if (response.status !== 200) {
                reject(new Error('送信エラー'));
              }
              return response.json();
            })
            .then((responseJson) => resolve(responseJson.imageToken))
            .catch((error) => reject(error))
        );
        return uploadPromise;
      };

      let promises = [];
      if (bufferOrArrayOfBuffers instanceof Array) {
        promises = bufferOrArrayOfBuffers.map((buffer) =>
          encryptAndUpload(buffer)
        );
      } else {
        promises.push(encryptAndUpload(bufferOrArrayOfBuffers));
      }
      Promise.all(promises)
        // @ts-ignore
        .then((tokens) => dispatch({ type: 'finished', tokens }))
        // @ts-ignore
        .catch((errors) => dispatch({ type: 'error', errors }));
    },
    [dispatch, rsaPublicKey]
  );
  return [upload, state];
};

export default useSecureUpload;
