import React from 'react';
import { range } from 'lodash';
import { NextRouter } from 'next/router';

export const scrollToTop = () => {
  const wrapper = document && document.getElementById('site-wrapper');
  if (wrapper) {
    wrapper.scrollTop = 0;
  }
};

export const getIncrementalOptions = (start: number, end: number) => {
  const min = start;
  let max = end + 1;
  let increment = 1;
  if (start > end) {
    max = end - 1;
    increment = -1;
  }
  return range(min, max, increment).map(number => (
    <option key={number} value={`${number}`}>
      {number}
    </option>
  ));
};

export const getAgeFromBirthDate = (birthDate: Date) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const thisYearBirthDay = new Date(
    today.getFullYear(),
    birthDate.getMonth(),
    birthDate.getDate()
  );
  if (today < thisYearBirthDay) {
    age = age - 1;
  }
  return age;
};

export const checkIsOverAge = (
  inputYear: number,
  inputMonth: number,
  inputDay: number,
  minAge: number
) => {
  return !!(
    inputYear &&
    inputMonth &&
    inputDay &&
    getAgeFromBirthDate(new Date(inputYear, inputMonth - 1, inputDay)) >= minAge
  );
};

export const checkIsValidDate = (
  inputYear: number,
  inputMonth: number,
  inputDay: number
) =>
  !!(
    inputYear &&
    inputMonth &&
    inputDay &&
    inputDay <= new Date(inputYear, inputMonth, 0).getDate()
  );

export const getFormattedDateString = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const getJapaneseDateString = (date: Date) =>
  `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;

export const getJapaneseDateStringByString = (dateString: string) =>
  dateString
    ? new Date(dateString).toLocaleString('ja-JP', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'Asia/Tokyo',
      })
    : '';

export const getUtcIsoString = (dateString: string) => {
  const date = new Date(dateString);
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  ).toISOString();
};

export const getDateStringWithSlash = (date: Date) => {
  return new Date(date).toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Asia/Tokyo',
  });
};

/**
 * epoch timeから、日本時間のその日の0時となるepoch timeを返す
 * @param  {Number} epochTime 例: 1582729200000
 */
export const getJSTMidnightFromEpochTime = (epochTime: number) => {
  const date = new Date(epochTime);
  const timezoneOffset = date.getTimezoneOffset() / 60;
  const newHour = -9 - timezoneOffset;
  const JSTMidnight = date.setHours(newHour, 0, 0, 0);
  return JSTMidnight;
};

/**
 * YYYY/M/D形式の日付文字列から、日本時間のその日の0時となるepoch timeを返す
 * @param  {String} string 例: 2020/2/27
 */
export const getJSTMidnightFromString = (string: string) => {
  const array = string.split(/\D+/);
  const year = Number(array[0]);
  const month = Number(array[1]);
  const day = Number(array[2]);
  const JSTMidnight = Date.UTC(year, month - 1, day - 1, 15);
  return JSTMidnight;
};

/**
 * YYYY-MM-DD〜形式の日付文字列から、YYYY/M/D文字列を返す
 * @param  {String} ISOString 例: 2020-02-27T00:00:00+09:00
 */
export const getJapaneseDateStringFromISOString = (ISOString: string) => {
  const array = ISOString.split(/\D+/);
  const year = Number(array[0]);
  const month = Number(array[1]);
  const day = Number(array[2]);
  return `${year}/${month}/${day}`;
};

export const addDaysAndGetEpochTime = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.getTime();
};

export const subtractDays = (refDate: Date, diff: number) => {
  const ref = new Date(refDate);
  ref.setDate(ref.getDate() - diff);
  return ref.toISOString();
};

export const subtractMonthsAndGetFirstDay = (refDate: Date, diff: number) => {
  const ref = new Date(refDate);
  ref.setDate(1);
  ref.setMonth(ref.getMonth() - diff);
  return ref.toISOString();
};

export const isActivePath = (router: NextRouter, route: RegExp) => {
  if (router && router.pathname.match(route)) {
    return true;
  }
  return false;
};

export const convertMBtoGB = (mb: number) => {
  // 1GB以上のとき小数点第2位を切り捨て、１GB未満のとき小数点第3位を切り捨て
  const gb = mb / 1024;
  if (gb < 0.01) {
    return '0';
  } else if (gb < 1) {
    return (Math.floor(gb * 100) / 100).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  return (Math.floor(gb * 10) / 10).toLocaleString(undefined, {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1,
  });
};

export const getApiError = ({
  code,
  message,
}: {
  code: number;
  message: string;
}) => {
  switch (code) {
    case 401:
      return new Error('リソースにアクセスができません。');
    case 404:
      return new Error('リソースが見つかりません。');
    default:
      return new Error(`サーバーエラー(${code}):${message}`);
  }
};

export const getCookieDomain = (host: string) => {
  if (host.match(/^localhost/)) {
    return 'localhost';
  } else {
    return host.replace(/^([a-zA-Z0-9-_]*\.)/, '');
  }
};

export const extractAlphanumeric = (string: string) =>
  string.replace(/[^\da-zA-Z]+/g, '');
