import styled, { css } from 'styled-components';
import { theme, typo } from '../../styles';
import { device } from '../../styles/constants';

// Headlines
const H1 = styled.h1`
  padding-bottom: 20px;
  position: relative;
  ${typo.XXL_B};
  color: ${({ theme }) => theme.text.primary};
  text-align: center;
  line-height: 1.5;
  :after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    width: 60px;
    height: 8px;
    margin: 0 auto;
    background: ${({ theme }) => theme.h1.accent};
    border-radius: 8px;

    @media ${device.mobile} {
      height: 6px;
    }
  }
  + * {
    margin-top: 56px !important;
  }

  @media ${device.mobile} {
    padding-bottom: 10px;
    ${typo.XL_B};
    + * {
      margin-top: 40px !important;
    }
  }
`;

const H2 = styled.h2`
  ${typo.XL};
  color: ${({ theme }) => theme.text.secondary};
  :not(:first-child) {
    margin-top: 56px;
  }

  @media ${device.mobile} {
    ${typo.L};
    :not(:first-child) {
      margin-top: 40px;
    }
  }
`;

const H3Css = css`
  ${typo.L_B};
  color: ${theme.text.primary};
  :not(:first-child) {
    margin-top: 40px;
  }

  @media ${device.mobile} {
    ${typo.M_B};
    :not(:first-child) {
      margin-top: 30px;
    }
  }
`;

const H3 = styled.h3`
  ${H3Css};
`;

const PlainH4Css = css`
  ${typo.M_B};
  color: ${theme.text.secondary};
`;

const PlainH4 = styled.h4`
  ${PlainH4Css};
`;

const H4Css = css`
  ${PlainH4Css};
  :not(:first-child) {
    margin-top: 60px;
  }
`;

const H4 = styled.h4`
  ${H4Css};
`;

// Paragraphs
const Body1Css = css`
  ${typo.M};
  line-height: 1.5;
  white-space: pre-wrap;
  :not(:first-child) {
    margin-top: 16px;
  }

  @media ${device.mobile} {
    ${typo.S};
  }
`;

const Body1 = styled.div`
  ${Body1Css};
`;

const Body2 = styled.div`
  ${typo.S};
  color: ${({ theme }) => theme.text.secondary};
  line-height: 1.5;
  white-space: pre-wrap;
  :not(:first-child) {
    margin-top: 16px;
  }

  @media ${device.mobile} {
    ${typo.XS};
  }
`;

const Body3 = styled.div`
  ${typo.XS};
  line-height: 1.5;
  color: ${({ theme }) => theme.text.secondary};
  white-space: pre-wrap;

  :not(:first-child) {
    margin-top: 16px;
  }
`;

const Body4 = styled.div`
  ${typo.XXS};
  line-height: 1.5;
  color: ${({ theme }) => theme.text.tertiary};
  white-space: pre-wrap;
`;

const CellName = styled.div`
  ${typo.M_B};
  color: ${({ theme }) => theme.text.tertiary};
`;

const Note = styled.div`
  ${typo.XS};
  color: ${({ theme }) => theme.text.tertiary};
  line-height: 1.5;
  white-space: pre-wrap;
  :not(:first-child) {
    margin-top: 16px;
  }

  @media ${device.mobile} {
    ${typo.XXS};
  }
`;

const Center = styled.div`
  ${Body1Css};
  text-align: center;
  white-space: pre-wrap;
`;

// Other text
const Link = styled.a`
  color: ${({ theme }) => theme.link.text};
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.link.textHovered};
  }
`;

const Error = styled.div`
  margin-top: 8px;
  ${typo.XS};
  color: ${({ theme }) => theme.text.warning};
`;

const Warning = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.text.warning};
  line-height: 1.5;
`;

const WarningSpan = styled.span`
  color: ${({ theme }) => theme.text.warning};
`;

const SpecialBig2nd = styled.div`
  font-family: Helvetica, Arial, sans-serif;
  ${typo.XXXL_B};
  color: ${({ theme }) => theme.text.special};
`;

const Strong = styled.span`
  ${typo.L_B};
`;

const Texts = {
  // Texts without layout formatting
  Plain: {
    H4: PlainH4,
  },
  H1,
  H2,
  H3,
  H4,
  H3Css,
  H4Css,
  Body1,
  Body1Css,
  Body2,
  Body3,
  Body4,
  CellName,
  Note,
  Center,
  Link,
  Error,
  Warning,
  WarningSpan,
  SpecialBig2nd,
  Strong,
};

export default Texts;
