import { css } from 'styled-components';
import typo from './typo';
import theme from './theme';
import { device } from './constants';

const INPUT_BOX = css`
  height: 56px;
  margin-top: 8px;
  background-color: ${theme.input.background};
  border: 2px solid
    ${({
      // @ts-ignore
      hasError,
    }) =>
      // @ts-ignore
      hasError ? theme.input.borderError : theme.input.border};
  border-radius: 10px;
  :not(:last-child) {
    margin-right: 8px;
  }

  @media ${device.mobile} {
    height: 48px;
  }
`;

const INPUT_CONTENT = css`
  padding: 0 24px;
  background-color: ${theme.input.background};
  ${typo.M};

  @media ${device.mobile} {
    padding: 0 8px;
  }
`;

const INPUT_FOCUS = css`
  :focus,
  :focus-within {
    box-shadow: 0 0 0 3px ${theme.input.focus};
    outline: none;
  }
`;

const mixinStyle = {
  INPUT_BOX,
  INPUT_CONTENT,
  INPUT_FOCUS,
};

export default mixinStyle;
