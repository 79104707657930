import React from 'react';
import { useUserContext } from '../../context/UserContext';
import useLogout from '../../hooks/useLogout';
import Button from '../Common/Button';
import Boxes from '../Common/Boxes';
import Texts from '../Common/Texts';
import { SimpleLayout } from '../Layout';

const NonApplicableError = () => {
  const logout = useLogout('/navlp');
  const { userInfo } = useUserContext();
  return (
    <SimpleLayout>
      <Texts.H1>お申し込み</Texts.H1>
      <Texts.Body1>
        お客様が現在ログインしているU-NEXT IDではお申し込みいただけません。
        {`\n`}本サービスをご利用いただく場合は
        {/* @ts-ignore */}
        <Button.TextLink onClick={logout}>ログアウト</Button.TextLink>
        し、新規U-NEXT IDでお申し込みください。
      </Texts.Body1>
      <Boxes.GrayCentered>{`ログイン中のU-NEXT ID : ${userInfo?.loginId}`}</Boxes.GrayCentered>
      <Button.Layout>
        {/* @ts-ignore */}
        <Button.Secondary onClick={logout}>ログアウト</Button.Secondary>
      </Button.Layout>
    </SimpleLayout>
  );
};

export default NonApplicableError;
