import Icon from '../Common/Icon';

export const BUTTONS = {
  ID_UPLOAD: {
    text: '本人確認手続きへ',
    // @ts-ignore
    getHref: ({ simUserApplicationId }) =>
      `/mypage/honnin?suaid=${simUserApplicationId}`,
  },
  MNP: {
    text: 'MNP開通手続きへ',
    getHref: () => `/mnp`,
  },
  AGREE: {
    text: '同意ページを共有する',
  },
};

export const STEP_CONFIG = {
  WAIT_FOR_AGREEMENT: {
    iconName: Icon.ASSETS.PROCEDURE.CHECK,
    title: 'お申し込み同意待ち',
    message:
      '追加SIM契約者に同意ページを共有し、お申し込み内容の同意を得てください。',
  },
  READY_FOR_ID_UPLOAD: {
    iconName: Icon.ASSETS.PROCEDURE.IDENTIFICATION,
    title: '本人確認書類待ち',
    message: '本人確認書類をアップロードしてください。',
  },
  REVIEWING: {
    iconName: Icon.ASSETS.PROCEDURE.IDENTIFICATION,
    title: '本人確認審査中',
    message:
      '審査完了までお待ちください。\n審査は1〜3日ほどかかる場合がございます。',
  },
  REJECTED: {
    iconName: Icon.ASSETS.PROCEDURE.IDENTIFICATION,
    title: '審査結果確認待ち',
    message: '本人確認書類審査の結果をご確認の上、再度手続きを行ってください。',
  },
  PREPARING_TO_SHIP: {
    iconName: Icon.ASSETS.PROCEDURE.TRACKING,
    title: '配送準備中',
    message: '手続きが完了しました。SIMが到着するまでお待ちください。',
  },
  READY_FOR_MNP_PROCESS: {
    iconName: Icon.ASSETS.PROCEDURE.MNP,
    title: 'MNP開通手続き待ち',
    message: 'MNP開通手続きを行ってください。',
  },
  ACTIVATING_MNP: {
    iconName: Icon.ASSETS.PROCEDURE.MNP,
    title: 'MNP開通手続き中',
    message: '開通手続き完了までお待ち下さい。',
  },
  SHIPPED: {
    iconName: Icon.ASSETS.PROCEDURE.TRACKING,
    title: 'SIM発送完了',
    message: 'SIMが到着するまでお待ち下さい。',
  },
  SHIPPED_MNP: {
    iconName: Icon.ASSETS.PROCEDURE.TRACKING,
    title: 'SIM発送完了',
    message:
      'SIMが到着するまでお待ち下さい。到着後、MNP転入の方はMNP開通手続きを行ってください。',
  },
};
