import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { STATUS } from '../../constants';
import { useUserContext } from '../../context/UserContext';
import Boxes from '../Common/Boxes';
import FirstMessage from '../FirstMessage';
import Announcements from '../Announcements';
import ProcedureStep from '../ProcedureStep';
import OptionContractAttention from '../OptionContractAttention';
import Menu from '../Menu';
import Layout from './Layout';
import { device } from '../../styles/constants';
import { MediaContext } from '../../context/MediaContext';

const MainContainer = styled.div`
  ${Boxes.RoundedCss};
  display: flex;
  /* iOS Safariでborder-radiusが効かない問題に対処 */
  position: relative;
  z-index: 0;
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  min-width: 0;
  padding: 64px 32px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.mainContent.background};

  @media ${device.mobile} {
    padding: 48px 16px;
  }
`;

// @ts-ignore
const MenuLayout = ({ children }) => {
  const { isPC } = useContext(MediaContext);
  const { accountStatus, userInfo, announcements } = useUserContext();

  const { mobileServiceAccount } = userInfo ?? {};
  const { hasApplicationOrPlan, hasActiveOptions } = accountStatus ?? {};

  // 「ファーストメッセージ」の表示トリガー：
  // 「SIM申込みがひとつもない」
  // かつ
  // 「すべてのSIMが無効（SIM利用者ステータスがキャンセル済み、解約済み）、もしくは紐づくSIMがひとつもない場合に表示」
  // https://wiki.unext-info.jp/pages/viewpage.action?pageId=54465931
  const showFirstMessage = !hasApplicationOrPlan;

  //   // 「外部オプション契約中のアテンション」の表示トリガー：
  //   // すべてのSIMが無効かつ、外部オプションがひとつでも利用中の場合に表示
  //   // https://wiki.unext-info.jp/pages/viewpage.action?pageId=54465931
  const showOptionContractAttention = !hasApplicationOrPlan && hasActiveOptions;

  return (
    <Layout isWide hasMobileMenu={!isPC}>
      <>
        {showFirstMessage && <FirstMessage />}
        {announcements && announcements.length > 0 && (
          <Announcements announcements={announcements} />
        )}
        {mobileServiceAccount && (
          // @ts-ignore
          <ProcedureStep
            // @ts-ignore
            simUserApplications={(
              mobileServiceAccount.simUserApplications ?? []
            ).filter(app => !!app)}
            // @ts-ignore
            deliveries={(mobileServiceAccount.deliveries ?? []).filter(
              delivery =>
                delivery &&
                (delivery.deliveryStatus === STATUS.DELIVERY.APPLYING ||
                  delivery.deliveryStatus === STATUS.DELIVERY.CANCELED ||
                  delivery.deliveryStatus === STATUS.DELIVERY.DELIVERING)
            )}
          />
        )}
        {showOptionContractAttention && <OptionContractAttention />}
        <MainContainer>
          {isPC && <Menu />}
          <Content>{children}</Content>
        </MainContainer>
      </>
    </Layout>
  );
};

MenuLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MenuLayout;
