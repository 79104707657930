import styled, { css } from 'styled-components';
import { typo, theme } from '../../styles';
import { device } from '../../styles/constants';

const Container = styled.div`
  :not(:first-child) {
    margin-top: 8px;
  }
  a {
    text-decoration: none;
  }
`;

const RowCss = css`
  display: flex;
  padding: 16px 24px;
  box-sizing: border-box;
  ${typo.S};
  line-height: 1.5;
  border-top: 1px solid ${theme.table.border};
  :last-child {
    border-bottom: 1px solid ${theme.table.border};
  }
  > * {
    flex: 1;
  }

  @media ${device.mobile} {
    padding: 8px;
    ${typo.XS};
  }
`;
const Row = styled.div`
  ${RowCss};
`;

const RowVCentered = styled.div`
  ${RowCss};
  align-items: center;
`;

const Key = styled.div`
  ${typo.S_B};
  color: ${({ theme }) => theme.table.text.key};

  @media ${device.mobile} {
    ${typo.XS_B};
  }
`;

const ValueCss = css`
  margin-left: 8px;
  color: ${theme.table.text.value};
  word-break: break-word;
  a {
    text-decoration: underline;
  }
`;

const Value = styled.div`
  ${ValueCss};
`;

const ValueRight = styled.div`
  ${ValueCss};
  text-align: right;
`;

const Table = {
  Container,
  Row,
  RowVCentered,
  Key,
  Value,
  ValueRight,
};

export default Table;
