// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
// @ts-ignore
import Logo from '../../assets/logos/logo-white.svg';
import { typo } from '../../styles';
import { device } from '../../styles/constants';

const MenuButton = styled.button`
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  background: ${({ theme }) => theme.menu.button.background};
  border-radius: 10px;
  > div {
    height: 5px;
    width: 26px;
    background: ${({ theme }) => theme.menu.button.accent};
    border-radius: 5px;
    :not(:first-child) {
      margin-top: 5px;
    }
  }
  @media ${device.exceptTablet} {
    display: none;
  }
`;

const LogoContainer = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.logo};
  svg {
    height: 40px;
  }
`;

const Welcome = styled.div`
  margin-left: 32px;
`;

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  padding: 24px 32px 16px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.text.primaryInverted};
  ${typo.S};

  @media ${device.tablet} {
    position: fixed;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    height: 78px;
    width: 100%;
    padding: 0 62px;
    background: linear-gradient(
      90deg,
      ${({ theme, isApplicationColor }) =>
          isApplicationColor
            ? theme.header.gradient.secondary.start
            : theme.header.gradient.primary.start}
        0%,
      ${({ theme, isApplicationColor }) =>
          isApplicationColor
            ? theme.header.gradient.secondary.end
            : theme.header.gradient.primary.end}
        100%
    );
    box-shadow: 0px 2px 5px 0px ${({ theme }) => theme.header.boxShadow};
    ${typo.XXS};
    ${Welcome} {
      margin-top: 8px;
      margin-left: 0px;
    }
    + * {
      padding-top: 88px;
    }
    ${LogoContainer} {
      svg {
        height: 26px;
      }
    }
  }
`;

const Header = ({
  // @ts-ignore
  isApplicationColor,
  // @ts-ignore
  showMenuButton,
  // @ts-ignore
  userId,
  // @ts-ignore
  toggleShowMenu,
  // @ts-ignore
  isError,
}) => {
  return (
    <HeaderContainer isApplicationColor={isApplicationColor}>
      {showMenuButton && (
        <MenuButton onClick={toggleShowMenu}>
          <div />
          <div />
          <div />
        </MenuButton>
      )}
      {/* When error happens reload the homepage to clean up local state */}
      {isError ? (
        <LogoContainer href="/mypage">
          <Logo />
        </LogoContainer>
      ) : (
        <Link href="/mypage" passHref>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Link>
      )}

      {userId && <Welcome>{`ようこそ、${userId}さん`}</Welcome>}
    </HeaderContainer>
  );
};

Header.propTypes = {
  userId: PropTypes.string,
  isApplicationColor: PropTypes.bool,
  showMenuButton: PropTypes.bool,
  toggleShowMenu: PropTypes.func,
  isError: PropTypes.bool,
};

Header.defaultProps = {
  userId: null,
  isApplicationColor: false,
  showMenuButton: false,
  toggleShowMenu: () => {},
  isError: false,
};

export default Header;
