import * as Yup from 'yup';
import { checkIsValidDate, checkIsOverAge } from './helpers';

// @ts-ignore
export const createBirthDateSchema = minAge =>
  Yup.string()
    .required('生年月日を入力してください')
    .test('生年月日', '有効な日付を入力してください', dateString => {
      if (!dateString || !dateString.split('T')[1]) {
        return false;
      }
      const [inputYear, inputMonth, inputDay] = dateString
        .split('T')[0]
        .split('-');
      return checkIsValidDate(
        Number(inputYear),
        Number(inputMonth),
        Number(inputDay)
      );
    })
    .test('生年月日', `${minAge}歳未満の方は契約できません`, dateString => {
      if (!dateString || !dateString.split('T')[1]) {
        return false;
      }
      const [inputYear, inputMonth, inputDay] = dateString
        .split('T')[0]
        .split('-');
      return checkIsOverAge(
        Number(inputYear),
        Number(inputMonth),
        Number(inputDay),
        minAge
      );
    });
