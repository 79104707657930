// @ts-nocheck
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
import { MediaContext } from '../../context/MediaContext';
import { typo } from '../../styles';
import Button from '../Common/Button';
import Icon from '../Common/Icon';
import { device } from '../../styles/constants';

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  :not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.procedureStep.border};
  }
`;

const TableRow = styled.div`
  display: table-row;
  :not(:first-child) {
    > div {
      border-top: 1px solid ${({ theme }) => theme.procedureStep.border};
    }
  }
`;

const LeftCell = styled.div`
  display: table-cell;
  padding: 16px 16px 16px 0;
  white-space: nowrap;
  vertical-align: top;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    flex-wrap: wrap;
  }
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  > div:first-child {
    flex: 1;
  }
  > div:not(:first-child) {
    margin-left: 40px;
  }
`;

const RightCell = styled.div`
  position: relative;
  display: table-cell;
  width: 100%;
  padding: 16px 0 16px 16px;
  vertical-align: top;
  :before {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    bottom: 16px;
    width: 1px;
    background-color: ${({ theme }) => theme.procedureStep.border};
  }
`;

const StyledButton = styled.button`
  ${Button.BaseCss};
  min-height: 48px;
  max-width: 320px;
  min-width: 240px;
  width: 100%;
  padding: 0 16px;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.procedureStep.gradient.start} 0%,
    ${({ theme }) => theme.procedureStep.gradient.end} 100%
  );
  ${typo.L_B};
  color: ${({ theme }) => theme.procedureStep.button.text};

  @media ${device.mobile} {
    ${typo.S_B};
    margin-top: 16px;
    min-height: 40px;
  }
`;

const Title = styled.div`
  ${typo.M_B};
  color: ${({ theme }) => theme.procedureStep.text};

  @media ${device.mobile} {
    ${typo.XL_B};
  }
`;

const SubTitle = styled.div`
  margin-top: 8px;
  ${typo.XS};
  color: ${({ theme }) => theme.procedureStep.text};

  @media ${device.mobile} {
    margin-right: 8px;
    ${typo.XSS};
    white-space: nowrap;
  }
`;

const StyledIcon = styled(Icon)`
  &&& {
    margin-right: 8px;
    font-size: 24px;
    stop:first-child {
      stop-color: ${({ theme }) => theme.procedureStep.gradient.start};
    }
    stop:last-child {
      stop-color: ${({ theme }) => theme.procedureStep.gradient.end};
    }

    @media ${device.mobile} {
      font-size: 28px;
    }
  }
`;

const Message = styled.div`
  ${typo.S};
  color: ${({ theme }) => theme.text.primary};
  line-height: 1.5;
  white-space: pre-wrap;

  @media ${device.mobile} {
    margin-top: 16px;
    ${typo.XS};
  }
`;

const SubMessageContainer = styled.div`
  margin-top: 8px;
  ${typo.XS};
  line-height: 1.5;
  color: ${({ theme }) => theme.text.secondary};
  white-space: pre-wrap;
`;

const ErrorContainer = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.procedureStep.error.background};
  :not(:first-child) {
    margin-top: 8px;
  }
`;

const ErrorTitle = styled.div`
  ${typo.S_B};
  color: ${({ theme }) => theme.procedureStep.error.text};
`;

const ErrorMessage = styled.div`
  margin-top: 8px;
  ${typo.XS};
  color: ${({ theme }) => theme.text.tertiary};
  line-height: 1.5;
  white-space: pre-wrap;
`;

const Step = ({
  // @ts-ignore
  iconName,
  // @ts-ignore
  title,
  // @ts-ignore
  name,
  // @ts-ignore
  date,
  // @ts-ignore
  message,
  // @ts-ignore
  subMessage,
  // @ts-ignore
  error,
  // @ts-ignore
  button,
}) => {
  const { isMobile } = useContext(MediaContext);
  return isMobile ? (
    <CenterContainer>
      <Flex>
        <StyledIcon name={iconName} />
        <Title>{title}</Title>
      </Flex>
      <Flex>
        <SubTitle>{`${name} 様`}</SubTitle>
        {date && <SubTitle>{`お申し込み日:${date}`}</SubTitle>}
      </Flex>
      <div>
        <Message>{message}</Message>
        {subMessage && <SubMessageContainer>{subMessage}</SubMessageContainer>}
        {error && (
          <ErrorContainer>
            <ErrorTitle>{error.title}</ErrorTitle>
            <ErrorMessage>{error.message}</ErrorMessage>
          </ErrorContainer>
        )}
      </div>
      <div>
        {button && button.link && (
          <Link href={button.link}>
            <StyledButton>{button.text}</StyledButton>
          </Link>
        )}
        {button && button.onClick && (
          <StyledButton onClick={button.onClick}>{button.text}</StyledButton>
        )}
      </div>
    </CenterContainer>
  ) : (
    <TableRow>
      <LeftCell>
        <Flex>
          <StyledIcon name={iconName} />
          <Title>{title}</Title>
        </Flex>
        <SubTitle>{`${name} 様`}</SubTitle>
        {date && <SubTitle>{`お申し込み日:${date}`}</SubTitle>}
      </LeftCell>
      <RightCell>
        <FlexBetween>
          <div>
            <Message>{message}</Message>
            {subMessage && (
              <SubMessageContainer>{subMessage}</SubMessageContainer>
            )}
            {error && (
              <ErrorContainer>
                <ErrorTitle>{error.title}</ErrorTitle>
                <ErrorMessage>{error.message}</ErrorMessage>
              </ErrorContainer>
            )}
          </div>
          <div>
            {button && button.link && (
              <Link href={button.link}>
                <StyledButton>{button.text}</StyledButton>
              </Link>
            )}
            {button && button.onClick && (
              <StyledButton onClick={button.onClick}>
                {button.text}
              </StyledButton>
            )}
          </div>
        </FlexBetween>
      </RightCell>
    </TableRow>
  );
};

Step.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.node,
  error: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

Step.defaultProps = {
  subMessage: null,
  error: null,
  button: null,
};

export default Step;
