// OTA仮申込書のステータス
const TEMP_APP = {
  APPLIED: 'APPLIED', //申込
  JUDGING: 'JUDGING', //審査中
  JUDGED: 'JUDGED', //本人審査完了
  DECLINED: 'DECLINED', //審査NG
  REAPPLYING: 'REAPPLYING', // 再審査依頼
  REGISTERED: 'REGISTERED', //本登録完了
  OPENED: 'OPENED', //開通準備完了
  COMPLETED: 'COMPLETED', //開通完了
  CANCELED: 'CANCELED', //キャンセル
};

// 本登録申込書のステータス
const APP = {
  APPLIED: 'APPLIED', //申込
  JUDGEABLE: 'JUDGEABLE', //審査可能
  JUDGING: 'JUDGING', //審査中
  JUDGED: 'JUDGED', //本人審査完了
  IRREGULAR_CANCEL: 'IRREGULAR_CANCEL', //審査完了後キャンセル
  DECLINED: 'DECLINED', //審査NG
  REAPPLYING: 'REAPPLYING', // 再審査依頼
  CONTRACTED: 'CONTRACTED', //契約完了
  CANCELED: 'CANCELED', //キャンセル
};

// OTA仮SIM申込書のステータス
const TEMP_APP_SIM_USER = {
  APPLIED: 'APPLIED', //申込情報入力済み
  JUDGING: 'JUDGING', //審査中
  PENDING: 'PENDING', //審査中断
  JUDGED: 'JUDGED', //審査完了
  DECLINED: 'DECLINED', //不備
  REAPPLYING: 'REAPPLYING', // 再審査依頼
  REGISTERED: 'REGISTERED', //本登録完了
  OPENED: 'OPENED', //開通準備完了
  IRREGULAR_CANCEL: 'IRREGULAR_CANCEL', //審査完了後キャンセル
  COMPLETED: 'COMPLETED', //開通完了
  CANCELED: 'CANCELED', //無効
};

// 本登録SIM申込書のステータス
const APP_SIM_USER = {
  AGREEING: 'AGREEING', //利用者同意待ち
  APPLIED: 'APPLIED', //申込情報入力済み
  JUDGEABLE: 'JUDGEABLE', //審査待ち
  JUDGING: 'JUDGING', //審査中
  PENDING: 'PENDING', //審査中断
  JUDGED: 'JUDGED', //審査完了
  DECLINED: 'DECLINED', //不備
  IRREGULAR_CANCEL: 'IRREGULAR_CANCEL', //審査完了後キャンセル
  MNP_ACTIVATE_WAITING: 'MNP_ACTIVATE_WAITING', //MNP手続き待ち（GQLで追加）
  MNP_ACTIVATING: 'MNP_ACTIVATING', //MNP手続き中（GQLで追加）
  REAPPLYING: 'REAPPLYING', // 再審査依頼
  CANCELED: 'CANCELED', //無効
};

// SIM利用者のステータス
const SIM_USER = {
  AGREEING: 'AGREEING', //利用者同意待ち
  APPLIED: 'APPLIED', //本人確認書類待ち
  JUDGING: 'JUDGING', //審査中
  JUDGE_NG: 'JUDGE_NG', //審査NG
  JUDGE_OK: 'JUDGE_OK', //審査完了
  MNP_ACTIVATE_WAITING: 'MNP_ACTIVATE_WAITING', //MNP手続き待ち
  MNP_ACTIVATING: 'MNP_ACTIVATING', //MNP手続き中
  CANCELED: 'CANCELED', //キャンセル済み
  RUNNING: 'RUNNING', //利用中
  STOPPED: 'STOPPED', //利用停止中
  TERMINATED: 'TERMINATED', //解約済み
};

// MNP予約番号のステータス
const MNP = {
  APPLYING: 'APPLYING', // MNP転出予約番号発行依頼中
  ISSUE_FAILURE: 'ISSUE_FAILURE', // MNP転出予約番号発行失敗
  ACTIVE: 'ACTIVE', // MNP転出予約番号発行済
  USED: 'USED', // MNP転出済
  EXPIRED: 'EXPIRED', // MNP転出予約番号期限切れ
  CANCELING: 'CANCELING', // MNP転出予約番号キャンセル中
  CANCELED: 'CANCELED', // MNP転出予約番号キャンセル済
  CANCEL_FAILURE: 'CANCEL_FAILURE', // MNP転出予約番号キャンセル失敗
};

// SIM配送のステータス
const DELIVERY = {
  APPLYING: 'PREPARING', //出庫指示中
  DELIVERING: 'DELIVERING', //配送中
  BACKTRACKED: 'RETURNED_TO_SENDER', //不在戻り
  ARRIVED: 'ARRIVED', //到着済み
  CANCELED: 'CANCELED', //キャンセル済み
};

// プランのステータス
const SERVICE_CONTRACT = {
  APPLICATION_PROCESS: 'APPLICATION_PROCESS',
  APPLICATION_DONE: 'APPLICATION_DONE',
  CONTRACT_ACTIVE: 'CONTRACT_ACTIVE',
  CONTRACT_NON_ACTIVE: 'CONTRACT_NON_ACTIVE',
  CONTRACT_IN_GRACE_PERIOD: 'CONTRACT_IN_GRACE_PERIOD',
  CONTRACT_SUSPENDED: 'CONTRACT_SUSPENDED',
};

// モバイルシステムのBOS連携契約ステータス for 割り込み通話、留守電
const MOBILE_SYSTEM_BOS_CONTRACT = {
  APPLYING: 'APPLYING', // 申込手続き中
  APPLIED_ORDERING: 'APPLIED_ORDERING', // 申込完了依頼中
  APPLIED: 'APPLIED', // 申込完了
  ACTIVATING: 'ACTIVATING', // 契約手続中
  ACTIVATED: 'ACTIVATED', // 契約中
  CANCELING: 'CANCELING', // 申込キャンセル中（実質、yuでんわ用）
  CANCELED: 'CANCELED', // 申込キャンセル済（実質、yuでんわ用）
  FREEZED: 'FREEZED', // 解約中
  TERMINATED: 'TERMINATED', // 解約済み
  PLAN_CHANGED: 'PLAN_CHANGED', // プラン変更済（クライアントがこのステータスを見ることはない）
};

// モバイルシステムの契約ステータス for 国際ローミング、国際電話
const MOBILE_SYSTEM_CONTRACT = {
  UNAPPLIED: 'UNAPPLIED', // 未申し込み
  APPLYING: 'APPLYING', // 申し込み中
  RUNNING: 'RUNNING', // 利用中
  TERMINATING: 'TERMINATING', // 解約処理中
  TERMINATED: 'TERMINATED', // 解約済み
};

// BOSユーザーの契約ステータス
const BOS_CONTRACT = {
  ACTIVE: 'ACTIVE',
  SUSPENSION: 'SUSPENSION',
  WITHDRAWAL: 'WITHDRAWAL',
};

const STATUS = {
  TEMP_APP,
  APP,
  TEMP_APP_SIM_USER,
  APP_SIM_USER,
  SIM_USER,
  MNP,
  DELIVERY,
  SERVICE_CONTRACT,
  MOBILE_SYSTEM_CONTRACT,
  MOBILE_SYSTEM_BOS_CONTRACT,
  BOS_CONTRACT,
};

export default STATUS;
