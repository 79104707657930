import styled, { css } from 'styled-components';

const RowCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  > * {
    min-width: 0;
    :not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const Row = styled.div`
  ${RowCss};
  > * {
    flex: 1;
  }
`;

const RowCenter = styled.div`
  ${RowCss};
  align-items: center;
  justify-content: space-between;
`;

const RowCenterLeft = styled.div`
  ${RowCss};
  align-items: center;
`;

const RowBaseline = styled.div`
  ${RowCss};
  align-items: baseline;
`;

const Flex = {
  Row,
  RowCenter,
  RowCenterLeft,
  RowBaseline,
};

export default Flex;
