import * as Yup from 'yup';
import { gql } from '@apollo/client';
import { INPUT_FIELD, APPLICATION_TYPES } from '../../context/constants';
import { createBirthDateSchema } from '../../utils/schemas';

export const SIM_USER_MIN_AGE = 12;

export const SIM_ADD_STEPS = {
  INTRO: 'intro',
  PLAN_INPUT: 'planinput',
  USER_INPUT: 'userinput',
  CONFIRM: 'confirm',
  COMPLETE_VOICE: 'completevoice',
  COMPLETE_DATA: 'completedata',
  COMPLETE_TEMP: 'completetemp',
};

export const GET_PLAN_DATA = gql`
  query getPlanData {
    getAccount {
      userPlatformId
      runningPrice {
        id
        priceIncludingTax
      }
    }
    getSimTypes {
      id
      simTypeEnum
      displayRunningName
      displayCaption
      description
      runningPrice {
        id
        priceIncludingTax
      }
      requiredPlans {
        id
      }
    }
    getVoicePlans {
      id
      displayRunningName
      displayCaption
      runningPrice {
        id
        priceIncludingTax
      }
      requiredSimTypes {
        id
      }
    }
    getOptions {
      id
      displayRunningName
      optionTypeEnum
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const GET_CUSTOMER_DATA = gql`
  query getCustomerData {
    getAccount {
      userPlatformId
      contractHolder {
        id
        customerInfo {
          id
          firstName
          lastName
          firstNameKana
          lastNameKana
          gender
          dateOfBirth
          address {
            addressLine1
            addressLine2
            addressLine3
            prefecture
            postalCode
          }
          phoneNumber
        }
      }
    }
  }
`;

export const GET_CONFIRM_DATA = gql`
  query getConfirmData($simTypeId: ID!, $voicePlanId: ID) {
    getAccount {
      userPlatformId
      runningPrice {
        id
        priceIncludingTax
      }
      contractHolder {
        id
        customerInfo {
          id
          firstName
          lastName
          firstNameKana
          lastNameKana
          gender
          dateOfBirth
        }
      }
    }
    getSimTypes {
      id
      simTypeEnum
      runningPrice {
        id
        priceIncludingTax
      }
    }
    getSimType(simTypeID: $simTypeId) {
      id
      simTypeEnum
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
      displayRunningName
    }
    getVoicePlan(voicePlanID: $voicePlanId) {
      id
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
      displayRunningName
    }
    getOptions {
      id
      displayRunningName
      optionTypeEnum
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const GET_COMPLETE_TEMP_DATA = gql`
  query getCompleteTempData($applicationId: ID!) {
    getSimTypes {
      id
      simTypeEnum
      runningPrice {
        id
        priceIncludingTax
      }
    }
    getApplication(applicationId: $applicationId, activeOnly: true) {
      id
      options {
        ... on RunningIFilter {
          id
        }
        runningServiceId
        optionTypeEnum
        service {
          id
          displayRunningName
          runningPrice {
            id
            priceIncludingTax
          }
        }
      }
      simUserApplications {
        id
        applicationId
        simType {
          id
          applicationDateTime
          simTypeEnum
          service {
            id
            displayRunningName
            runningPrice {
              id
              priceIncludingTax
            }
          }
        }
        voicePlan {
          id
          service {
            id
            displayRunningName
            runningPrice {
              id
              priceIncludingTax
            }
          }
        }
        mnp {
          phoneNumber
          mnpNumber
          expiryDate
        }
        customerInfo {
          id
          firstName
          lastName
          firstNameKana
          lastNameKana
          gender
          dateOfBirth
          phoneNumber
          address {
            addressLine1
            addressLine2
            addressLine3
            prefecture
            postalCode
          }
        }
        entryCode
      }
    }
  }
`;

export const SUBMIT_NEW_SIM_APPLICATION = gql`
  mutation submitApplicationForNewSimUser(
    $simUser: NewSimUserInput!
    $optionInput: OptionInput
  ) {
    submitApplicationForNewSimUser(
      simUser: $simUser
      optionInput: $optionInput
    ) {
      id
      simUserApplication {
        id
        statusCode
      }
    }
  }
`;

const ERROR_MNP_TYPE = 'MNP予約番号は、10桁数字が必要です。';
const ERROR_PHONE_NUMBER_TYPE = '携帯電話の番号は、11桁の数字が必要です。';

export const PLAN_INPUT_SCHEMA = Yup.object().shape({
  [INPUT_FIELD.SIMTYPE_ID]:
    Yup.string().required('SIMタイプの選択が必要です。'),
  [INPUT_FIELD.APPLICATION_TYPE]:
    Yup.string().required('申し込み区分の選択が必要です。'),
  [INPUT_FIELD.MNP_CODE]: Yup.string().when(INPUT_FIELD.APPLICATION_TYPE, {
    is: APPLICATION_TYPES.MOBILE_NUMBER_PORTABILITY,
    then: (schema) =>
      schema.required(ERROR_MNP_TYPE).matches(/^[0-9]{10}$/, ERROR_MNP_TYPE),
  }),
  [INPUT_FIELD.MNP_CODE_DUE]: Yup.string().when(INPUT_FIELD.APPLICATION_TYPE, {
    is: APPLICATION_TYPES.MOBILE_NUMBER_PORTABILITY,
    then: (schema) => schema.required('MNP有効期限が必要です。'),
  }),
  [INPUT_FIELD.MNP_PHONE_NUMBER]: Yup.string().when(
    INPUT_FIELD.APPLICATION_TYPE,
    {
      is: APPLICATION_TYPES.MOBILE_NUMBER_PORTABILITY,
      then: (schema) =>
        schema
          .required(ERROR_PHONE_NUMBER_TYPE)
          .matches(/^[0-9]{11}$/, ERROR_PHONE_NUMBER_TYPE),
    }
  ),
  [INPUT_FIELD.IS_CONTRACT_HOLDER]:
    Yup.string().required('SIM名義の選択が必要です。'),
  [INPUT_FIELD.HAS_CONSENT]: Yup.bool().test(
    'consent',
    '同意が必要です。',
    // @ts-ignore
    (val) => val
  ),
  [INPUT_FIELD.HAS_CONSENT_NON_CONTRACTOR]: Yup.bool().when(
    INPUT_FIELD.IS_CONTRACT_HOLDER,
    {
      // @ts-ignore
      is: (val) => val !== 'yes',
      then: (schema) =>
        schema.test(
          'consent_non_contractor',
          '同意が必要です。',
          // @ts-ignore
          (val) => val
        ),
    }
  ),
  [INPUT_FIELD.ENTRY_CODE]: Yup.string().matches(
    /^\d{16}$/,
    'エントリーコードは半角数字16桁で入力してください'
  ),
});

export const USER_INPUT_SCHEMA = Yup.object().shape({
  [INPUT_FIELD.POSTAL_CODE]: Yup.string()
    .matches(/^\d{7}$/, '郵便番号を半角数字7桁で入力してください')
    .required('郵便番号を7桁の半角数字で入力してください'),
  [INPUT_FIELD.ADDR_PREF]: Yup.string().required('都道府県を選択してください'),
  [INPUT_FIELD.ADDR_1]: Yup.string()
    .trim()
    .max(255, '${max}字以内で入力してください')
    .required('市町村群を入力してください'),
  [INPUT_FIELD.ADDR_2]: Yup.string()
    .trim()
    .max(255, '${max}字以内で入力してください')
    .required('丁目番地を入力してください'),
  [INPUT_FIELD.ADDR_3]: Yup.string()
    .trim()
    .max(255, '${max}字以内で入力してください'),
  [INPUT_FIELD.LAST_NAME]: Yup.string().when(INPUT_FIELD.IS_CONTRACT_HOLDER, {
    is: 'no',
    then: (schema) =>
      schema
        .trim()
        .max(50, '${max}字以内で入力してください')
        .required('姓を入力してください'),
  }),
  [INPUT_FIELD.FIRST_NAME]: Yup.string().when(INPUT_FIELD.IS_CONTRACT_HOLDER, {
    is: 'no',
    then: (schema) =>
      schema
        .trim()
        .max(50, '${max}字以内で入力してください')
        .required('名を入力してください'),
  }),
  [INPUT_FIELD.LAST_NAME_KANA]: Yup.string().when(
    INPUT_FIELD.IS_CONTRACT_HOLDER,
    {
      is: 'no',
      then: (schema) =>
        schema
          .trim()
          .max(50, '${max}字以内で入力してください')
          .matches(/^[ァ-ヴー 　]*$/, '姓をカタカナで入力してください') // eslint-disable-line no-irregular-whitespace
          .required('姓をカタカナで入力してください'),
    }
  ),
  [INPUT_FIELD.FIRST_NAME_KANA]: Yup.string().when(
    INPUT_FIELD.IS_CONTRACT_HOLDER,
    {
      is: 'no',
      then: (schema) =>
        schema
          .trim()
          .max(50, '${max}字以内で入力してください')
          .matches(/^[ァ-ヴー 　]*$/, '名をカタカナで入力してください') // eslint-disable-line no-irregular-whitespace
          .required('名をカタカナで入力してください'),
    }
  ),
  [INPUT_FIELD.GENDER]: Yup.string().when(INPUT_FIELD.IS_CONTRACT_HOLDER, {
    is: 'no',
    then: (schema) => schema.required('性別を選択してください'),
  }),
  [INPUT_FIELD.BIRTH_DATE]: Yup.string().when(INPUT_FIELD.IS_CONTRACT_HOLDER, {
    is: 'no',
    then: () => createBirthDateSchema(SIM_USER_MIN_AGE),
  }),
  [INPUT_FIELD.TEL]: Yup.string()
    .matches(/^(0{1}\d{9,10})$/, '有効な電話番号を入力してください')
    .required('電話番号を入力してください'),
});
