import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '../styles';
import { UserProvider } from '../context/UserContext';
import { MarketingProvider } from '../context/MarketingContext';
import { MediaProvider } from '../context/MediaContext';
import AccountCheck from '../components/AccountCheck';
import Head from '../components/Head';
import ErrorBoundary from '../components/ErrorBoundary';
import TrackingTag from '../components/TrackingTag';
import { useApollo } from '../lib/apolloClient';
import '/static/nprogress.css';
import UserInfoTracker from '../components/UserInfoTracker';

function CoreApp({ Component, pageProps }: AppProps) {
  const isServer = typeof window === 'undefined';
  const apolloClient = useApollo(pageProps);

  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Head />
          <ErrorBoundary>
            <TrackingTag />
            <UserProvider>
              <MarketingProvider>
                <MediaProvider>
                  <AccountCheck>
                    <Component {...pageProps} isServer={isServer} />
                    <UserInfoTracker />
                  </AccountCheck>
                </MediaProvider>
              </MarketingProvider>
            </UserProvider>
          </ErrorBoundary>
        </>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default CoreApp;
