import { useMemo } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  NormalizedCacheObject,
  defaultDataIdFromObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import getConfig from 'next/config';
import { mergeDeep } from '@apollo/client/utilities';
import { AppProps } from 'next/app';

const { publicRuntimeConfig } = getConfig();

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

function createApolloClient(ctx?: GetServerSidePropsContext | NextPageContext) {
  const isBrowser = typeof window !== 'undefined';
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([
      errorLink,
      new HttpLink({
        uri: isBrowser
          ? publicRuntimeConfig.commandCenterUrl
          : process.env.COMMAND_CENTER_URL,
        credentials: 'include',
      }),
    ]),
    headers: ctx?.req
      ? {
          cookie: ctx.req.headers.cookie ?? '',
          'user-agent': ctx.req.headers['user-agent'] ?? '',
        }
      : {},
    cache: new InMemoryCache({
      dataIdFromObject: (object) => {
        switch (object.__typename) {
          case 'Account':
            return `Account:${object.userPlatformId}`;
          default:
            return defaultDataIdFromObject(object);
        }
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = mergeDeep(existingCache, initialState);

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps['pageProps']
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: AppProps['pageProps']) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
