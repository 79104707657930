import * as Yup from 'yup';
import { gql } from '@apollo/client';
import { graphql } from '../../gql/gql';
import { INPUT_FIELD, APPLICATION_TYPES } from '../../context/constants';
import { createBirthDateSchema } from '../../utils/schemas';

export const MIN_AGE = 18;

export const STEP = {
  CHOOSE_PLAN: 'chooseplan',
  CUSTOMER_INFO: 'customerinfo',
  CONFIRM: 'confirm',
  COMPLETE: 'complete',
  MODIFY_PLAN: 'modifyplan',
  MODIFY_INFO: 'modifyinfo',
};

export const GET_STEP1_DATA = gql`
  query getStep1Data {
    getPlans {
      id
      planTypeEnum
      displayRunningName
      displayCaption
      displaySubCaption
      includesVideoSubscription
      runningPrice {
        id
        priceIncludingTax
      }
    }
    getSimTypes {
      id
      simTypeEnum
      displayRunningName
      displayCaption
      description
      runningPrice {
        id
        priceIncludingTax
      }
      requiredPlans {
        id
      }
    }
    getVoicePlans {
      id
      displayRunningName
      displayCaption
      runningPrice {
        id
        priceIncludingTax
      }
      requiredSimTypes {
        id
      }
    }
    getOptions {
      id
      displayRunningName
      optionTypeEnum
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const GET_STEP2_DATA = graphql(`
  query getStep2Data {
    getUser {
      id
      customerInfo {
        id
        emailAddress
        firstName
        lastName
        firstNameKana
        lastNameKana
        gender
        dateOfBirth
        phoneNumber
        address {
          addressLine1
          addressLine2
          addressLine3
          prefecture
          postalCode
        }
      }
    }
    unextAccount {
      currentBillingMethod {
        __typename
        ... on PaymentCardInfo {
          cardNumber
          cardType
        }
        ... on SbpsV2PaymentInfo {
          code
          sbpsV2PaymentInfoName: name
        }
        ... on BasicPaymentInfo {
          basicPaymentInfoName: name
        }
        ... on MobilePaymentInfo {
          mobilePaymentInfoName: name
        }
      }
    }
  }
`);

export const GET_STEP3_DATA = gql`
  query getStep3Data($planId: ID!, $simTypeId: ID!, $voicePlanId: ID) {
    getUser {
      id
      customerInfo {
        id
        emailAddress
      }
    }
    getPlan(planID: $planId) {
      id
      displayRunningName
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
    }
    getSimType(simTypeID: $simTypeId) {
      id
      simTypeEnum
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
      displayRunningName
    }
    getVoicePlan(voicePlanID: $voicePlanId) {
      id
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
      displayRunningName
    }
    getOptions {
      id
      displayRunningName
      optionTypeEnum
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const SUBMIT_APPLICATION = gql`
  mutation submitApplication($application: ApplicationInput!) {
    submitApplication(application: $application) {
      id
      simUserApplication {
        id
      }
    }
  }
`;

const ERROR_MNP_TYPE = 'MNP予約番号は、10桁数字が必要です。';
const ERROR_PHONE_NUMBER_TYPE = '携帯電話の番号は、11桁の数字が必要です。';

// @ts-ignore
export const getStep1Schema = ({ hasEntryCode }) =>
  Yup.object().shape({
    [INPUT_FIELD.PLAN_ID]: Yup.string().required('プラン選択が必要です。'),
    [INPUT_FIELD.PLAN_CATEGORY_ID]:
      Yup.string().required('プラン選択が必要です。'),
    [INPUT_FIELD.SIMTYPE_ID]:
      Yup.string().required('SIMタイプの選択が必要です。'),
    [INPUT_FIELD.VOICEPLAN_ID]: Yup.string(),
    [INPUT_FIELD.OPTION_IDS]: Yup.array(),
    [INPUT_FIELD.APPLICATION_TYPE]:
      Yup.string().required('申し込み区分の選択が必要です。'),
    [INPUT_FIELD.MNP_CODE]: Yup.string().when(INPUT_FIELD.APPLICATION_TYPE, {
      is: APPLICATION_TYPES.MOBILE_NUMBER_PORTABILITY,
      then: (schema) =>
        schema.required(ERROR_MNP_TYPE).matches(/^[0-9]{10}$/, ERROR_MNP_TYPE),
    }),
    [INPUT_FIELD.MNP_CODE_DUE]: Yup.string().when(
      INPUT_FIELD.APPLICATION_TYPE,
      {
        is: APPLICATION_TYPES.MOBILE_NUMBER_PORTABILITY,
        then: (schema) => schema.required('MNP有効期限が必要です。'),
      }
    ),
    [INPUT_FIELD.MNP_PHONE_NUMBER]: Yup.string().when(
      INPUT_FIELD.APPLICATION_TYPE,
      {
        is: APPLICATION_TYPES.MOBILE_NUMBER_PORTABILITY,
        then: (schema) =>
          schema
            .required(ERROR_PHONE_NUMBER_TYPE)
            .matches(/^[0-9]{11}$/, ERROR_PHONE_NUMBER_TYPE),
      }
    ),
    ...(hasEntryCode
      ? {
          [INPUT_FIELD.ENTRY_CODE]: Yup.string()
            .matches(
              /^\d{16}$/,
              'エントリーコードは半角数字16桁で入力してください'
            )
            .required('エントリーコードが必要です。'),
        }
      : {}),
    [INPUT_FIELD.HAS_CONSENT]: Yup.bool().test(
      'consent',
      '同意が必要です。',
      // @ts-ignore
      (val) => val
    ),
  });

export const STEP1_MESSAGE = {
  I_FILTER_NOTE:
    '18歳未満の方が利用者となる場合、フィルタリングサービスの利用が法律上義務付けられているため、「安心フィルタリング」オプション（月額330円）を自動的に契約します。',
  I_FILTER_ENABLE: 'このSIMは18歳未満が利用する',
};

export const STEP2_SCHEMA = Yup.object().shape({
  [INPUT_FIELD.LAST_NAME]: Yup.string()
    .trim()
    .max(50, '${max}字以内で入力してください')
    .required('姓を入力してください'),
  [INPUT_FIELD.FIRST_NAME]: Yup.string()
    .trim()
    .max(50, '${max}字以内で入力してください')
    .required('名を入力してください'),
  [INPUT_FIELD.LAST_NAME_KANA]: Yup.string()
    .trim()
    .max(50, '${max}字以内で入力してください')
    .matches(/^[ァ-ヴー 　]*$/, '姓をカタカナで入力してください') // eslint-disable-line no-irregular-whitespace
    .required('姓をカタカナで入力してください'),
  [INPUT_FIELD.FIRST_NAME_KANA]: Yup.string()
    .trim()
    .max(50, '${max}字以内で入力してください')
    .matches(/^[ァ-ヴー 　]*$/, '名をカタカナで入力してください') // eslint-disable-line no-irregular-whitespace
    .required('名をカタカナで入力してください'),
  [INPUT_FIELD.POSTAL_CODE]: Yup.string()
    .matches(/^\d{7}$/, '郵便番号を半角数字7桁で入力してください')
    .required('郵便番号を7桁の半角数字で入力してください'),
  [INPUT_FIELD.ADDR_PREF]: Yup.string().required('都道府県を選択してください'),
  [INPUT_FIELD.ADDR_1]: Yup.string()
    .trim()
    .max(255, '${max}字以内で入力してください')
    .required('市町村群を入力してください'),
  [INPUT_FIELD.ADDR_2]: Yup.string()
    .trim()
    .max(255, '${max}字以内で入力してください')
    .required('丁目番地を入力してください'),
  [INPUT_FIELD.ADDR_3]: Yup.string()
    .trim()
    .max(255, '${max}字以内で入力してください'),
  [INPUT_FIELD.GENDER]: Yup.string().required('性別を選択してください'),
  [INPUT_FIELD.BIRTH_DATE]: createBirthDateSchema(MIN_AGE),
  [INPUT_FIELD.TEL]: Yup.string()
    .matches(/^(0{1}\d{9,10})$/, '有効な電話番号を入力してください')
    .required('電話番号を入力してください'),
  [INPUT_FIELD.CARD_NUM]: Yup.string()
    .matches(/^\d+$/, 'クレジットカード番号を半角数字で入力してください')
    .when(INPUT_FIELD.USE_CURRENT_CARD, {
      is: false,
      then: (schema) =>
        schema.required('クレジットカード番号を入力してください'),
    }),
  [INPUT_FIELD.CARD_VALID_MONTH]: Yup.string().when(
    INPUT_FIELD.USE_CURRENT_CARD,
    {
      is: false,
      then: (schema) =>
        schema.required('クレジットカード有効期限 月を選択してください'),
    }
  ),
  [INPUT_FIELD.CARD_VALID_YEAR]: Yup.string().when(
    INPUT_FIELD.USE_CURRENT_CARD,
    {
      is: false,
      then: (schema) =>
        schema.required('クレジットカード有効期限 年を選択してください'),
    }
  ),
  [INPUT_FIELD.CARD_NAME]: Yup.string()
    .matches(
      /^[A-Z ]+$/,
      'クレジットカード名義を半角英 大文字で入力してください'
    )
    .when(INPUT_FIELD.USE_CURRENT_CARD, {
      is: false,
      then: (schema) =>
        schema.required('クレジットカード名義を入力してください'),
    }),
  [INPUT_FIELD.CARD_SEC_CODE]: Yup.string()
    .matches(
      /^\d{3,4}$/,
      'セキュリティコードを3〜4桁の半角数字で入力してください'
    )
    .when(INPUT_FIELD.USE_CURRENT_CARD, {
      is: false,
      then: (schema) =>
        schema.required('クレジットカードセキュリティコードを入力してください'),
    }),
});

export const STEP3_SCHEMA = Yup.object().shape({
  [INPUT_FIELD.IS_E_GRANT]: Yup.string(),
});

export const PLAN_CATEGORIES = [
  {
    planTypeEnum: 'SINGLE',
    dataVolume: '5GB',
  },
  {
    planTypeEnum: 'SINGLE_UNEXT',
    dataVolume: '10GB',
  },
  {
    planTypeEnum: 'SHARED',
    dataVolume: '20GB',
  },
];

export const SIM_TYPES = {
  VOICE: '音声SIM',
  SMS_DATA: 'データ・SMS',
  DATA: 'データSIM',
};
