import { gql } from '@apollo/client';

export const INPUT_FIELD = {
  PLAN_ID: 'planId',
  PLAN_CATEGORY_ID: 'planCategoryId',
  SIMTYPE_ID: 'simTypeId',
  VOICEPLAN_ID: 'voicePlanId',
  OPTION_IDS: 'optionIds',
  APPLICATION_TYPE: 'applicationType',
  MNP_CODE: 'mnpCode',
  MNP_CODE_DUE: 'mnpCodeDue',
  MNP_PHONE_NUMBER: 'mnpPhoneNumber',
  HAS_MINOR_AGE: 'hasMinorAge',
  ENTRY_CODE: 'entryCode',
  HAS_CONSENT: 'hasConsent',
  HAS_CONSENT_NON_CONTRACTOR: 'hasConsentNonContractor',
  IS_BELOW_AGE_18: 'isBelowAge18',
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
  LAST_NAME_KANA: 'lastNameKana',
  FIRST_NAME_KANA: 'firstNameKana',
  POSTAL_CODE: 'postalCode',
  ADDR_PREF: 'prefecture',
  ADDR_1: 'addressLine1',
  ADDR_2: 'addressLine2',
  ADDR_3: 'addressLine3',
  GENDER: 'gender',
  BIRTH_DATE: 'dateOfBirth',
  TEL: 'tel',
  USE_CURRENT_CARD: 'useCurrentCard',
  CARD_NUM: 'cardNum',
  CARD_VALID_MONTH: 'cardValidMonth',
  CARD_VALID_YEAR: 'cardValidYear',
  CARD_NAME: 'cardName',
  CARD_SEC_CODE: 'cardSecCode',
  IS_E_GRANT: 'isEGrant',
  EMAIL: 'email',
  EMAIL_ADDRESS: 'emailAddress',
  IS_CONTRACT_HOLDER: 'isContractHolder',
  USE_ANOTHER_ADDRESS: 'useAnotherAddress',
  PASSWORD: 'password',
  VERIFICATION_CODE: 'verificationCode',
};

export const APPLICATION_TYPES = {
  NEW: 'new',
  MOBILE_NUMBER_PORTABILITY: 'mnp',
};

export const GENDERS = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const STRINGS = {
  [APPLICATION_TYPES.NEW]: '新規申し込み',
  [APPLICATION_TYPES.MOBILE_NUMBER_PORTABILITY]: '他社からの乗り換え（MNP）',
  [GENDERS.MALE]: '男性',
  [GENDERS.FEMALE]: '女性',
};

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUser {
      id
      loginId
      userPlatformId
      userType
      platformCode
      migrationStatus {
        canMigrate
      }
      hasUnresolvedExternalMigration
      customerInfo {
        id
        emailAddress
      }
      mobileServiceAccount {
        userPlatformId
        noOfActiveSimUsers
        noOfAgreeingSimUserApplications
        statusCode
        simUserApplications {
          id
          statusCode
          applicationId
          applicationDateTime
          customerInfo {
            id
            firstName
            lastName
          }
          rejectionHistory {
            id
            statusName
            reason
          }
          versionNo
        }
        simUsers {
          id
          statusCode
        }
        deliveries {
          id
          deliveryStatus
          customerInfo {
            id
            firstName
            lastName
          }
          trackingNumber
          shipDate
          applicationDateTime
          isMnp
        }
        activePlan {
          id
          planTypeEnum
          serviceContractStatus {
            statusCode
          }
        }
        activeOptions {
          runningServiceId
          optionTypeEnum
          ... on RunningIFilter {
            id
          }
        }
      }
    }
  }
`;

export const GET_LP_DATA = gql`
  query getLpMasterData($lpCode: String!) {
    getLpData(landingPageCode: $lpCode) {
      id
      landingPageCode
      landingPageEndDateTime
      campaign {
        code
        displayTitle
        campaignPdfUrl
      }
      salesAgency {
        code
      }
    }
  }
`;
