import { gql } from '@apollo/client';
import * as Yup from 'yup';
import { INPUT_FIELD } from '../../context/constants';
export const DEVICE_PURCHASE_STEPS = {
  INPUT: 'devicePurchaseInput',
  CONFIRM: 'devicePurchaseConfirm',
  COMPLETE: 'devicePurchaseComplete',
};

export const DEVICE_PURCHASABLE_ERROR_CODE = {
  // マイグレ可能なNSBO_PF
  IS_NSBO_USER: 'IS_NSBO_USER',
  // BOS_PFだがYUモバイルを申し込んでいない
  NO_MOBILE_CONTRACT: 'NO_MOBILE_CONTRACT',
  // 本人確認審査済みSIM利用者がいない
  NO_ELIGIBLE_SIM_USERS: 'NO_ELIGIBLE_SIM_USERS',
  // 有効な決済が登録されていない場合
  NO_VALID_PAYMENT_METHOD: 'NO_VALID_PAYMENT_METHOD',
  // 該当端末の在庫がない
  SOLD_OUT: 'SOLD_OUT',
  // 購入不可期間中もしくは購入上限台数に達している
  CAN_NOT_PURCHASE_TERM_OR_MAX_LIMIT: 'CAN_NOT_PURCHASE_TERM_OR_MAX_LIMIT',
  // 休止中ステータスの場合
  IS_SUSPENDED: 'IS_SUSPENDED',
};

export const GET_DEVICE_PURCHASE_DATA = gql`
  query getDevicePurchaseData($pid: ID!) {
    getAccount {
      userPlatformId
      contractHolder {
        id
        customerInfo {
          id
          firstName
          lastName
          address {
            addressLine1
            addressLine2
            addressLine3
            prefecture
            postalCode
          }
          phoneNumber
        }
        currentBillingMethod {
          ... on CreditCardInfo {
            id
            creditCardNumber
            name
          }
        }
      }
    }
    devicePurchaseEligibilityCheck(purchasableConfigId: $pid) {
      reasonCode
    }
    getPurchasableDeviceConfig(purchasableConfigId: $pid) {
      id
      imageUri
      name
      priceIncludingTax
      seller
      sellerTosUrl
    }
  }
`;

export const PURCHASE_DEVICE = gql`
  mutation purchaseDevice(
    $purchasableConfigId: ID!
    $customerInfo: DevicePurchaseCustomerInfoInput!
  ) {
    purchaseDevice(
      purchasableConfigId: $purchasableConfigId
      customerInfo: $customerInfo
    ) {
      id
      priceIncludingTax
      orderNo
      reservationDateTime
    }
  }
`;

export const PURCHASE_INPUT_SCHEMA = Yup.object().shape({
  [INPUT_FIELD.POSTAL_CODE]: Yup.string().when(
    INPUT_FIELD.USE_ANOTHER_ADDRESS,
    {
      is: true,
      then: (schema) =>
        schema
          .matches(/^\d{7}$/, '郵便番号を半角数字7桁で入力してください')
          .required('郵便番号を7桁の半角数字で入力してください'),
    }
  ),
  [INPUT_FIELD.ADDR_PREF]: Yup.string().when(INPUT_FIELD.USE_ANOTHER_ADDRESS, {
    is: true,
    then: (schema) => schema.required('都道府県を選択してください'),
  }),
  [INPUT_FIELD.ADDR_1]: Yup.string().when(INPUT_FIELD.USE_ANOTHER_ADDRESS, {
    is: true,
    then: (schema) =>
      schema
        .trim()
        .max(255, '${max}字以内で入力してください')
        .required('市町村群を入力してください'),
  }),
  [INPUT_FIELD.ADDR_2]: Yup.string().when(INPUT_FIELD.USE_ANOTHER_ADDRESS, {
    is: true,
    then: (schema) =>
      schema
        .trim()
        .max(255, '${max}字以内で入力してください')
        .required('丁目番地を入力してください'),
  }),
  [INPUT_FIELD.ADDR_3]: Yup.string().when(INPUT_FIELD.USE_ANOTHER_ADDRESS, {
    is: true,
    then: (schema) => schema.trim().max(255, '${max}字以内で入力してください'),
  }),
  [INPUT_FIELD.LAST_NAME]: Yup.string().when(INPUT_FIELD.USE_ANOTHER_ADDRESS, {
    is: true,
    then: (schema) =>
      schema
        .trim()
        .max(50, '${max}字以内で入力してください')
        .required('姓を入力してください'),
  }),
  [INPUT_FIELD.FIRST_NAME]: Yup.string().when(INPUT_FIELD.USE_ANOTHER_ADDRESS, {
    is: true,
    then: (schema) =>
      schema
        .trim()
        .max(50, '${max}字以内で入力してください')
        .required('名を入力してください'),
  }),
  [INPUT_FIELD.TEL]: Yup.string().when(INPUT_FIELD.USE_ANOTHER_ADDRESS, {
    is: true,
    then: (schema) =>
      schema
        .matches(/^(0{1}\d{9,10})$/, '有効な電話番号を入力してください')
        .required('電話番号を入力してください'),
  }),
});

export const PURCHASE_CONFIRM_SCHEMA = Yup.object().shape({
  [INPUT_FIELD.HAS_CONSENT]: Yup.bool().test(
    'consent',
    '同意が必要です。',
    // @ts-ignore
    (val) => val
  ),
});
