import React from 'react';
import { GlobalConfig } from '../../constants';
import Texts from '../Common/Texts';

// デフォルトの予期せぬエラーメッセージ
export const getUnexpectedError = (errorCode?: string): string =>
  `予期せぬエラーが発生しました。${
    errorCode ? '(' + errorCode + ')' : ''
  }\n\nお客さまの現在の状態ではお手続きやアクセスができない可能性があります。\nまた、サーバへのアクセスが混み合っているか、メンテナンス中の可能性があります。\nしばらく時間をおいてから、もう一度アクセスしてください。`;

export const ERROR_TYPE = {
  COMMON: {
    VALIDATION: {
      CODE: 'VALIDATION_ERROR',
    },
  },
  // 新規申込時のエラー
  APPLICATION_SUBMIT: {
    // iOSアプリ内課金ユーザーが契約できないプランへの申込み
    IAP: {
      CODE: 'REQUIRED_APPLE_IAP_NON_ACTIVE',
      MESSAGE: (
        <>
          お客様のアカウントでご希望のプランを申し込むには、現在ご利用中のU-NEXTサービスを解約してください。（REQUIRED_APPLE_IAP_NON_ACTIVE）
          <br />
          くわしくは
          <Texts.Link
            href="https://help.yumobile.jp/troubleshooting/detail/cannot-change-to-shareplans"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </Texts.Link>
          をご確認ください。
        </>
      ),
    },
  },
  // データチャージmutationのエラー
  // https://wiki.unext-info.jp/pages/viewpage.action?pageId=72894790
  DATA_CHARGE: {
    // プランが課金開始していない もしくは 解約済み
    INACTIVE: {
      CODE: 'CANNNOT_CHARGE_DATA_NO_ACTIVE_USER',
      MESSAGE: getUnexpectedError('CANNNOT_CHARGE_DATA_NO_ACTIVE_USER'),
    },
    // 契約が休止中
    SUSPENDED: {
      CODE: 'CANNNOT_CHARGE_DATA_SUSPENDED',
    },
    // 決済NG
    PAYMENT: {
      CODE: 'PAYMENT_ERROR',
      MESSAGE:
        '登録されている決済方法で購入手続きができませんでした。\n\nカード会社までお問い合わせいただくか、決済方法を変更してください。',
      BUTTON: {
        text: '支払い情報の登録・変更へ',
        href: `${GlobalConfig.supportUrl}/payment`,
      },
    },
    // システムエラー
    BOS_SYSTEM: {
      CODE: 'BOS_SYSTEM_ERROR',
      MESSAGE:
        '予期せぬエラーが発生しました。（BOS_SYSTEM_ERROR）\n\nしばらく時間をおいてから、もう一度実行してください。',
    },
    // チャージ後の容量が100GBを超える
    OVER_CAPACITY: {
      CODE: 'CANNNOT_CHARGE_DATA_OVER_MAXIMUM_CAPACITY',
      MESSAGE: 'データ容量が100GBを超えるため受け付けられませんでした。',
    },
    // 連続チャージ
    REPEATED: {
      CODE: 'CANNNOT_CHARGE_DATA',
      MESSAGE:
        'チャージが失敗しました。\n\n時間をおいてもう一度実行してください。',
    },
  },
  // MNP開通手続きmutationのエラー
  // https://wiki.unext-info.jp/pages/viewpage.action?pageId=72902655
  MNP_ACTIVATE: {
    // 当該SIMが開通済み
    ACTIVATED: {
      CODE: 'MNP_PORTIN_MNP_NUMBER_ACTIVATED',
      MESSAGE:
        'すでにMNP開通済みです。\n\nMNP予約番号の有効期限前日に、開通処理をさせていただく場合があります。\n\nSIMをさしてご確認ください。',
    },
    // 契約が休止中
    SUSPENDED: {
      CODE: 'MNP_PORTIN_SUSPENDED',
    },
    // 当該SIMが利用停止中・解約済み
    INACTIVE: {
      CODE: 'MNP_PORTIN_MNP_NUMBER_NONACTIVE',
      MESSAGE: getUnexpectedError('MNP_PORTIN_MNP_NUMBER_NONACTIVE'),
    },
    // 当該SIMがMNP開通手続き中
    SUBSCRIBED: {
      CODE: 'MNP_PORTIN_MNP_NUMBER_SUBSCRIBED',
      MESSAGE:
        '現在すでにMNP開通手続きを受付済みです。\n\n開通まで最大1時間ほどかかる場合がございます。\n21:00以降のお手続きの場合は、翌日9:00以降の開通となります。',
    },
    // 当該SIMがMNP開通失敗
    FAILED: {
      CODE: 'MNP_PORTIN_FAILED',
      MESSAGE:
        'お申し込みいただいたMNP予約番号で開通ができまませんでした。\nカスタマーセンターまでお問い合わせください。',
      BUTTON: {
        text: 'お問い合わせへ',
        href: '/contact',
      },
    },
  },
  // プラン変更手続きmutationのエラー
  // https://wiki.unext-info.jp/pages/viewpage.action?pageId=33274838
  PLAN_UPDATE: {
    // 契約が休止中
    SUSPENDED: {
      CODE: 'CANNOT_CHANGE_PLAN_CUSTOMER_SUSPENDED',
    },
    // 退会済みなど
    INACTIVE: {
      CODE: 'CANNOT_CHANGE_PLAN_ILLEGAL_STATUS',
      MESSAGE: getUnexpectedError('CANNOT_CHANGE_PLAN_ILLEGAL_STATUS'),
    },
    // データ不整合など
    SYSTEM: {
      CODE: 'CANNOT_CHANGE_PLAN_SYSTEM_ERROR_OCCURRED',
      MESSAGE:
        '予期せぬエラーが発生しました。（CANNOT_CHANGE_PLAN_SYSTEM_ERROR_OCCURRED）\n\nしばらく時間をおいてから、もう一度実行してください。\n\nそれでも解決しない場合はカスタマーセンターまでお問い合わせください。',
      BUTTON: {
        text: 'お問い合わせへ',
        href: '/contact',
      },
    },
    // 同月内で既にプラン変更済み
    LIMIT: {
      CODE: 'CANNOT_CHANGE_PLAN_MONTHLY_UPPER_LIMIT',
      MESSAGE:
        'プラン変更は1か月に1度まで可能です。来月以降に改めてお手続きください。',
    },
    // SIMが複数ある
    MULTI_SIM: {
      CODE: 'CANNOT_CHANGE_PLAN_SIM_COUNT_ILLEGAL',
      MESSAGE:
        '他のプランへ変更するためには、ご利用中のSIMを1枚にする必要があります。\n引き続きお使いになるSIM以外を解約もしくは転出完了してから、プラン変更を行ってください。',
    },
    // MNP転出予約番号を発行中
    MNP_ISSUING: {
      CODE: 'CANNOT_CHANGE_PLAN_MNP_NUMBER_ISSUING',
      MESSAGE:
        'ご契約中のSIMは現在MNP転出手続き中です。MNP転出手続き中はプラン変更ができません。',
    },
    // U-NEXTの解約処理が必要
    TERMINATE: {
      CODE: 'CANNOT_CHANGE_PLAN_MUST_TERMINATE',
      MESSAGE: (
        <>
          プラン変更をするには、現在ご利用中のU-NEXTサービスを解約してください。
          <br />
          くわしくは
          <Texts.Link
            href="https://help.yumobile.jp/troubleshooting/detail/cannot-change-to-shareplans"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </Texts.Link>
          をご確認ください。
        </>
      ),
    },
  },
  // 端末予約キャンセルmutationのエラー
  CANCEL_DEVICE_PURCHASE: {
    // 契約が休止中
    SUSPENDED: {
      CODE: 'IS_SUSPENDED',
    },
    // 予約キャンセル不可のステータス
    INVALID_STATUS: {
      CODE: 'PURCHASE_NOT_CANCELABLE',
      MESSAGE: getUnexpectedError('DEVICE_PURCHASE_NOT_CANCELABLE_STATUS'),
    },
    // version_no違い
    CONFLICT: {
      CODE: 'CONFLICT',
      MESSAGE: 'マイページトップに戻り、ステータスをご確認ください。',
    },
  },
};
