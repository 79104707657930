import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import { SimpleLayout } from '../Layout';

// @ts-ignore
const InvalidLPIDError = ({ message }) => {
  return (
    <SimpleLayout>
      <Texts.H1>エラー</Texts.H1>
      <Texts.Body1>
        {message ||
          `URLが無効です。\n\n各種サイトによって実施しているキャンペーン内容は異なります。\n再度お申し込みの際は、特典内容をご確認の上、お進みください。`}
      </Texts.Body1>
      <Button.Layout>
        <a href="https://www.yumobile.jp">
          <Button.Secondary>公式サイトへ戻る</Button.Secondary>
        </a>
      </Button.Layout>
    </SimpleLayout>
  );
};

InvalidLPIDError.propTypes = {
  message: PropTypes.string,
};

InvalidLPIDError.defaultProps = {
  message: null,
};

export default InvalidLPIDError;
