// @ts-nocheck
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Table from '../Common/Table';
import Icon from './Icon';
import { theme, typo } from '../../styles';
import { MediaContext } from '../../context/MediaContext';
import { device } from '../../styles/constants';
import useInitialFee from '../../hooks/useInitialFee';
import useMonthlyFee from '../../hooks/useMonthlyFee';

const FlexContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  max-width: 912px;
  margin: 0 auto;
  > div :not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.feeDetail.border};
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.feeDetail.background.sticky};
  ${typo.L_B};
  color: ${({ theme }) => theme.text.primaryInverted};
  text-align: center;

  @media ${device.mobile} {
    ${typo.M_B};
    text-align: left;
  }
`;

const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.feeDetail.background.footer};
  ${typo.S};
  color: ${({ theme }) => theme.text.primaryInverted};

  @media ${device.mobile} {
    ${typo.XS};
  }
`;

const ItemKey = styled.div`
  display: table-cell;
  word-break: break-word;
`;

const ItemValue = styled.div`
  display: table-cell;
  text-align: right;
  white-space: nowrap;
  // @ts-ignore
  color: ${({ theme, isDeducted }) =>
    isDeducted ? theme.feeDetail.deducted : 'inherit'} !important;
`;

const Item = styled.div`
  display: table-row;
  :not(:first-child) {
    > ${ItemKey} {
      padding-top: 4px;
    }
  }
`;

const ItemHeader = styled.div`
  margin-bottom: 20px;
  ${typo.M_B};

  @media ${device.mobile} {
    margin-bottom: 8px;
    ${typo.XS_B};
  }
`;

const ItemContainer = styled.div`
  width: 100%;
  display: table;
`;

const BoxCss = css`
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
`;

const FooterBox = styled.div`
  ${BoxCss};
  flex: 1;
  padding: 24px 51px;

  @media ${device.mobile} {
    padding: 8px 16px;
  }
`;

const Total = styled.div`
  ${BoxCss};
  justify-content: center;
  flex-direction: column;
  padding: 18px 51px 22px;
  ${typo.XS_B};

  @media ${device.mobile} {
    align-items: flex-start;
    padding: 16px;
    ${typo.XS};
    > div:not(:first-child) {
      margin-top: 8px;
      margin-left: 0;
    }
  }
`;

const TotalMain = styled.div`
  display: flex;
  justify-content: center;
  ${typo.XL_B};

  @media ${device.mobile} {
    ${typo.S_B};
  }
`;

const Note = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.feeDetail.subtext};
  ${typo.XXS};
  line-height: 1;

  @media ${device.mobile} {
    margin-top: 4px;
  }
`;

const ConfirmTotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 24px;
  background-color: ${({ theme }) => theme.feeDetail.background.total};
  ${typo.M_B};
  line-height: 1;

  > div:not(:first-child) {
    flex-shrink: 0;
    margin-left: 8px;
  }

  @media ${device.mobile} {
    margin-top: 10px;
    padding: 16px;
    ${typo.S_B};
  }
`;
const ConfirmNote = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.text.special};
  ${typo.XS};
  line-height: 1.5;
`;

const StyledIcon = styled(Icon)`
  &&& {
    margin-right: 6px;
    font-size: 32px;
  }
`;

const TableDetailBoxCss = css`
  ${BoxCss};
  color: ${theme.table.text.key};
  > ${ItemValue} {
    color: ${theme.table.text.value};
  }
  :not(:first-child) {
    margin-top: 24px;
  }

  @media ${device.mobile} {
    :not(:first-child) {
      margin-top: 16px;
    }
  }
`;

const TableDetailBox = styled.div`
  ${TableDetailBoxCss};
`;

const TableTotalBox = styled.div`
  ${TableDetailBoxCss};
  ${typo.M_B};

  @media ${device.mobile} {
    ${typo.S_B};
  }
`;

// @ts-ignore
// @ts-ignore
const getTotalBox = (name, price) => (
  <>
    <ItemKey>{name}</ItemKey>
    <ItemValue>{price.toLocaleString()}円</ItemValue>
  </>
);

// @ts-ignore
// @ts-ignore
const getDetailBox = (itemList, name) => (
  <ItemContainer>
    {name && <ItemHeader>{name}</ItemHeader>}
    {itemList.map((item, index) => (
      <Item key={item.name}>
        {!name && <ItemKey>{index === 0 ? '内訳' : ''}</ItemKey>}
        <ItemKey>{item.name}</ItemKey>
        {/* @ts-ignore */}
        <ItemValue isDeducted={item.price < 0}>
          {`${item.price.toLocaleString()}円`}
        </ItemValue>
      </Item>
    ))}
  </ItemContainer>
);

const ToggleCheck = styled.input`
  display: none;
  + label > * {
    position: relative;
    padding-right: 56px;
    cursor: pointer;
    ::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 24px;
      display: block;
      width: 6px;
      height: 6px;
      margin: auto;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      transform: translate(0, -30%) rotate(45deg);
    }
  }
  + label + * {
    display: none;
  }
  :checked {
    + label > * {
      ::after {
        transform: translate(0, -30%) rotate(225deg);
      }
    }
    + label + * {
      display: block;
      > * {
        padding-right: 56px;
      }
    }
  }
`;

// @ts-ignore
// @ts-ignore
const Toggle = ({ children, id }) => {
  return (
    <>
      <ToggleCheck type="checkbox" id={id} />
      <label htmlFor={id}>{children}</label>
    </>
  );
};

Toggle.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

const FeeDetailsForSimAdd = ({
  // @ts-ignore
  currentMonthlyFee,
  // @ts-ignore
  simTypeProps,
  // @ts-ignore
  voicePlanProps,
  // @ts-ignore
  optionsProps,
  // @ts-ignore
  hasEntryCode,
  // @ts-ignore
  isFooter,
  // @ts-ignore
  secondVoiceSimText,
}) => {
  const { isMobile } = useContext(MediaContext);
  const { simTypeEnum } = simTypeProps;

  const { initialFees, initialSubtotal, loading, error } = useInitialFee({
    simTypeEnum,
    hasEntryCode,
  });

  const { monthlyFees, monthlySubtotal } = useMonthlyFee({
    simTypeProps,
    voicePlanProps,
    optionsProps,
  });

  // No need to block user interaction with a loading spinner
  if (loading) {
    return null;
  }

  if (error) {
    throw error;
  }

  if (isFooter) {
    // iOS SafariでinitialSubtotalが変わっても再レンダーされない不具合があるため対処
    const StickyWrapperKey = `${initialSubtotal}_${monthlySubtotal}`;
    // フッター用の明細
    return isMobile ? (
      <StickyWrapper key={StickyWrapperKey}>
        <Toggle id="total">
          <Total>
            <TotalMain>
              <div>
                {`プラン全体の月額基本料金 ${(
                  currentMonthlyFee + monthlySubtotal
                ).toLocaleString()}円`}
              </div>
            </TotalMain>
            {/* @ts-ignore */}
            <div>{`追加される月額基本料金 ${monthlySubtotal.toLocaleString()}円/初期費用 ${initialSubtotal.toLocaleString()}円`}</div>
            <Note>※{secondVoiceSimText}</Note>
          </Total>
        </Toggle>
        <FooterWrapper>
          <FooterBox>{getDetailBox(initialFees, '初期費用内訳')}</FooterBox>
          <FooterBox>
            {getDetailBox(monthlyFees, '追加される月額基本料金内訳')}
          </FooterBox>
        </FooterWrapper>
      </StickyWrapper>
    ) : (
      <>
        <StickyWrapper key={StickyWrapperKey}>
          <Total>
            <TotalMain>
              <StyledIcon name={Icon.ASSETS.COMMON.PURCHASE} />
              <div>
                {`プラン全体の月額基本料金 ${(
                  currentMonthlyFee + monthlySubtotal
                ).toLocaleString()}円`}
              </div>
            </TotalMain>
            {/* @ts-ignore */}
            <div>{`追加される月額基本料金 ${monthlySubtotal.toLocaleString()}円/初期費用 ${initialSubtotal.toLocaleString()}円`}</div>
            <Note>※{secondVoiceSimText}</Note>
          </Total>
        </StickyWrapper>
        <FooterWrapper>
          <FlexContainer>
            <FooterBox>{getDetailBox(initialFees, '初期費用内訳')}</FooterBox>
            <FooterBox>
              {getDetailBox(monthlyFees, '追加される月額基本料金内訳')}
            </FooterBox>
          </FlexContainer>
        </FooterWrapper>
      </>
    );
  }

  // 確認画面用の明細
  return (
    <>
      <Table.Container>
        <Table.Row>
          <div>
            <TableTotalBox>
              {getTotalBox('初期費用', initialSubtotal)}
            </TableTotalBox>
            <TableDetailBox>{getDetailBox(initialFees)}</TableDetailBox>
          </div>
        </Table.Row>
        <Table.Row>
          <div>
            <TableTotalBox>
              {getTotalBox('追加される月額基本料金', monthlySubtotal)}
            </TableTotalBox>
            <TableDetailBox>{getDetailBox(monthlyFees)}</TableDetailBox>
          </div>
        </Table.Row>
      </Table.Container>
      {currentMonthlyFee !== null ? (
        <ConfirmTotal>
          <div>
            プラン全体の月額基本料金
            <ConfirmNote>{secondVoiceSimText}</ConfirmNote>
          </div>
          <div>{` ${(
            currentMonthlyFee + monthlySubtotal
          ).toLocaleString()}円`}</div>
        </ConfirmTotal>
      ) : (
        <ConfirmNote>{secondVoiceSimText}</ConfirmNote>
      )}
    </>
  );
};

FeeDetailsForSimAdd.propTypes = {
  currentMonthlyFee: PropTypes.number,
  simTypeProps: PropTypes.shape({
    simTypeEnum: PropTypes.string.isRequired,
    displayRunningName: PropTypes.string.isRequired,
    planCategory: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  voicePlanProps: PropTypes.shape({
    displayRunningName: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }),
  optionsProps: PropTypes.arrayOf(
    PropTypes.shape({
      displayRunningName: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ),
  hasEntryCode: PropTypes.bool,
  isFooter: PropTypes.bool,
  secondVoiceSimText: PropTypes.string.isRequired,
};

FeeDetailsForSimAdd.defaultProps = {
  currentMonthlyFee: null,
  voicePlanProps: null,
  optionsProps: [],
  hasEntryCode: false,
  isFooter: false,
};

export default FeeDetailsForSimAdd;
