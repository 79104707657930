import React, { useRef } from 'react';
import styled from 'styled-components';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import Icon from '../Common/Icon';
import { mixinStyle } from '../../styles';
import Loading from '../Loading';

const ShareUrl = styled.input`
  margin-top: 16px;
  width: 100%;
  box-sizing: border-box;
  ${mixinStyle.INPUT_BOX};
  ${mixinStyle.INPUT_CONTENT};
  ${mixinStyle.INPUT_FOCUS};
`;

const CopyButton = styled(Button.Primary)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CopyIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const LineButton = styled(Button.Primary)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.button.line.background};
    :hover {
      background-color: ${({ theme }) => theme.button.line.backgroundHovered};
    }
  }
`;

const LineIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${({ theme }) => theme.button.line.text};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-bottom: -8px;
  button {
    box-sizing: border-box;
    max-width: unset;
    min-width: unset;
  }
  > * {
    margin-right: 16px;
    margin-bottom: 8px;
  }
`;

type ShareProps = {
  title: string;
  description: string;
  description2?: string;
  shareUrl?: string;
  shareLoading?: boolean;
  shareError?: Error;
  closeModal: () => void;
};

const Share = ({
  title,
  description,
  description2,
  shareUrl,
  shareLoading,
  shareError,
  closeModal,
}: ShareProps) => {
  const shareUrlInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <Texts.H2>{title}</Texts.H2>
      {shareError && (
        <Texts.Error>もう一度閉じてから、やりなおしてください。</Texts.Error>
      )}
      {shareLoading && <Loading />}
      {shareUrl && (
        <>
          <Texts.Body2>{description}</Texts.Body2>
          {description2 && <Texts.Body2>{description2}</Texts.Body2>}
          <ShareUrl
            type="text"
            readOnly
            value={shareUrl}
            ref={shareUrlInput}
            placeholder="URL発行中・・・"
          />
        </>
      )}
      <Button.Layout>
        <FlexContainer>
          <CopyButton
            disabled={!shareUrl}
            onClick={() => {
              if (shareUrlInput.current) {
                shareUrlInput.current.select();
              }
              document.execCommand('copy');
            }}
          >
            <CopyIcon name={Icon.ASSETS.COMMON.COPY} />
            URLをコピー
          </CopyButton>
          <LineButton
            disabled={!shareUrl}
            onClick={() => {
              if (shareUrl) {
                window.open(
                  `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
                    shareUrl
                  )}`,
                  '_blank'
                );
              }
            }}
          >
            <LineIcon name={Icon.ASSETS.COMMON.LINE} />
            LINEで送る
          </LineButton>
        </FlexContainer>
        <Button.Tertiary onClick={closeModal}>閉じる</Button.Tertiary>
      </Button.Layout>
    </>
  );
};

export default Share;
