import React from 'react';
import { useRouter } from 'next/router';
import { getOAuthLoginLink } from '../../constants';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import { SimpleLayout } from '../Layout';

const Login = () => {
  const router = useRouter();
  return (
    <SimpleLayout>
      <Texts.H1>ログイン</Texts.H1>
      <Texts.Center>U-NEXT IDでのログインが必要です。</Texts.Center>
      <Button.Layout>
        <a href={getOAuthLoginLink(router.asPath)}>
          <Button.Secondary>
            <Button.SubText>すでにU-NEXT IDをお持ちの方</Button.SubText>
            ログイン
          </Button.Secondary>
        </a>
      </Button.Layout>
    </SimpleLayout>
  );
};

export default Login;
