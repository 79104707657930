import { css } from 'styled-components';

const XXXL_B = css`
  font-size: 32px;
  font-weight: bold;
`;

const XXL_B = css`
  font-size: 24px;
  font-weight: bold;
`;

const XL = css`
  font-size: 20px;
  font-weight: normal;
`;

const XL_B = css`
  font-size: 20px;
  font-weight: bold;
`;

const L = css`
  font-size: 18px;
  font-weight: normal;
`;

const L_B = css`
  font-size: 18px;
  font-weight: bold;
`;

const M = css`
  font-size: 16px;
  font-weight: normal;
`;

const M_B = css`
  font-size: 16px;
  font-weight: bold;
`;

const S = css`
  font-size: 14px;
  font-weight: normal;
`;

const S_B = css`
  font-size: 14px;
  font-weight: bold;
`;

const XS = css`
  font-size: 12px;
  font-weight: normal;
`;

const XS_B = css`
  font-size: 12px;
  font-weight: bold;
`;

const XXS = css`
  font-size: 10px;
  font-weight: normal;
`;

const XXS_B = css`
  font-size: 10px;
  font-weight: bold;
`;

const typo = {
  XXXL_B,
  XXL_B,
  XL,
  XL_B,
  L_B,
  L,
  M,
  M_B,
  S,
  S_B,
  XS,
  XS_B,
  XXS,
  XXS_B,
};

export default typo;
