import styled, { css } from 'styled-components';
import { theme, typo, mixinStyle } from '../../styles';
import { device } from '../../styles/constants';

// Base button style
const BaseCss = css`
  border: none;
  border-radius: 10px;
  text-align: center;
  line-height: 1.5;
  cursor: pointer;
  :disabled {
    background-color: ${theme.button.disabled.background} !important;
    color: ${theme.button.disabled.text};
    cursor: initial;
  }
  ${mixinStyle.INPUT_FOCUS};
`;

// Size
// L
const LCss = css`
  min-height: 64px;
  min-width: 320px;
  padding: 4px 16px;
  ${typo.L_B};

  @media ${device.mobile} {
    min-height: 48px;
    min-width: 240px;
    ${typo.M_B};
  }
`;

// M
const MCss = css`
  min-width: 280px;
  padding: 12px 16px;
  ${typo.M_B};

  @media ${device.mobile} {
    width: 100%;
    min-width: 0;
    max-width: 320px;
    ${typo.S_B};
  }
`;

// S
const SCss = css`
  min-height: 48px;
  min-width: 126px;
  padding: 4px 16px;
  ${typo.S_B};

  @media ${device.mobile} {
    min-height: 44px;
    min-width: 100px;
    padding: 4px 8px;
    ${typo.XS_B};
  }
`;

// Generated button with color
const Primary = styled.button`
  ${BaseCss};
  ${LCss};
  color: ${theme.button.primary.text};
  background-color: ${theme.button.primary.background};

  :hover:not(:disabled) {
    background-color: ${theme.button.primary.backgroundHovered};
  }
`;

const Secondary = styled.button`
  ${BaseCss};
  ${LCss};
  background-color: ${theme.button.secondary.background};
  color: ${theme.button.secondary.text};

  :hover:not(:disabled) {
    background-color: ${theme.button.secondary.backgroundHovered};
  }
`;

const Tertiary = styled.button`
  ${BaseCss};
  ${SCss};
  background-color: ${theme.button.tertiary.background};
  color: ${theme.button.tertiary.text};

  :hover:not(:disabled) {
    background-color: ${theme.button.tertiary.backgroundHovered};
  }
`;

const WarningCss = css`
  ${BaseCss};
  ${LCss};
  background-color: ${theme.button.warning.background};
  color: ${theme.button.warning.text};

  :hover:not(:disabled) {
    background-color: ${theme.button.warning.backgroundHovered};
  }
`;

const PrimarySub = styled.button`
  ${BaseCss};
  ${MCss};
  color: ${theme.button.primary.text};
  background-color: ${theme.button.primary.background};

  :hover:not(:disabled) {
    background-color: ${theme.button.primary.backgroundHovered};
  }
`;

const Positive = styled.button`
  ${BaseCss};
  ${SCss};
  background-color: ${theme.button.positive.background};
  color: ${theme.button.positive.text};
  :hover:not(:disabled) {
    background-color: ${theme.button.positive.backgroundHovered};
  }
`;

const Negative = styled.button`
  ${BaseCss};
  ${SCss};
  background-color: ${theme.button.negative.background};
  color: ${theme.button.negative.text};
  :hover:not(:disabled) {
    background-color: ${theme.button.negative.backgroundHovered};
  }
`;

const Warning = styled.button`
  ${WarningCss};
`;

const Plain = styled.button`
  padding: 4px 0;
  background: none;
  border: none;
  ${typo.M};
  color: ${({ theme }) => theme.button.plain};
  cursor: pointer;
  ${mixinStyle.INPUT_FOCUS};

  @media ${device.mobile} {
    ${typo.S};
  }
`;

const TextLink = styled.button`
  color: ${({ theme }) => theme.link.text};
  text-decoration: underline;
  cursor: pointer;
  ${mixinStyle.INPUT_FOCUS};
  :hover {
    color: ${({ theme }) => theme.link.textHovered};
  }
`;

const TextLinkXS = styled.button`
  ${typo.XS}
  color: ${({ theme }) => theme.link.text};
  text-decoration: none;
  cursor: pointer;
  ${mixinStyle.INPUT_FOCUS};
  :hover {
    color: ${({ theme }) => theme.link.textHovered};
  }
`;

const SubText = styled.div`
  ${typo.S};
`;

// Button layout
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 56px;
  :not(:last-child) {
    margin-bottom: 80px;
  }
  > * {
    :not(:first-child) {
      margin-top: 24px;
    }
  }

  @media ${device.mobile} {
    margin-top: 40px;
    :not(:last-child) {
      margin-bottom: 72px;
    }
  }
`;

const LayoutHorizontal = styled.div`
  margin-top: 24px;
  text-align: center;
  > * {
    :not(:first-child) {
      margin-left: 16px;
    }
  }
`;

const LayoutRight = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  > * {
    :not(:first-child) {
      margin-left: 16px;
    }
  }
`;

const Button = {
  BaseCss,
  Secondary,
  Primary,
  PrimarySub,
  SubText,
  Tertiary,
  Positive,
  Negative,
  WarningCss,
  Warning,
  Plain,
  TextLink,
  TextLinkXS,
  Layout,
  LayoutHorizontal,
  LayoutRight,
};

export default Button;
