export const TEXT_INPUT_SIZE = {
  L: { PC: 488, MOBILE: 375 },
  M: { PC: 240, MOBILE: 200 },
  S: { PC: 180, MOBILE: 120 },
};

export const MENU_WIDTH = {
  PC: 256,
  MOBILE: 225,
};

const STYLE_BREAKPOINTS = {
  MOBILE: 768,
  TABLET: 1024,
};

export const device = {
  mobile: `(max-width: ${STYLE_BREAKPOINTS.MOBILE}px)`,
  tablet: `(max-width: ${STYLE_BREAKPOINTS.TABLET}px)`,
  exceptMobile: `(min-width: ${STYLE_BREAKPOINTS.MOBILE + 1}px)`,
  exceptTablet: `(min-width: ${STYLE_BREAKPOINTS.TABLET + 1}px)`,
};
