import { useQuery } from '@apollo/client';
import { get, sum } from 'lodash';
import { GET_INITIAL_FEE } from './constants';

// @ts-ignore
// @ts-ignore
const useInitialFee = ({ simTypeEnum, hasEntryCode }) => {
  const { data, loading, error } = useQuery(GET_INITIAL_FEE, {
    variables: {
      simTypeEnum,
      hasEntryCode,
    },
    skip: !simTypeEnum,
  });

  if (loading || error || !data) {
    return { loading, error };
  }

  const initialFees =
    get(data, 'getApplicationFee') &&
    // @ts-ignore
    data.getApplicationFee.map((fee) => ({
      name: fee.name,
      price: fee.feeWithTax,
    }));

  // @ts-ignore
  const initialSubtotal = sum(initialFees.map((fee) => fee.price));

  return { initialFees, initialSubtotal, loading, error };
};

export default useInitialFee;
