// @ts-nocheck
import { useCallback, useReducer, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { GlobalConfig } from '../constants';
import useSecureUpload from './useSecureUpload';
import { GET_COMPLETE_TEMP_DATA } from '../components/SimAdd/constants';
import { GET_SIM_USER_SHARE_TOKEN } from './constants';

// @ts-ignore
// @ts-ignore
const reducer = (state, action) => {
  switch (action.type) {
    case reducer.ACTIONS.INIT:
      return {
        loading: true,
        error: null,
        shareUrl: '',
        simUserApplicationId: action.simUserApplicationId,
        applicationId: action.applicationId,
      };
    case reducer.ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case reducer.ACTIONS.COMPLETE:
      return {
        ...state,
        loading: false,
        shareUrl: action.shareUrl,
      };
    default:
      return state;
  }
};

reducer.ACTIONS = {
  INIT: 'init',
  ERROR: 'error',
  COMPLETE: 'complete',
};

const initialState = {
  loading: false,
  error: null,
  shareUrl: '',
};

const rsaPublicKey = Buffer.from(
  GlobalConfig.simUserShareRsaPublicKey,
  'base64'
).toString();

// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore
const useSimUserShareApplication = ({ aid, suaid, baseUrl, skip }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    loading: initLoading,
    data: initData,
    error: initError,
  } = useQuery(GET_COMPLETE_TEMP_DATA, {
    variables: { applicationId: aid },
    skip,
  });

  const [upload, { uploading, uploadErrors, uploadTokens }] =
    useSecureUpload(rsaPublicKey);
  const [getSimUserShareToken, { called, error, data }] = useLazyQuery(
    GET_SIM_USER_SHARE_TOKEN
  );

  const shareApplication = useCallback(
    async ({
      simUserApplicationId,
      applicationId,
      applicationData,
      lifetimeSec,
    }) => {
      // @ts-ignore
      dispatch({
        type: reducer.ACTIONS.INIT,
        simUserApplicationId,
        applicationId,
      });
      const serializedApplicationData = Buffer.from(
        JSON.stringify(applicationData),
        'utf-8'
      );
      upload(serializedApplicationData, lifetimeSec);
    },
    [dispatch, upload]
  );

  const requestShareUrl = useCallback(() => {
    const simUserApplication = get(
      initData,
      'getApplication.simUserApplications',
      []
      // @ts-ignore
    ).find(({ id }) => id === suaid);
    if (simUserApplication) {
      const simTypes = get(initData, 'getSimTypes');
      const voiceSimData =
        // @ts-ignore
        simTypes && simTypes.find((sim) => sim.simTypeEnum === 'VOICE');
      const voiceSimPrice =
        voiceSimData && voiceSimData.runningPrice.priceIncludingTax;
      const applicationData = {
        ...simUserApplication,
        options: get(initData, 'getApplication.options', []),
        voiceSimPrice,
      };
      const { applicationDateTime } = simUserApplication.simType;
      const currentTimeSec = Math.floor(Date.now() / 1000);
      const applicationTimeSec = Math.floor(
        Date.parse(applicationDateTime) / 1000
      );
      const { id: simUserApplicationId, applicationId } = simUserApplication;
      shareApplication({
        simUserApplicationId,
        applicationId,
        applicationData,
        lifetimeSec: Math.max(
          0,
          3600 * 24 - currentTimeSec + applicationTimeSec
        ),
      });
    } else {
      // @ts-ignore
      dispatch({
        type: reducer.ACTIONS.ERROR,
        error: new Error('Application not found'),
      });
    }
  }, [initData, shareApplication, suaid, dispatch]);

  useEffect(() => {
    if (!initLoading && get(initData, 'getApplication')) {
      requestShareUrl();
    }
  }, [initData, initLoading, requestShareUrl]);

  useEffect(() => {
    const token = get(data, 'getSimUserApplicationConsentToken.token');
    if (called && !error && token) {
      // @ts-ignore
      dispatch({
        type: reducer.ACTIONS.COMPLETE,
        shareUrl: `${baseUrl}?token=${encodeURIComponent(token)}`,
      });
    } else if (error) {
      // @ts-ignore
      dispatch({ type: reducer.ACTIONS.ERROR, error });
    }
  }, [called, data, error, dispatch, baseUrl]);

  useEffect(() => {
    if (get(uploadTokens, 'length', 0) === 1) {
      const { simUserApplicationId, applicationId } = state;
      getSimUserShareToken({
        variables: {
          simUserApplicationId,
          applicationId,
          uploadToken: uploadTokens[0],
        },
      });
    } else if (get(uploadErrors, 'length', 0) === 1) {
      // @ts-ignore
      dispatch({ type: reducer.ACTIONS.ERROR, error: uploadErrors[0] });
    }
  }, [getSimUserShareToken, state, uploading, uploadErrors, uploadTokens]);

  return {
    ...state,
    loading: initLoading || state.loading,
    error: initError || state.error,
    refetchShareUrl: requestShareUrl,
  };
};

export default useSimUserShareApplication;
