import { useRouter } from 'next/router';

const PUBLIC_PATH_LIST = [/^\/entry/, /^\/signup/, /^\/contact/];

const APPLICATION_PATH_LIST = [
  /^\/entry/,
  /^\/signup/,
  /^\/applicationintro/,
  /^\/application/,
];

const CHECK_EXCEPTION_PATH_LIST = [
  /^\/login_callback/,
  /^\/mnp/,
  /^\/noauth/,
  /^\/navlp/,
];

const PRIVATE_PATH_LIST = [
  /^\/mypage/,
  /^\/purchase/,
  /^\/applicationintro/,
  /^\/application/,
];

const usePageCheck = () => {
  const router = useRouter();

  const isPublicPage = PUBLIC_PATH_LIST.some(routeRegexp =>
    router.asPath.match(routeRegexp)
  );
  const isApplicationPage = APPLICATION_PATH_LIST.some(routeRegexp =>
    router.asPath.match(routeRegexp)
  );
  const isExceptionPage = CHECK_EXCEPTION_PATH_LIST.some(routeRegexp =>
    router.asPath.match(routeRegexp)
  );
  const isPrivatePage = PRIVATE_PATH_LIST.some(routeRegexp =>
    router.asPath.match(routeRegexp)
  );

  return {
    isPublicPage,
    isApplicationPage,
    isExceptionPage,
    isPrivatePage,
  };
};

export default usePageCheck;
