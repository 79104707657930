import { lighten, darken, transparentize } from 'polished';

const BASE_COLORS = {
  yellow: 'rgba(255, 207, 1, 1)',
  yellow_dark_medium: 'rgba(255, 172, 1, 1)',
  yellow_dark: 'rgba(248, 139, 2, 1)',
  navy: 'rgba(43, 53, 168, 1)',
  navy_medium: 'rgba(223, 226, 255, 1)',
  navy_light: 'rgba(232, 233, 241, 1)',
  red: 'rgba(217, 8, 8, 1)',
  red_medium: 'rgba(255, 152, 152, 1)',
  red_light: 'rgba(250, 232, 232, 1)',
  purple: 'rgba(151, 43, 168, 1)',

  mono: 'rgba(0, 0, 0, 1)',
  mono_light: 'rgba(250, 250, 250, 1)',
  white: 'rgba(255, 255, 255, 1)',

  gray: 'rgba(92, 94, 97, 1)',
  gray_light: 'rgba(210, 211, 214, 1)',
  gray_dark: 'rgba(55, 56, 60, 1)',

  alpha_3: 'rgba(0, 0, 0, 0.03)',

  green: 'rgba(0, 190, 0, 1)',
};

const GRADIENT = {
  yellow: {
    start: 'rgba(255, 80, 11, 1)',
    end: 'rgba(255, 200, 56, 1)',
  },
  navy: {
    start: 'rgba(16, 22, 93, 1)',
    end: 'rgba(70, 84, 253, 1)',
  },
  purple: {
    start: 'rgba(55, 26, 166, 1)',
    end: 'rgba(190, 47, 154, 1)',
  },
  blue: {
    start: 'rgba(55, 88, 253, 1)',
    end: 'rgba(24, 245, 253, 1)',
  },
};

export const colors = {
  primary: BASE_COLORS.navy,
  primary_medium: BASE_COLORS.navy_medium,
  primary_light: BASE_COLORS.navy_light,
  primary_07: transparentize(1 - 0.07, BASE_COLORS.navy),
  primary_70: transparentize(1 - 0.7, BASE_COLORS.navy),

  secondary: BASE_COLORS.yellow,
  secondary_dark_medium: BASE_COLORS.yellow_dark_medium,
  secondary_dark: BASE_COLORS.yellow_dark,

  tertiary: BASE_COLORS.purple,

  warning: BASE_COLORS.red,
  warning_medium: BASE_COLORS.red_medium,
  warning_light: BASE_COLORS.red_light,

  mono: BASE_COLORS.mono,
  mono_80: transparentize(1 - 0.8, BASE_COLORS.mono),
  mono_60: transparentize(1 - 0.6, BASE_COLORS.mono),
  mono_30: transparentize(1 - 0.3, BASE_COLORS.mono),
  mono_03: transparentize(1 - 0.03, BASE_COLORS.mono),

  mono_light: BASE_COLORS.mono_light,

  white: BASE_COLORS.white,
  white_60: transparentize(1 - 0.6, BASE_COLORS.white),

  red: BASE_COLORS.red,
  gray: BASE_COLORS.gray,
  gray_light: BASE_COLORS.gray_light,
  gray_dark: BASE_COLORS.gray_dark,
  alpha_3: BASE_COLORS.alpha_3,

  line: BASE_COLORS.green,
};

const theme = {
  h1: { accent: colors.secondary },
  text: {
    primary: colors.mono,
    primaryInverted: colors.white,
    secondary: colors.mono_80,
    tertiary: colors.mono_60,
    warning: colors.warning,
    special: colors.primary,
    special2: colors.secondary,
  },
  textList: {
    info: colors.primary,
    warning: colors.warning,
  },
  link: {
    text: colors.primary,
    textHovered: lighten(0.2, colors.primary),
  },
  button: {
    primary: {
      text: colors.white,
      background: colors.primary,
      backgroundHovered: lighten(0.1, colors.primary),
    },
    secondary: {
      text: colors.white,
      background: colors.secondary_dark_medium,
      backgroundHovered: lighten(0.1, colors.secondary_dark_medium),
    },
    tertiary: {
      text: colors.primary,
      background: colors.primary_medium,
      backgroundHovered: darken(0.1, colors.primary_medium),
    },
    positive: {
      text: colors.white,
      background: colors.primary,
      backgroundHovered: lighten(0.1, colors.primary),
    },
    negative: {
      // TODO: sub?に統一
      text: colors.white,
      background: colors.mono_60,
      backgroundHovered: colors.mono_30,
    },
    warning: {
      text: colors.white,
      background: colors.warning,
      backgroundHovered: lighten(0.1, colors.warning),
    },
    disabled: {
      text: colors.white_60,
      background: colors.gray_light,
    },
    line: {
      text: colors.white,
      background: colors.line,
      backgroundHovered: transparentize(0.1, colors.line),
    },
    plain: colors.primary,
  },
  radio: {
    text: {
      standard: colors.mono,
      selected: colors.white,
    },
    background: {
      standard: colors.white,
      selected: colors.primary,
    },
    border: colors.gray_light,
    check: {
      background: colors.white,
      selected: colors.secondary,
    },
  },
  input: {
    background: colors.white,
    border: colors.gray_light,
    borderError: colors.warning,
    focus: colors.primary_medium,
    button: {
      text: colors.mono_60,
      background: colors.primary_light,
    },
    check: {
      text: colors.white,
      background: colors.secondary,
      border: colors.gray_light,
    },
  },
  imageInput: {
    text: colors.primary,
    background: colors.white,
    backgroundHovered: colors.primary_light,
  },
  table: {
    text: {
      key: colors.mono_60,
      value: colors.mono,
    },
    border: colors.gray_light,
    row: {
      backgroundHovered: colors.alpha_3,
    },
  },
  box: {
    white: colors.white,
    gray: colors.mono_03,
    red: colors.warning_light,
    balloon: colors.primary_07,
    boxShadow: transparentize(1 - 0.08, BASE_COLORS.mono),
  },
  mainContent: {
    background: colors.mono_light,
    boxShadow: transparentize(1 - 0.08, BASE_COLORS.mono),
  },
  logo: colors.white,
  header: {
    gradient: {
      primary: GRADIENT.navy,
      secondary: GRADIENT.yellow,
    },
    boxShadow: colors.mono_30,
  },
  firstMessage: {
    button: {
      text: colors.white,
      gradient: GRADIENT.yellow,
    },
    boxShadow: transparentize(1 - 0.08, BASE_COLORS.mono),
  },
  menu: {
    background: colors.white,
    backgroundHover: colors.primary_07,
    text: {
      header: colors.gray_light,
      item: colors.mono_60,
      itemHover: colors.primary,
    },
    activeMark: colors.primary,
    border: colors.gray_light,
    gradient: GRADIENT.purple,
    button: {
      background: colors.white,
      accent: colors.secondary,
    },
  },
  announcements: {
    text: colors.white,
    textHovered: darken(0.1, colors.white),
    background: colors.primary_70,
  },
  card: {
    text: colors.primary,
    background: colors.white,
    border: colors.gray_light,
  },
  procedureStep: {
    text: colors.secondary_dark,
    button: {
      text: colors.white,
    },
    gradient: GRADIENT.yellow,
    error: {
      text: colors.warning,
      background: colors.warning_light,
    },
    border: colors.gray_light,
  },
  applicationItem: {
    text: colors.secondary_dark,
    number: colors.tertiary,
    target: colors.tertiary,
    gradient: {
      standard: GRADIENT.purple,
      background: {
        start: transparentize(0.8, GRADIENT.purple.start),
        end: transparentize(0.8, GRADIENT.purple.end),
      },
    },
  },
  applicationCompleteItem: {
    text: colors.mono_80,
    gradient: {
      standard: GRADIENT.purple,
      background: {
        start: transparentize(0.8, GRADIENT.purple.start),
        end: transparentize(0.8, GRADIENT.purple.end),
      },
    },
    check: colors.mono,
  },
  campaign: {
    gradient: {
      standard: GRADIENT.yellow,
      background: {
        start: transparentize(0.8, GRADIENT.yellow.start),
        end: transparentize(0.8, GRADIENT.yellow.end),
      },
    },
  },
  toolTip: {
    icon: colors.primary,
    background: colors.gray_light,
    text: colors.mono_60,
    dropShadow: transparentize(1 - 0.08, BASE_COLORS.mono),
  },
  label: {
    gray: {
      text: colors.white,
      background: colors.mono_30,
    },
    gradient: {
      text: colors.white,
      background: GRADIENT.purple,
    },
  },
  listItem: {
    border: colors.gray_light,
    text: { primary: colors.mono_80, secondary: colors.white_60 },
  },
  feeDetail: {
    subtext: colors.white_60,
    border: colors.gray_light,
    deducted: colors.warning_medium,
    background: {
      sticky: colors.gray_dark,
      footer: colors.gray,
      total: colors.mono_03,
    },
  },
  barChart: {
    background: colors.mono_light,
    text: {
      standard: colors.mono_60,
      primary: colors.primary,
    },
    gradient: GRADIENT.blue,
    border: colors.gray_light,
  },
  volume: {
    gradient: GRADIENT.purple,
    border: colors.primary_medium,
    text: {
      primary: colors.primary,
      warning: colors.warning,
    },
    charge: {
      background: colors.primary_07,
    },
  },
  arrow: {
    background: colors.gray_light,
  },
  modal: {
    overlay: colors.mono_30,
  },
  loading: {
    overlay: colors.white_60,
  },
  device: {
    status: {
      special: colors.primary,
      normal: colors.mono_60,
      default: colors.mono_30,
    },
    devider: colors.gray_light,
  },
};

export default theme;
