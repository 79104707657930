import React, { PropsWithChildren } from 'react';
import { useUserContext } from '../../context/UserContext';
import Login from '../Login';
import NonApplicableError from '../NonApplicableError';
import SuspensionError from '../SuspensionError';
import HasApplicationError from '../HasApplicationError';
import EmailUnregisteredError from '../EmailUnregisteredError';
import Loading from '../Loading';
import usePageCheck from '../../hooks/usePageCheck';

const AccountCheck: React.FC<PropsWithChildren> = ({ children }) => {
  const { isLoggedIn, userInfoLoading, accountStatus } = useUserContext();
  const { isApplicationPage, isPrivatePage, isExceptionPage } = usePageCheck();

  if (isExceptionPage) {
    return <>{children}</>;
  }

  if (userInfoLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  if (isPrivatePage && !isLoggedIn) {
    // ログイン必須ページで未ログインの場合、ログイン誘導画面を表示
    return <Login />;
  }

  if (isLoggedIn && accountStatus) {
    if (!accountStatus.isApplicable) {
      // 子アカウントまたはマイグレ不可NSBOアカウントの場合、対象外画面を表示
      return <NonApplicableError />;
    } else if (accountStatus.isSuspended) {
      // BOSユーザーが休止の場合、休止中画面を表示
      return <SuspensionError />;
    } else if (isApplicationPage && accountStatus.hasApplicationOrPlan) {
      // 申し込みページで、ユーザーが申し込み済み・契約済みの場合、申込済みエラーを表示
      return <HasApplicationError />;
    } else if (isApplicationPage && !accountStatus.isEmailRegistered) {
      // 申し込みページで、ユーザーがメアド未登録の場合、メアド未登録エラーを表示
      return <EmailUnregisteredError />;
    }
  }

  return <>{children}</>;
};

export default AccountCheck;
