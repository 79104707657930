import React, {
  useMemo,
  useCallback,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from 'react';
import { useQuery } from '@apollo/client';
import useSWR from 'swr';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-unfetch';
import { GlobalConfig } from '../constants';
import { useRouter } from 'next/router';
import InvalidLPIDError from '../components/InvalidLPIDError';
import { GetLpMasterDataDocument, GetLpMasterDataQuery } from '../gql/graphql';

type IMarketingContext = GetLpMasterDataQuery['getLpData'] & {
  showEntryCode: boolean;
};

const MarketingContext = React.createContext<IMarketingContext | undefined>(
  undefined
);

const MarketingProvider: React.FC<PropsWithChildren> = (props) => {
  const router = useRouter();
  const { lp, cid, check } = router.query;
  const lpCode = lp || GlobalConfig.landingPageDefaults.landingPageCode;
  const { data, loading, error } = useQuery(GetLpMasterDataDocument, {
    variables: {
      lpCode,
    },
    skip: !lpCode,
  });
  const getConfigUrl = (): string =>
    lp
      ? check
        ? GlobalConfig.controlJsonCheckUrl
        : GlobalConfig.controlJsonReleaseUrl
      : null;
  const [agencyCode, setAgencyCode] = useState(cid);
  const { data: configData, error: configError } = useSWR(
    getConfigUrl,
    (url) => fetch(url).then((res) => res.json()),
    { errorRetryCount: 5 }
  );
  const lpMasterData = !loading && data?.getLpData;
  const lpData: NonNullable<GetLpMasterDataQuery['getLpData']> = useMemo(() => {
    if (lpMasterData) {
      if (agencyCode) {
        return {
          ...lpMasterData,
          salesAgency: { ...lpMasterData.salesAgency, code: agencyCode },
        };
      }
      return lpMasterData;
    }
    return GlobalConfig.landingPageDefaults;
  }, [lpMasterData, agencyCode]);

  useEffect(() => {
    if (!cid) {
      fetch('https://www.yumobile.jp/api/getcookiedata', {
        credentials: 'include',
      })
        .then((response) => {
          return response.json();
        })
        .then(({ data }) => {
          if (data.cidValue) {
            setAgencyCode(data.cidValue);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [cid]);

  if (error || configError) {
    if (error?.graphQLErrors?.[0]?.extensions?.code === 'USER_INPUT_ERROR') {
      return <InvalidLPIDError />;
    }
    throw error || configError;
  }
  const lpEndDateTime = lpData.landingPageEndDateTime;
  const hasExpired = !!lpEndDateTime && Date.parse(lpEndDateTime) < Date.now();
  if (hasExpired) {
    return (
      <InvalidLPIDError
        message={
          'こちらのキャンペーンは終了しました。\nたくさんの申し込みありがとうございました。'
        }
      />
    );
  }

  return lpMasterData && (configData || !lp) ? (
    <MarketingContext.Provider
      value={{
        ...lpData,
        showEntryCode: get(configData, ['lpid'], []).indexOf(lpCode) >= 0,
      }}
    >
      {props.children}
    </MarketingContext.Provider>
  ) : null;
};

MarketingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useMarketingContext = (): [
  IMarketingContext,
  (url: string) => string
] => {
  const router = useRouter();
  const context = useContext(MarketingContext);

  if (context === undefined) {
    throw new Error(
      'useMarketingContext must be used within a MarketingProvider'
    );
  }

  const addLpQueryToUrl = useCallback(
    (url: string): string => {
      const params = {
        lp: router.query.lp,
        cid: context.salesAgency.code,
      };
      const addQuery = Object.entries(params)
        .map(([key, value]) => value && `${key}=${value}`)
        .filter((value) => !!value);

      if (addQuery.length) {
        return `${url}${url.indexOf('?') > 0 ? '&' : '?'}${addQuery.join('&')}`;
      }
      return url;
    },
    [context.salesAgency.code, router.query]
  );
  return [context, addLpQueryToUrl];
};

export { MarketingProvider, useMarketingContext };
