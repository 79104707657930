import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typo } from '../../styles';
import Boxes from '../Common/Boxes';
import Icon from '../Common/Icon';
import { device } from '../../styles/constants';

const Container = styled.div`
  ${Boxes.RoundedCss};
  background: ${({ theme }) => theme.card.background};
  > div {
    min-height: 44px;
    padding: 0 24px;
    :not(:first-child) {
      border-top: 1px solid ${({ theme }) => theme.card.border};
    }
  }

  @media ${device.mobile} {
    > div {
      min-height: 32px;
      padding: 0 16px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${({
    // @ts-ignore
    isWarning,
    theme,
  }) => (isWarning ? theme.text.warning : theme.card.text)};
  ${typo.S_B};
  line-height: 44px;

  @media ${device.mobile} {
    padding: 0 16px;
    ${typo.XXS_B};
    line-height: 32px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
  font-size: 20px;

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

// @ts-ignore
// @ts-ignore
// @ts-ignore
const Card = ({ children, header, isWarning }) => {
  return (
    <Container>
      {/* @ts-ignore */}
      <Header isWarning={isWarning}>
        <StyledIcon name={Icon.ASSETS.COMMON.ATTENTION} />
        <div>{header}</div>
      </Header>
      <div>{children}</div>
    </Container>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  isWarning: PropTypes.bool,
};

Card.defaultProps = {
  isWarning: false,
};

export default Card;
