import React, { useEffect } from 'react';
import { useUserContext } from '../context/UserContext';

type CustomWindow = Window &
  typeof globalThis & {
    yuUserInfo: {
      isLogin: boolean;
      pfid: string;
    };
  };

/* ポイ活などの成果通知で使用するグローバル変数。YTM側で当変数を使用してタグを差し込む。 */
const UserInfoTracker: React.FC = () => {
  const { userInfo } = useUserContext();

  useEffect(() => {
    (window as CustomWindow).yuUserInfo = {
      isLogin: !!userInfo,
      pfid: userInfo?.userPlatformId ?? '',
    };
  }, [userInfo]);

  return null;
};

export default UserInfoTracker;
