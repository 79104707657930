import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import Error from '../Error';
import { get } from 'lodash';
import { getUnexpectedError } from './constants';

class ErrorBoundary extends React.Component {
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = { error: null };
    Router.events.on('routeChangeStart', this.onRouteChange);
  }

  // @ts-ignore
  static getDerivedStateFromError(error) {
    return { error };
  }

  // @ts-ignore
  // @ts-ignore
  componentDidCatch(error, errorInfo) {
    if (process.env.SENTRY_DSN) {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
    }
  }

  componentWillUnmount() {
    Router.events.off('routeChangeStart', this.onRouteChange);
  }

  onRouteChange = () => {
    this.setState({ error: null });
  };

  // An error that should be captured on Sentry has to be thrown in the application code,
  // it will be caught in the `ErrorBoundary`.
  //
  // ex. For default error message:
  //   `throw error;`
  // ex. For general errors with custom error message:
  //   `throw { ...error, customMessage: 'custom error message'}`
  render() {
    // @ts-ignore
    const error = this.state.error;

    if (!error) {
      return this.props.children;
    }

    if (typeof error === 'string') {
      return <Error message={error} />;
      // @ts-ignore
    } else if (error.customMessage) {
      // For errors with custom message
      return (
        // @ts-ignore
        <Error message={error.customMessage} button={error.customButton} />
      );
    } else if (
      get(error, 'graphQLErrors[0]') && // This line is to make sure it's graphQLError
      get(error, 'graphQLErrors[0].extensions.status') === 400 &&
      get(error, 'graphQLErrors[0].extensions.errors[0].code') ===
        'CLIENT_ERROR'
    ) {
      return <Error message={getUnexpectedError('CLIENT_ERROR')} />;
    } else if (
      get(error, 'graphQLErrors[0]') && // This line is to make sure it's graphQLError
      get(error, 'graphQLErrors[0].extensions.status') &&
      get(error, 'graphQLErrors[0].extensions.status') !== 500 &&
      // Make sure an error message is available
      (get(error, 'graphQLErrors[0].extensions.errors[0].message') ||
        get(error, 'graphQLErrors[0].message'))
    ) {
      // For graphQLErrors except HTTP 500 or status undefined
      const message = get(
        error,
        'graphQLErrors[0].extensions.errors[0].message'
      )
        ? `${get(
            error,
            'graphQLErrors[0].extensions.errors[0].message'
          )} (${get(error, 'graphQLErrors[0].extensions.errors[0].code')})`
        : get(error, 'graphQLErrors[0].message');
      return <Error message={message} />;
    }
    // For unexpected errors
    const errorCode = get(error, 'graphQLErrors[0].extensions.errors[0].code');
    return <Error message={getUnexpectedError(errorCode)} />;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
