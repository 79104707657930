import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Outer, Inner } from './Layout';
import Header from './Header';
import usePageCheck from '../../hooks/usePageCheck';
import { Content } from './SimpleLayout';

// @ts-ignore
const ErrorLayout = ({ children }) => {
  const { isApplicationPage } = usePageCheck();
  return (
    <Wrapper>
      {/* @ts-ignore */}
      <Outer isApplicationColor={isApplicationPage}>
        <Header isApplicationColor={isApplicationPage} isError />
        <Inner>
          <Content>{children}</Content>
        </Inner>
      </Outer>
    </Wrapper>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorLayout;
