import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from './Layout';
import { device } from '../../styles/constants';
import usePageCheck from '../../hooks/usePageCheck';

export const Content = styled.div`
  position: relative;
  padding: 64px 96px;
  background: ${({ theme }) => theme.mainContent.background};
  border-radius: 20px;
  box-shadow: 0px 10px 20px 0px ${({ theme }) => theme.mainContent.boxShadow};

  @media ${device.mobile} {
    padding: 48px 16px;
  }
`;

// @ts-ignore
const SimpleLayout = ({ children, ...props }) => {
  const { isApplicationPage } = usePageCheck();
  return (
    <Layout isApplicationColor={isApplicationPage} {...props}>
      <Content>{children}</Content>
    </Layout>
  );
};

SimpleLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SimpleLayout;
