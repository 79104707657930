import { sum } from 'lodash';

type useMonthlyFeeProps = {
  simTypeProps: {
    planCategory: string;
    displayRunningName: string;
    price: number;
  };
  voicePlanProps?: {
    displayRunningName: string;
    price: number;
  };
  optionsProps: {
    displayRunningName: string;
    price: number;
  }[];
};

const useMonthlyFee = ({
  simTypeProps,
  voicePlanProps,
  optionsProps,
}: useMonthlyFeeProps) => {
  const monthlyFees = [
    {
      name: `${simTypeProps.planCategory} ${simTypeProps.displayRunningName}`,
      price: simTypeProps.price,
    },
    voicePlanProps && {
      name: `y.uでんわ ${voicePlanProps.displayRunningName}`,
      price: voicePlanProps.price,
    },
    ...optionsProps.map(option => ({
      name: option.displayRunningName,
      price: option.price,
    })),
  ].filter(item => !!item);
  const monthlySubtotal = sum(monthlyFees.map(fee => fee?.price));

  return { monthlyFees, monthlySubtotal };
};

export default useMonthlyFee;
