import React, {
  useReducer,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  PropsWithChildren,
} from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-unfetch';
import Router, { useRouter } from 'next/router';
import { INPUT_FIELD } from './constants';
import { STATUS } from '../constants';
import { SIM_ADD_STEPS } from '../components/SimAdd/constants';
import { STEP } from '../components/Application/constants';
import { DEVICE_PURCHASE_STEPS } from '../components/DevicePurchase/constants';
import { Announcement } from '../components/Announcements';
import {
  GetUserInfoDocument,
  GetUserInfoQuery,
  MobileAccountStatus,
} from '../gql/graphql';

type ReducerState = {
  userInput: { [x: string]: any };
  simAddInput: { [x: string]: any };
  devicePurchaseInput: { [x: string]: any };
  hasInput: { [x: string]: boolean };
  signup: { [x: string]: any };
  isVoicePlan?: boolean;
  suaid?: string;
};

type ReducerAction = {
  type:
    | 'submitStep'
    | 'submitComplete'
    | 'simAdd'
    | 'devicePurchase'
    | 'signup';
  payload?: { [x: string]: any };
  hasInput?: { [x: string]: boolean };
  shouldReset?: boolean;
  suaid?: string;
};

type AccountStatus = {
  hasApplicationInProcess: boolean;
  canApplyAnotherSim: boolean;
  hasApplicationOrPlan: boolean;
  hasActivePlan: boolean;
  hasActiveOptions: boolean;
  isSuspended: boolean;
  isApplicable: boolean;
  isEmailRegistered: boolean;
};

interface IUserContext {
  state: ReducerState;
  dispatch: React.Dispatch<ReducerAction>;
  userInfo: GetUserInfoQuery['getUser'] | null;
  userInfoLoading: boolean;
  isLoggedIn: boolean;
  refetchUserInfo: () => void;
  accountStatus?: AccountStatus;
  announcements: Announcement[];
}

const initialState: ReducerState = {
  userInput: {
    // step 1
    [INPUT_FIELD.PLAN_ID]: '',
    [INPUT_FIELD.PLAN_CATEGORY_ID]: '',
    [INPUT_FIELD.VOICEPLAN_ID]: '',
    [INPUT_FIELD.SIMTYPE_ID]: '',
    [INPUT_FIELD.OPTION_IDS]: [],
    [INPUT_FIELD.APPLICATION_TYPE]: '',
    [INPUT_FIELD.MNP_CODE]: '',
    [INPUT_FIELD.MNP_CODE_DUE]: '',
    [INPUT_FIELD.MNP_PHONE_NUMBER]: '',
    [INPUT_FIELD.ENTRY_CODE]: '',
    [INPUT_FIELD.HAS_CONSENT]: false,
    [INPUT_FIELD.IS_BELOW_AGE_18]: false,
    // step 2
    [INPUT_FIELD.LAST_NAME]: '',
    [INPUT_FIELD.FIRST_NAME]: '',
    [INPUT_FIELD.LAST_NAME_KANA]: '',
    [INPUT_FIELD.FIRST_NAME_KANA]: '',
    [INPUT_FIELD.POSTAL_CODE]: '',
    [INPUT_FIELD.ADDR_PREF]: '',
    [INPUT_FIELD.ADDR_1]: '',
    [INPUT_FIELD.ADDR_2]: '',
    [INPUT_FIELD.ADDR_3]: '',
    [INPUT_FIELD.GENDER]: '',
    [INPUT_FIELD.BIRTH_DATE]: '',
    [INPUT_FIELD.TEL]: '',
    [INPUT_FIELD.USE_CURRENT_CARD]: false,
    [INPUT_FIELD.CARD_NUM]: '',
    [INPUT_FIELD.CARD_VALID_MONTH]: '',
    [INPUT_FIELD.CARD_VALID_YEAR]: '',
    [INPUT_FIELD.CARD_NAME]: '',
    [INPUT_FIELD.CARD_SEC_CODE]: '',
    // step 3
    [INPUT_FIELD.IS_E_GRANT]: 'yes',
  },
  simAddInput: {
    // plan input
    [INPUT_FIELD.VOICEPLAN_ID]: '',
    [INPUT_FIELD.SIMTYPE_ID]: '',
    [INPUT_FIELD.OPTION_IDS]: [],
    [INPUT_FIELD.APPLICATION_TYPE]: '',
    [INPUT_FIELD.MNP_CODE]: '',
    [INPUT_FIELD.MNP_CODE_DUE]: '',
    [INPUT_FIELD.MNP_PHONE_NUMBER]: '',
    [INPUT_FIELD.ENTRY_CODE]: '',
    [INPUT_FIELD.HAS_CONSENT]: false,
    [INPUT_FIELD.HAS_CONSENT_NON_CONTRACTOR]: false,
    [INPUT_FIELD.IS_BELOW_AGE_18]: false,
    [INPUT_FIELD.IS_CONTRACT_HOLDER]: '',
    // user input
    [INPUT_FIELD.LAST_NAME]: '',
    [INPUT_FIELD.FIRST_NAME]: '',
    [INPUT_FIELD.LAST_NAME_KANA]: '',
    [INPUT_FIELD.FIRST_NAME_KANA]: '',
    [INPUT_FIELD.GENDER]: '',
    [INPUT_FIELD.BIRTH_DATE]: '',
    [INPUT_FIELD.POSTAL_CODE]: '',
    [INPUT_FIELD.ADDR_PREF]: '',
    [INPUT_FIELD.ADDR_1]: '',
    [INPUT_FIELD.ADDR_2]: '',
    [INPUT_FIELD.ADDR_3]: '',
    [INPUT_FIELD.TEL]: '',
    // confirm
    [INPUT_FIELD.IS_E_GRANT]: 'yes',
  },
  devicePurchaseInput: {
    [INPUT_FIELD.LAST_NAME]: '',
    [INPUT_FIELD.FIRST_NAME]: '',
    [INPUT_FIELD.LAST_NAME_KANA]: '',
    [INPUT_FIELD.FIRST_NAME_KANA]: '',
    [INPUT_FIELD.POSTAL_CODE]: '',
    [INPUT_FIELD.ADDR_PREF]: '',
    [INPUT_FIELD.ADDR_1]: '',
    [INPUT_FIELD.ADDR_2]: '',
    [INPUT_FIELD.ADDR_3]: '',
    [INPUT_FIELD.TEL]: '',
    [INPUT_FIELD.USE_ANOTHER_ADDRESS]: false,
  },
  hasInput: {
    [STEP.CHOOSE_PLAN]: false,
    [STEP.CUSTOMER_INFO]: false,
    [SIM_ADD_STEPS.PLAN_INPUT]: false,
    [SIM_ADD_STEPS.USER_INPUT]: false,
    [DEVICE_PURCHASE_STEPS.INPUT]: false,
  },
  signup: {
    [INPUT_FIELD.EMAIL_ADDRESS]: '',
    [INPUT_FIELD.PASSWORD]: '',
  },
};

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case 'submitStep':
      return {
        ...state,
        userInput: { ...state.userInput, ...action.payload },
        hasInput: { ...state.hasInput, ...action.hasInput },
      };
    case 'submitComplete':
      if (action.shouldReset) {
        return {
          ...initialState,
          isVoicePlan: state.isVoicePlan,
          suaid: state.suaid,
        };
      }
      return {
        ...state,
        isVoicePlan: !!state.userInput[INPUT_FIELD.VOICEPLAN_ID],
        suaid: action.suaid,
      };
    case 'simAdd':
      if (action.shouldReset) {
        return initialState;
      }
      return {
        ...state,
        simAddInput: { ...state.simAddInput, ...action.payload },
        hasInput: { ...state.hasInput, ...action.hasInput },
      };
    case 'devicePurchase':
      if (action.shouldReset) {
        return initialState;
      }
      return {
        ...state,
        devicePurchaseInput: {
          ...state.devicePurchaseInput,
          ...action.payload,
        },
        hasInput: { ...state.hasInput, ...action.hasInput },
      };
    case 'signup':
      if (action.shouldReset) {
        return initialState;
      }
      return {
        ...state,
        signup: {
          ...state.signup,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

const getTimeFromString = (string: string) => {
  const year = string.slice(0, 4);
  const month = string.slice(4, 6);
  const day = string.slice(6, 8);
  const hour = string.slice(8, 10);
  const minute = string.slice(10, 12);
  const second = string.slice(12, 14);
  const date = new Date(
    `${year}-${month}-${day}T${hour}:${minute}:${second}+09:00`
  );
  return date.getTime();
};

const UserContext = React.createContext<IUserContext | undefined>(undefined);

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const isCheckMode = get(router, 'query.check') === '1';
  const [state, action] = useReducer(reducer, initialState);
  const {
    data,
    loading: userInfoLoading,
    refetch: refetchUserInfo,
  } = useQuery(GetUserInfoDocument, { errorPolicy: 'all' });
  // TODO: refetch doesn't change the loading state
  // https://github.com/apollographql/react-apollo/issues/321
  // Before Apollo fixed that issue (it's closed 2 years ago but not fixed)
  // we can use the line below, BUT!
  // it will trigger a warning for unmatched SSR html and client html
  //
  // query options:
  // {
  // notifyOnNetworkStatusChange: true,
  // Leaving it here as reference and fix when the issue is resolved
  // }
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  useEffect(() => {
    let mounted = true;
    const infoUrl = `https://metac.nxtv.jp/img/static_resource/yumobile/${
      isCheckMode ? 'check' : 'prod'
    }/yumobile_info.json`;
    const fetchData = async () => {
      const result = await fetch(infoUrl, { cache: 'no-cache' });
      const data = await result.json();
      if (mounted) {
        const info = get(data, 'mypage.info');
        const infoList: Announcement[] = info?.reduce(
          (
            acc: Announcement[],
            cur: { start: string; end: string; title: string; url: string }
          ) => {
            if (
              getTimeFromString(cur.start) < Date.now() &&
              getTimeFromString(cur.end) > Date.now()
            ) {
              acc.push({ title: cur.title, url: cur.url });
            }
            return acc;
          },
          []
        );
        setAnnouncements(infoList);
      }
    };
    fetchData();

    // Mutations for contracts will invalidate the cache,
    // while we can refetch queries in the mutation callback,
    // in certain paths or components it'll trigger a re-render and produce errors
    const handleRouteChange = (url: string) => {
      if (url === '/mypage') {
        refetchUserInfo();
      }
    };
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      mounted = false;
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isCheckMode, refetchUserInfo]);

  const accountStatus = useMemo(() => {
    if (data?.getUser) {
      const {
        userType,
        platformCode,
        migrationStatus,
        hasUnresolvedExternalMigration, // Paraviの初期設定未完了
        customerInfo,
        mobileServiceAccount,
      } = data.getUser;

      let applicationsInProcess = [];

      if (mobileServiceAccount?.simUserApplications) {
        mobileServiceAccount.simUserApplications.forEach((app) => {
          if (
            app &&
            (app.statusCode !== STATUS.APP_SIM_USER.CANCELED ||
              app.statusCode !== STATUS.APP_SIM_USER.AGREEING ||
              app.statusCode !== STATUS.APP_SIM_USER.JUDGED)
          ) {
            applicationsInProcess.push(app);
          }
        });
      }

      if (mobileServiceAccount?.deliveries) {
        mobileServiceAccount.deliveries.forEach((delivery) => {
          if (
            delivery &&
            (delivery.deliveryStatus !== STATUS.DELIVERY.APPLYING ||
              delivery.deliveryStatus !== STATUS.DELIVERY.CANCELED ||
              delivery.deliveryStatus !== STATUS.DELIVERY.DELIVERING)
          ) {
            applicationsInProcess.push(delivery);
          }
        });
      }

      const hasApplicationInProcess = applicationsInProcess.length > 0;

      const canApplyAnotherSim =
        mobileServiceAccount?.activePlan?.planTypeEnum === 'SHARED' &&
        mobileServiceAccount.noOfActiveSimUsers +
          mobileServiceAccount.noOfAgreeingSimUserApplications <
          4;

      const serviceContractStatus =
        mobileServiceAccount?.activePlan?.serviceContractStatus?.statusCode;
      const hasApplicationOrPlan =
        serviceContractStatus === STATUS.SERVICE_CONTRACT.CONTRACT_ACTIVE ||
        serviceContractStatus === STATUS.SERVICE_CONTRACT.APPLICATION_PROCESS ||
        serviceContractStatus === STATUS.SERVICE_CONTRACT.APPLICATION_DONE ||
        serviceContractStatus ===
          STATUS.SERVICE_CONTRACT.CONTRACT_IN_GRACE_PERIOD ||
        serviceContractStatus === STATUS.SERVICE_CONTRACT.CONTRACT_SUSPENDED;
      const hasActivePlan =
        // BOSがCONTRACT_ACTIVEに変わるのはモバイルAPIより10分ほど遅いため、simUserのステータスで補完する
        serviceContractStatus === STATUS.SERVICE_CONTRACT.CONTRACT_ACTIVE ||
        serviceContractStatus ===
          STATUS.SERVICE_CONTRACT.CONTRACT_IN_GRACE_PERIOD ||
        !!mobileServiceAccount?.simUsers?.some(
          (simUser) =>
            simUser &&
            (simUser.statusCode === STATUS.SIM_USER.RUNNING ||
              simUser.statusCode === STATUS.SIM_USER.STOPPED)
        );
      const hasActiveOptions =
        (mobileServiceAccount?.activeOptions ?? []).length > 0;
      const isSuspended =
        mobileServiceAccount?.statusCode === MobileAccountStatus.Suspended;
      const isApplicable =
        userType === 'MAIN' &&
        (platformCode === 'BOS' || !!migrationStatus?.canMigrate) &&
        !hasUnresolvedExternalMigration;
      const isEmailRegistered = !!customerInfo?.emailAddress;

      return {
        hasApplicationInProcess,
        canApplyAnotherSim,
        hasApplicationOrPlan,
        hasActivePlan,
        hasActiveOptions,
        isSuspended,
        isApplicable,
        isEmailRegistered,
      };
    }
    return undefined;
  }, [data]);

  const dispatch = useCallback((value: ReducerAction) => {
    if (action) action(value);
  }, []);

  const userInfo = data?.getUser ?? null;
  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        userInfo,
        userInfoLoading,
        isLoggedIn: !!userInfo?.loginId,
        refetchUserInfo,
        accountStatus,
        announcements,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

export { UserContext, UserProvider, useUserContext };
