import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { typo } from '../../styles';
import Texts from '../Common/Texts';
import Card from '../Card';

const Message = styled.div`
  line-height: 44px;
  ${typo.S};
`;

const OptionContractAttention = () => {
  return (
    <Card isWarning header="お客様は、現在提携サービスのみご契約中です。">
      <Message>
        詳しくは、
        <Link href="/mypage/contract" passHref>
          <Texts.Link>契約内容の確認・変更</Texts.Link>
        </Link>
        からご確認ください。
      </Message>
    </Card>
  );
};

export default OptionContractAttention;
