import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Texts from '../Common/Texts';
import { getJapaneseDateString } from '../../utils/helpers';
import { STATUS, GlobalConfig } from '../../constants';
import useSimUserShareApplication from '../../hooks/useSimUserShareApplication';
import { STEP_CONFIG, BUTTONS } from './constants';
import useModal from '../../hooks/useModal';
import Share from './Share';

const { APP_SIM_USER, DELIVERY } = STATUS;

const StepConfig = ({
  // 共通情報
  // @ts-ignore
  customerInfo,
  // @ts-ignore
  applicationDateTime,
  // ApplicationSimUser情報
  // @ts-ignore
  applicationId,
  // @ts-ignore
  simUserApplicationId,
  // @ts-ignore
  statusCode,
  // @ts-ignore
  rejectionHistory,
  // 配送情報
  // @ts-ignore
  deliveryStatus,
  // @ts-ignore
  trackingNumber,
  // @ts-ignore
  shipDate,
  // @ts-ignore
  isMnp,
  // @ts-ignore
  children,
}) => {
  const [
    ReactModal,
    { modalIsOpen, openModal, closeModal, defaultStyle },
  ] = useModal();

  const isWaitingForAgreement = statusCode === APP_SIM_USER.AGREEING;

  const { shareUrl, error: shareError } = useSimUserShareApplication({
    aid: applicationId,
    suaid: simUserApplicationId,
    baseUrl: `${GlobalConfig.clientUrl}/noauth/consent`,
    skip: !isWaitingForAgreement,
  });

  const subMessages = {
    cancel: (
      <>
        お申し込みのキャンセルは
        <Link
          href={`/mypage/confirmation?aid=${applicationId}&suaid=${simUserApplicationId}`}
          passHref
        >
          <Texts.Link>こちら</Texts.Link>
        </Link>
      </>
    ),
    WAIT_FOR_AGREEMENT: (
      <>24時間以内に同意がなかった場合、お申し込みがキャンセルされます。</>
    ),
    MNP: (
      <>
        開通まで最大1時間ほどかかる場合があります。
        <br />
        21:00～翌8:59のお手続きは、9:00以降順次開通処理を行います。
      </>
    ),
    MNPActivating: (
      <>
        開通手続き中です。
        <br />
        開通まで最大1時間ほどかかる場合があります。
        <br />
        21:00～翌8:59のお手続きは、9:00以降順次開通処理を行います。
      </>
    ),
  };
  const deliveryUrl = `http://tracking.post.japanpost.jp/service/singleSearch.do?org.apache.struts.taglib.html.TOKEN=&searchKind=S002&locale=ja&SVID=&reqCodeNo1=${trackingNumber}`;

  let stepConfig = null;
  let subMessage = null;
  let error = null;
  let button = null;
  switch (statusCode || deliveryStatus) {
    // applicationSimUserのステータス
    case APP_SIM_USER.AGREEING:
      stepConfig = STEP_CONFIG.WAIT_FOR_AGREEMENT;
      subMessage = subMessages.WAIT_FOR_AGREEMENT;
      button = BUTTONS.AGREE;
      // @ts-ignore
      button.onClick = openModal;
      break;
    case APP_SIM_USER.APPLIED:
      stepConfig = STEP_CONFIG.READY_FOR_ID_UPLOAD;
      subMessage = subMessages.cancel;
      button = BUTTONS.ID_UPLOAD;
      break;
    case APP_SIM_USER.JUDGEABLE:
    case APP_SIM_USER.JUDGING:
    case APP_SIM_USER.REAPPLYING:
      stepConfig = STEP_CONFIG.REVIEWING;
      subMessage = subMessages.cancel;
      break;
    case APP_SIM_USER.DECLINED:
      stepConfig = STEP_CONFIG.REJECTED;
      subMessage = subMessages.cancel;
      button = BUTTONS.ID_UPLOAD;
      if (rejectionHistory) {
        error = {
          title: rejectionHistory[0].statusName,
          message: rejectionHistory[0].reason,
        };
      }
      break;
    case APP_SIM_USER.MNP_ACTIVATE_WAITING:
      stepConfig = STEP_CONFIG.READY_FOR_MNP_PROCESS;
      subMessage = subMessages.MNP;
      button = BUTTONS.MNP;
      break;
    case APP_SIM_USER.MNP_ACTIVATING:
      stepConfig = STEP_CONFIG.ACTIVATING_MNP;
      subMessage = subMessages.MNPActivating;
      break;

    // 配送のステータス
    case DELIVERY.APPLYING:
    case DELIVERY.CANCELED:
      stepConfig = STEP_CONFIG.PREPARING_TO_SHIP;
      break;
    case DELIVERY.DELIVERING:
      stepConfig = isMnp ? STEP_CONFIG.SHIPPED_MNP : STEP_CONFIG.SHIPPED;
      subMessage = (
        <>
          配送番号：
          <a href={deliveryUrl} target="_blank" rel="noopener noreferrer">
            {trackingNumber}
          </a>
          {shipDate && `(${getJapaneseDateString(new Date(shipDate))} 発送)`}
          <br />
          配送業者：日本郵便
        </>
      );
      if (isMnp) {
        button = BUTTONS.MNP;
      }
      break;
    default:
      break;
  }

  const name = `${customerInfo.lastName} ${customerInfo.firstName}`;
  return (
    <>
      {children(
        stepConfig
          ? {
              name: name,
              date:
                applicationDateTime &&
                getJapaneseDateString(new Date(applicationDateTime)),
              subMessage,
              button: button
                ? {
                    text: button.text,
                    link:
                      // @ts-ignore
                      button.getHref &&
                      // @ts-ignore
                      button.getHref({ applicationId, simUserApplicationId }),
                    // @ts-ignore
                    onClick: button.onClick,
                  }
                : null,
              error,
              ...stepConfig,
            }
          : null
      )}
      {(shareUrl || shareError) && (
        <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={defaultStyle}
        >
          <Share
            title="追加SIM契約者に共有"
            description="追加SIM契約者に同意ページを共有してください。追加SIM契約者がお申し込み内容に同意して「送信」ボタンを押していただくことで、お申し込みが完了となります。"
            description2="24時間以内に同意がなかった場合、お申し込みがキャンセルされます。"
            shareUrl={shareUrl}
            shareError={shareError}
            closeModal={closeModal}
          />
        </ReactModal>
      )}
    </>
  );
};

StepConfig.propTypes = {
  // 共通情報
  customerInfo: PropTypes.object.isRequired,
  applicationDateTime: PropTypes.string.isRequired,
  // ApplicationSimUser情報
  applicationId: PropTypes.number,
  simUserApplicationId: PropTypes.string,
  statusCode: PropTypes.string,
  rejectionHistory: PropTypes.array,
  // 配送情報
  deliveryStatus: PropTypes.string,
  trackingNumber: PropTypes.string,
  shipDate: PropTypes.string,
  isMnp: PropTypes.bool,

  children: PropTypes.func.isRequired,
};

export default StepConfig;
