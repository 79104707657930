import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { GlobalConfig } from '../../constants';
import { typo } from '../../styles';
import useLogout from '../../hooks/useLogout';
import Icon from '../Common/Icon';
import { isActivePath } from '../../utils/helpers';
import { device, MENU_WIDTH } from '../../styles/constants';

const {
  AGREEMENT,
  DATA,
  HELP,
  OTHER,
  PURCHASE,
  SETTING,
  SUPPORT,
  LOGOUT,
  DEVICE,
} = Icon.ASSETS.NAV;

type MenuType = {
  header: string;
  items: {
    name: string;
    icon: string;
    href?: string;
    hrefPattern?: RegExp | string;
    extHref?: string;
  }[];
};

const MENUS: MenuType[] = [
  {
    header: 'マイページ',
    items: [
      {
        name: 'データ利用状況',
        icon: DATA,
        href: '/mypage',
        hrefPattern: '^/mypage(/charge(/.*)?)?$',
      },
      { name: '契約内容確認・変更', icon: AGREEMENT, href: '/mypage/contract' },
      { name: 'その他お手続き', icon: OTHER, href: '/mypage/misc' },
      { name: '端末', icon: DEVICE, href: '/mypage/device' },
    ],
  },
  {
    header: 'サポートメニュー',
    items: [
      { name: 'ヘルプセンター', icon: SUPPORT, extHref: GlobalConfig.helpUrl },
      { name: 'お問い合わせ', icon: HELP, href: '/contact' },
    ],
  },
  {
    header: 'U-NEXT IDの管理',
    items: [
      {
        name: '設定・サポートTOP',
        icon: SETTING,
        extHref: GlobalConfig.myAccountAuthorizationUrl,
      },
      {
        name: '請求金額の確認',
        icon: PURCHASE,
        extHref: `${GlobalConfig.supportUrl}/billing`,
      },
    ],
  },
];

const Container = styled.nav`
  width: ${MENU_WIDTH.PC}px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.menu.background};
  > div {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    :not(:first-child):before {
      content: '';
      position: absolute;
      top: 0;
      left: 28px;
      right: 28px;
      height: 1px;
      background-color: ${({ theme }) => theme.menu.border};
    }
  }
  @media ${device.tablet} {
    width: ${MENU_WIDTH.MOBILE}px;
    overflow-y: auto;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    > div {
      padding-top: 32px;
    }
  }
`;

const Submenu = styled.div`
  :not(:first-child) {
    margin-top: 40px;
  }
  a {
    text-decoration: none;
  }
`;

const SubmenuHeader = styled.h2`
  height: 12px;
  padding-left: 32px;
  color: ${({ theme }) => theme.menu.text.header};
  ${typo.XS_B};
  line-height: 12px;
`;

const ItemIcon = styled(Icon)`
  &&& {
    margin-right: 8px;
    font-size: 24px;
  }
`;

const ItemLi = styled.li<{ isActive?: boolean }>`
  margin-top: 8px;
  > a,
  button {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
    height: 48px;
    padding-left: 32px;
    ${typo.S_B};
    line-height: 48px;
    color: ${({ theme }) => theme.menu.text.item};
    :hover,
    :focus {
      background-color: ${({ theme }) => theme.menu.backgroundHover};
    }
  }
  // @ts-ignore
  ${({ isActive }) =>
    isActive &&
    css`
      > a,
      button {
        color: ${({ theme }) => theme.menu.text.itemHover};
        ${ItemIcon} {
          stop:first-child {
            stop-color: ${({ theme }) => theme.menu.gradient.start};
          }
          stop:last-child {
            stop-color: ${({ theme }) => theme.menu.gradient.end};
          }
        }
        :before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border-top: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid ${({ theme }) => theme.menu.activeMark};
        }
      }
    `}
`;

const Menu: React.FC = () => {
  const logout = useLogout();
  const router = useRouter();

  return (
    <Container>
      <div>
        {MENUS.map(submenu => (
          <Submenu key={submenu.header}>
            <SubmenuHeader>{submenu.header}</SubmenuHeader>
            <ul>
              {submenu.items.map(item => (
                <React.Fragment key={item.name}>
                  {item.href && (
                    <ItemLi
                      isActive={isActivePath(
                        router,
                        new RegExp(
                          item.hrefPattern ||
                            `^${item.href.replace(/\//, '\\/')}(\\/.*)*$`
                        )
                      )}
                    >
                      <Link href={item.href} passHref>
                        <ItemIcon name={item.icon} />
                        <div>{item.name}</div>
                      </Link>
                    </ItemLi>
                  )}
                  {item.extHref && (
                    <ItemLi>
                      <a href={item.extHref}>
                        <ItemIcon name={item.icon} />
                        <div>{item.name}</div>
                      </a>
                    </ItemLi>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </Submenu>
        ))}
      </div>
      <div>
        <Submenu>
          <ul>
            <ItemLi>
              {/* @ts-ignore */}
              <button onClick={logout}>
                <ItemIcon name={LOGOUT} />
                <div>ログアウト</div>
              </button>
            </ItemLi>
          </ul>
        </Submenu>
      </div>
    </Container>
  );
};

export default Menu;
