import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { DELETE_COOKIE } from './constants';
import { getOAuthLogoutLink } from '../constants';

const useLogout = (logoutPath = '/mypage') => {
  const clearCache = () => {
    window.sessionStorage.clear();
    window.location.href = getOAuthLogoutLink(logoutPath);
  };
  const [logout, { error }] = useMutation(DELETE_COOKIE, {
    // ログアウトしたらGQLキャッシュをクリアする
    onCompleted: clearCache,
  });

  if (error) {
    if (
      get(error, 'graphQLErrors[0].extensions.code') === 'NOT_AUTHENTICATED'
    ) {
      // ログアウト済みの場合
      clearCache();
    } else {
      throw error;
    }
  }

  return logout;
};

export default useLogout;
