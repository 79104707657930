import React from 'react';
import Texts from '../Common/Texts';
import Boxes from '../Common/Boxes';
import Flex from '../Common/FlexContainers';

const CustomerCenter = () => {
  return (
    <Boxes.Gray>
      <Texts.Body1>カスタマーセンター</Texts.Body1>
      <Flex.RowCenterLeft>
        <Texts.SpecialBig2nd>0120-790-657</Texts.SpecialBig2nd>
        <Texts.Body1>（受付時間：11:00〜19:00　年末年始は除く）</Texts.Body1>
      </Flex.RowCenterLeft>
    </Boxes.Gray>
  );
};

export default CustomerCenter;
