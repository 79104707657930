// @ts-nocheck
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { MediaContext } from '../../context/MediaContext';
import Table from '../Common/Table';
import { theme, typo } from '../../styles';
import useInitialFee from '../../hooks/useInitialFee';
import useMonthlyFee from '../../hooks/useMonthlyFee';
import { device } from '../../styles/constants';

const ItemKey = styled.div`
  word-break: break-word;
  :first-child {
    padding-right: 16px;
  }
`;
const ItemValue = styled.div`
  text-align: right;
  white-space: nowrap;
  // @ts-ignore
  color: ${({ theme, isDeducted }) =>
    isDeducted ? theme.feeDetail.deducted + ' !important' : 'inherit'};
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  :not(:first-child) {
    > ${ItemKey} {
      padding-top: 4px;
    }
  }
`;

const ItemContainer = styled.div`
  flex: 1;
`;

const Header = styled.div`
  flex: 0 0 auto;
  margin-right: 16px;
`;

const BoxCss = css`
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
`;

const FooterBox = styled.div`
  ${BoxCss};
  flex: 1;
  padding: 24px 51px;

  @media ${device.mobile} {
    padding: 16px;
  }
`;

const TableDetailBoxCss = css`
  ${BoxCss};
  color: ${theme.table.text.key};
  > ${ItemValue} {
    color: ${theme.table.text.value};
  }
  :not(:first-child) {
    margin-top: 24px;
  }
`;

const TableDetailBox = styled.div`
  ${TableDetailBoxCss};
`;

const TableTotalBox = styled.div`
  ${TableDetailBoxCss};
  ${typo.M_B};

  > ${ItemValue} {
    color: ${theme.table.text.value};
  }
`;

const FlexContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  max-width: 912px;
  margin: 0 auto;
  > div:not(:first-child) {
    border-top: none;
    border-left: 1px solid ${({ theme }) => theme.feeDetail.border};
  }

  @media ${device.mobile} {
    flex-direction: column;
    > div:not(:first-child) {
      border-left: none;
      border-top: 1px solid ${({ theme }) => theme.feeDetail.border};
    }
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.feeDetail.background.sticky};
  ${typo.L_B};
  color: ${({ theme }) => theme.text.primaryInverted};

  @media ${device.mobile} {
    ${typo.M_B};
  }
`;

const FooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.feeDetail.background.footer};
  ${typo.S};
  color: ${({ theme }) => theme.text.primaryInverted};

  @media ${device.mobile} {
    ${typo.XS};
  }
`;

const ToggleCheck = styled.input`
  display: none;
  + label > * {
    position: relative;
    padding-right: 56px;
    cursor: pointer;
    ::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 24px;
      display: block;
      width: 6px;
      height: 6px;
      margin: auto;
      border-bottom: 1px solid white;
      border-right: 1px solid white;
      transform: translate(0, -30%) rotate(45deg);
    }
  }
  + label + * {
    display: none;
  }
  :checked {
    + label > * {
      ::after {
        transform: translate(0, -30%) rotate(225deg);
      }
    }
    + label + * {
      display: flex;
      > * {
        padding-right: 56px;
      }
    }
  }
`;

// @ts-ignore
// @ts-ignore
const getTotalBox = (name, price) => (
  <>
    <ItemKey>{name}</ItemKey>
    <ItemValue>{price.toLocaleString()}円</ItemValue>
  </>
);

// @ts-ignore
const getDetailBox = (itemList) => (
  <>
    <Header>内訳</Header>
    <ItemContainer>
      {itemList.map((item) => (
        <Item key={item.name}>
          <ItemKey>{item.name}</ItemKey>
          {/* @ts-ignore */}
          <ItemValue isDeducted={item.price < 0}>
            {`${item.price.toLocaleString()}円`}
          </ItemValue>
        </Item>
      ))}
    </ItemContainer>
  </>
);

// @ts-ignore
// @ts-ignore
const Toggle = ({ children, id }) => {
  return (
    <>
      <ToggleCheck type="checkbox" id={id} />
      <label htmlFor={id}>{children}</label>
    </>
  );
};

Toggle.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

const FeeDetails = ({
  // @ts-ignore
  simTypeProps,
  // @ts-ignore
  voicePlanProps,
  // @ts-ignore
  optionsProps,
  // @ts-ignore
  hasEntryCode,
  // @ts-ignore
  isFooter,
}) => {
  const { isMobile } = useContext(MediaContext);
  const { simTypeEnum } = simTypeProps;

  const { initialFees, initialSubtotal, loading, error } = useInitialFee({
    simTypeEnum,
    hasEntryCode,
  });

  const { monthlyFees, monthlySubtotal } = useMonthlyFee({
    simTypeProps,
    voicePlanProps,
    optionsProps,
  });

  // No need to block user interaction with a loading spinner
  if (loading) {
    return null;
  }

  if (error) {
    throw error;
  }

  if (isFooter) {
    // iOS SafariでinitialSubtotalが変わっても再レンダーされない不具合があるため対処
    const StickyWrapperKey = `${initialSubtotal}_${monthlySubtotal}`;
    // フッター用の明細
    return isMobile ? (
      <StickyWrapper key={StickyWrapperKey}>
        <FlexContainer>
          <div>
            <Toggle id="initial">
              <FooterBox>{getTotalBox('初期費用', initialSubtotal)}</FooterBox>
            </Toggle>
            <FooterWrapper>
              <FooterBox>{getDetailBox(initialFees)}</FooterBox>
            </FooterWrapper>
          </div>
          <div>
            <Toggle id="monthly">
              <FooterBox>
                {getTotalBox('月額基本料金', monthlySubtotal)}
              </FooterBox>
            </Toggle>
            <FooterWrapper>
              <FooterBox>{getDetailBox(monthlyFees)}</FooterBox>
            </FooterWrapper>
          </div>
        </FlexContainer>
      </StickyWrapper>
    ) : (
      <>
        <StickyWrapper key={StickyWrapperKey}>
          <FlexContainer>
            <FooterBox>{getTotalBox('初期費用', initialSubtotal)}</FooterBox>
            <FooterBox>
              {getTotalBox('月額基本料金', monthlySubtotal)}
            </FooterBox>
          </FlexContainer>
        </StickyWrapper>
        <FooterWrapper>
          <FlexContainer>
            <FooterBox>{getDetailBox(initialFees)}</FooterBox>
            <FooterBox>{getDetailBox(monthlyFees)}</FooterBox>
          </FlexContainer>
        </FooterWrapper>
      </>
    );
  }

  // 確認画面用の明細
  return (
    <Table.Container>
      <Table.Row>
        <div>
          <TableTotalBox>
            {getTotalBox('初期費用', initialSubtotal)}
          </TableTotalBox>
          <TableDetailBox>{getDetailBox(initialFees)}</TableDetailBox>
        </div>
      </Table.Row>
      <Table.Row>
        <div>
          <TableTotalBox>
            {getTotalBox('月額基本料金', monthlySubtotal)}
          </TableTotalBox>
          <TableDetailBox>{getDetailBox(monthlyFees)}</TableDetailBox>
        </div>
      </Table.Row>
    </Table.Container>
  );
};

FeeDetails.propTypes = {
  simTypeProps: PropTypes.shape({
    simTypeEnum: PropTypes.string.isRequired,
    displayRunningName: PropTypes.string.isRequired,
    planCategory: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
  voicePlanProps: PropTypes.shape({
    displayRunningName: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }),
  optionsProps: PropTypes.arrayOf(
    PropTypes.shape({
      displayRunningName: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    })
  ),
  hasEntryCode: PropTypes.bool,
  isFooter: PropTypes.bool,
};

FeeDetails.defaultProps = {
  voicePlanProps: null,
  optionsProps: [],
  hasEntryCode: false,
  isFooter: false,
};

export default FeeDetails;
