import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Overlay = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: ${({ theme }) => theme.loading.overlay};
`;

const CenterImage = styled.img`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  margin: 0 auto;
`;

class Modal extends React.Component {
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = {
      el: null,
    };
    this.id = `LoadingPortal_${Math.random().toString(36).substring(7)}`;
  }

  componentDidMount() {
    const el = document.createElement('div');
    el.id = this.id;
    el.style.cssText = `
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10000;
    `;
    document.body.appendChild(el);
    this.setState({ el });
  }

  componentWillUnmount() {
    if (this.state.el && document.body.contains(this.state.el)) {
      // @ts-ignore
      document.body.removeChild(this.state.el);
    } else {
      // If this component is unmounted before a state change,
      // this.state.el could be null
      const el = document.getElementById(this.id);
      if (el && document.body.contains(el)) {
        document.body.removeChild(el);
      }
    }
  }

  render() {
    return (
      // @ts-ignore
      this.state.el && ReactDOM.createPortal(this.props.children, this.state.el)
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

const Loading = () => {
  return (
    <Modal>
      <Overlay>
        <CenterImage src="/static/images/loading.png" alt="loading" />
      </Overlay>
    </Modal>
  );
};

export default Loading;
