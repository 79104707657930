import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GA4_TRACKING_ID, GA_MEASUREMENT_ID } from '../constants';
import Script from 'next/script';

const TrackingTag: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (process.env.APP_ENV === 'prod') {
      timer = setTimeout(() => {
        // @ts-ignore
        window.gtag('config', GA_MEASUREMENT_ID, {
          page_title: window.document.title,
          page_location: window.location.href,
        });
      }, 0);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [router.asPath]);

  if (process.env.APP_ENV !== 'prod') return null;

  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="dYl7jx-lhvx7Op_oI-f2rqRPfokTQXTiGa6YBGX3Eto"
        />
      </Head>
      <Script id="gtm">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5FNR3FC2')
        `}
      </Script>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4_TRACKING_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
          gtag('config', '${GA4_TRACKING_ID}');
        `}
      </Script>
      <Script id="yjtag">
        {`
          (function () {
            var tagjs = document.createElement("script");
            tagjs.async = true;
            tagjs.src = "//s.yjtag.jp/tag.js#site=qWPyKIT&referrer=" + encodeURIComponent(document.location.href) + "";
            document.getElementsByTagName('head')[0].appendChild(tagjs);
          }());
        `}
      </Script>
    </>
  );
};

export default TrackingTag;
