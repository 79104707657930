import React from 'react';
import styled from 'styled-components';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import { typo } from '../../styles';
import FirstImage from '../../assets/images/first_message.png';
import { device } from '../../styles/constants';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  background: ${({ theme }) => theme.box.white};
  border-radius: 24px;
  > * {
    flex: 1;
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: initial;
  }
`;

const ImageContainer = styled.div`
  padding: 0 64px;
  text-align: center;
  > img {
    margin-top: 24px;
    vertical-align: bottom;
    width: 100%;
    height: auto;
    max-width: 440px;
  }

  @media ${device.mobile} {
    padding: 0 16px;
  }
`;

const MessageContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 32px 32px 0;

  @media ${device.mobile} {
    padding: 32px 16px;
    box-shadow: 0px 2px 24px 0px ${({ theme }) => theme.firstMessage.boxShadow};
  }
`;

const Message = styled.div`
  margin: 0 auto;
  ${typo.M};
  color: ${({ theme }) => theme.text.secondary};
  line-height: 28px;
  white-space: pre-wrap;
  > div:first-child {
    margin-top: 32px;
  }
  button {
    ${Button.BaseCss};
    min-height: 64px;
    min-width: 220px;
    padding: 0 16px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.firstMessage.button.gradient.start} 0%,
      ${({ theme }) => theme.firstMessage.button.gradient.end} 100%
    );
    box-shadow: 0px 10px 20px 0px ${({ theme }) => theme.firstMessage.boxShadow};
    ${typo.L_B};
    color: ${({ theme }) => theme.firstMessage.button.text};
  }

  @media ${device.mobile} {
    ${typo.S};
    > div:first-child {
      text-align: center;
    }
    button {
      ${typo.M_B};
    }
  }
`;

const FirstMessage = () => {
  return (
    <Container>
      <ImageContainer>
        <img src={FirstImage.src} alt="" />
      </ImageContainer>
      <MessageContainer>
        <Message>
          y.u mobileのプランは、シンプルかつ充実の3タイプ。{`\n`}
          さらに、有効期限がなく永久繰り越し※1できるギガや、お客さま負担0円でついてくるスマホ修理費用保険※2などの他社にはない満足も、y.u
          mobileならではの魅力です。
          {`\n`}
          <div>
            <a href="https://www.yumobile.jp">
              <button>くわしくは こちら</button>
            </a>
          </div>
          <Texts.Note>
            ※1：ギガのストック上限は100GBです。上限を超えた分は、古いギガから消失します。※2：音声通話SIMをご利用の方が対象です。
          </Texts.Note>
        </Message>
      </MessageContainer>
    </Container>
  );
};

export default FirstMessage;
