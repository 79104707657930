// @ts-nocheck

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Router from 'next/router';
// @ts-ignore
import NProgress from 'nprogress';
import { useUserContext } from '../../context/UserContext';
import Header from './Header';
import { MENU_WIDTH, device } from '../../styles/constants';
import Menu from '../Menu';
import FeeDetails from '../Common/FeeDetails';
import FeeDetailsForSimAdd from '../Common/FeeDetailsForSimAdd';

// @ts-ignore
Router.onRouteChangeStart = () => NProgress.start();
// @ts-ignore
Router.onRouteChangeComplete = () => NProgress.done();
// @ts-ignore
Router.onRouteChangeError = () => NProgress.done();

// @ts-ignore
// @ts-ignore
const getBGCss = (theme, isApplicationColor) =>
  isApplicationColor
    ? css`
        background: url('/static/images/background/slime_orange.svg'),
          linear-gradient(
            90deg,
            ${theme.header.gradient.secondary.start} 0%,
            ${theme.header.gradient.secondary.end} 100%
          );
      `
    : css`
        background: url('/static/images/background/slime_navy.svg'),
          linear-gradient(
            90deg,
            ${theme.header.gradient.primary.start} 0%,
            ${theme.header.gradient.primary.end} 100%
          );
      `;

// @ts-ignore
// @ts-ignore
const getMobileBGCss = (theme, isApplicationColor) =>
  isApplicationColor
    ? css`
        background: url('/static/images/background/slime_orange_top.svg'),
          url('/static/images/background/slime_orange_bottom.svg'),
          linear-gradient(
            90deg,
            ${theme.header.gradient.secondary.start} 0%,
            ${theme.header.gradient.secondary.end} 100%
          );
        background-position: top center, bottom center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      `
    : css`
        background: url('/static/images/background/slime_navy_top.svg'),
          url('/static/images/background/slime_navy_bottom.svg'),
          linear-gradient(
            90deg,
            ${theme.header.gradient.primary.start} 0%,
            ${theme.header.gradient.primary.end} 100%
          );
        background-position: top center, bottom center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      `;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  > nav {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    /* Innerのバウンススクロールで背景のMenuが見えてしまうのを防ぐ */
    // @ts-ignore
    ${({ showMenu }) =>
      !showMenu &&
      css`
        opacity: 0;
      `}
  }
`;

export const Outer = styled.div`
  position: absolute;
  // @ts-ignore
  left: ${({ showMenu }) => (showMenu ? MENU_WIDTH.MOBILE : 0)}px;
  top: 0;
  width: 100%;
  min-height: 100%;
  transition: left 0.3s ease-in-out;

  ${({ theme, isApplicationColor }) => getBGCss(theme, isApplicationColor)}
  background-size: 100%;
  background-repeat: no-repeat;

  @media ${device.mobile} {
    ${({ theme, isApplicationColor }) =>
      getMobileBGCss(theme, isApplicationColor)}
  }
`;

export const Inner = styled.div`
  // @ts-ignore
  max-width: ${({ isWide }) => (isWide ? '1008px' : '912px')};
  margin: 0 auto;
  padding-right: 8px;
  padding-left: 8px;
  padding-bottom: 64px;
`;

const Layout = ({
  // @ts-ignore
  children,
  // @ts-ignore
  isApplicationColor,
  // @ts-ignore
  isWide,
  // @ts-ignore
  hasMobileMenu,
  // @ts-ignore
  feeDetailsProps,
  // @ts-ignore
  feeDetailsForSimAddProps,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const toggleShowMenu = () => setShowMenu(!showMenu);
  const { userInfo } = useUserContext();
  return (
    <Wrapper
      showMenu={showMenu}
      // @ts-ignore
      onClick={showMenu ? toggleShowMenu : null}
      id="site-wrapper"
    >
      {hasMobileMenu && <Menu />}
      <Outer isApplicationColor={isApplicationColor} showMenu={showMenu}>
        <Header
          isApplicationColor={isApplicationColor}
          userId={userInfo?.loginId}
          toggleShowMenu={toggleShowMenu}
          showMenuButton={hasMobileMenu}
        />
        {/* @ts-ignore */}
        <Inner isWide={isWide}>{children}</Inner>
        {feeDetailsProps && <FeeDetails {...feeDetailsProps} />}
        {feeDetailsForSimAddProps && (
          <FeeDetailsForSimAdd {...feeDetailsForSimAddProps} />
        )}
      </Outer>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  isApplicationColor: PropTypes.bool,
  hasMobileMenu: PropTypes.bool,
  isWide: PropTypes.bool,
  feeDetailsProps: PropTypes.shape(FeeDetails.propTypes),
  feeDetailsForSimAddProps: PropTypes.shape(FeeDetailsForSimAdd.propTypes),
};

Layout.defaultProps = {
  isApplicationColor: false,
  hasMobileMenu: false,
  isWide: false,
};

export default Layout;
