import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { device } from '../../styles/constants';
import Card from '../Card';
import Step from './Step';
import StepConfig from './StepConfig';

const StepContainer = styled.div`
  display: table;
  @media ${device.mobile} {
    display: block;
  }
`;

// @ts-ignore
// @ts-ignore
const ProcedureStep = ({ simUserApplications, deliveries }) => {
  // @ts-ignore
  const steps = []
    .concat(
      simUserApplications.map(
        ({
          // @ts-ignore
          id,
          // @ts-ignore
          statusCode,
          // @ts-ignore
          applicationId,
          // @ts-ignore
          applicationDateTime,
          // @ts-ignore
          customerInfo,
          // @ts-ignore
          rejectionHistory,
        }) => {
          const stepConfigProps = {
            statusCode,
            customerInfo,
            applicationDateTime,
            applicationId,
            simUserApplicationId: id,
            rejectionHistory,
          };
          return (
            <StepConfig key={id} {...stepConfigProps}>
              {stepItemConfig => {
                if (stepItemConfig) {
                  return <Step {...stepItemConfig} />;
                }
              }}
            </StepConfig>
          );
        }
      )
    )
    .concat(
      deliveries.map(
        ({
          // @ts-ignore
          id,
          // @ts-ignore
          deliveryStatus,
          // @ts-ignore
          customerInfo,
          // @ts-ignore
          trackingNumber,
          // @ts-ignore
          shipDate,
          // @ts-ignore
          applicationDateTime,
          // @ts-ignore
          isMnp,
        }) => {
          const stepConfigProps = {
            deliveryStatus,
            customerInfo,
            trackingNumber,
            shipDate,
            applicationDateTime,
            isMnp,
          };
          return (
            <StepConfig key={id} {...stepConfigProps}>
              {stepItemConfig => {
                if (stepItemConfig) {
                  return <Step {...stepItemConfig} />;
                }
              }}
            </StepConfig>
          );
        }
      )
    );

  return (
    !!steps.length && (
      <Card header="完了していないお申し込みがあります">
        <StepContainer>{steps}</StepContainer>
      </Card>
    )
  );
};

ProcedureStep.propTypes = {
  simUserApplications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      statusCode: PropTypes.string.isRequired,
      applicationId: PropTypes.number.isRequired,
      applicationDateTime: PropTypes.string.isRequired,
      customerInfo: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  deliveries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      deliveryStatus: PropTypes.string.isRequired,
      customerInfo: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
      trackingNumber: PropTypes.string.isRequired,
      shipDate: PropTypes.string.isRequired,
      applicationDateTime: PropTypes.string.isRequired,
      isMnp: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default ProcedureStep;
