import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { theme, typo } from '../../styles';
import { device } from '../../styles/constants';

const WhiteCss = css`
  padding: 32px;
  margin-top: 16px;
  background: ${theme.box.white};
  white-space: pre-wrap;

  @media ${device.mobile} {
    padding: 24px 16px;
  }
`;

const RoundedCss = css`
  overflow: hidden;
  border-radius: 24px;
  :not(:first-child) {
    margin-top: 16px;
  }
  @media ${device.mobile} {
    border-radius: 10px;
  }
`;

const White = styled.div`
  ${WhiteCss};
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px ${({ theme }) => theme.box.boxShadow};

  @media ${device.mobile} {
    border-radius: 6px;
  }
`;

const GrayCss = css`
  ${WhiteCss};
  margin-top: 24px;
  background: ${theme.box.gray};
  ${typo.S};
  color: ${theme.text.secondary};
  line-height: 1.5;
`;

const RedCss = css`
  ${WhiteCss};
  padding: 16px 24px;
  background: ${theme.box.red};
  ${typo.M};
  line-height: 1.5;

  @media ${device.mobile} {
    padding: 16px;
    ${typo.XS};
  }
`;

const Gray = styled.div`
  ${GrayCss};
`;

const GrayCentered = styled.div`
  ${GrayCss};
  text-align: center;
`;

const Red = styled.div`
  ${RedCss};
`;
interface BalloonProps extends HTMLAttributes<HTMLDivElement> {
  isRight?: boolean;
}

const Balloon = styled.div<BalloonProps>`
  ${WhiteCss};
  position: relative;
  margin-top: 42px;
  background: ${({ theme }) => theme.box.balloon};
  border-radius: 10px;

  :before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: ${({ isRight }) => (isRight ? '75%' : '50%')};
    margin-left: -26px;
    border: 26px solid transparent;
    border-bottom: 26px solid ${({ theme }) => theme.box.balloon};

    @media ${device.mobile} {
      margin-left: -12px;
      border: 12px solid transparent;
      border-bottom: 12px solid ${({ theme }) => theme.box.balloon};
    }
  }
`;

const Rounded = styled.div`
  ${RoundedCss};
`;

const Boxes = {
  White,
  WhiteCss,
  Gray,
  GrayCentered,
  Red,
  RedCss,
  Balloon,
  Rounded,
  RoundedCss,
};

export default Boxes;
