import React from 'react';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import ErrorLayout from '../Layout/ErrorLayout';
import { getUnexpectedError } from '../ErrorBoundary/constants';
import Link from 'next/link';

export type ErrorButtonProps = {
  href: string;
  text: string;
};

type ErrorProps = {
  message?: React.ReactNode;
  button?: ErrorButtonProps;
};

const Error: React.FC<ErrorProps> = ({ message, button }) => {
  const displayMessage = message ?? getUnexpectedError();
  return (
    <ErrorLayout>
      <Texts.H1>エラー</Texts.H1>
      <Texts.Center>{displayMessage}</Texts.Center>
      <Button.Layout>
        {/* When error happens reload the homepage to clean up local state */}
        {button ? (
          <a href={button.href}>
            <Button.Secondary>{button.text}</Button.Secondary>
          </a>
        ) : (
          <Link href="/mypage">
            <Button.Secondary>マイページトップへ戻る</Button.Secondary>
          </Link>
        )}
      </Button.Layout>
    </ErrorLayout>
  );
};

export default Error;
