import React from 'react';
import styled from 'styled-components';
import { typo } from '../../styles';
import { device } from '../../styles/constants';
import Texts from '../Common/Texts';

export type Announcement = {
  title: string;
  url: string;
};

const Container = styled.div`
  display: inline-flex;
  overflow: hidden;
  padding: 8px 24px;
  background: ${({ theme }) => theme.announcements.background};
  border-radius: 10px;
  ${typo.XS};
  color: ${({ theme }) => theme.announcements.text};
  :not(:first-child) {
    margin-top: 16px;
  }
  line-height: 1.5;
  > div:first-child {
    flex: 1 0 auto;
  }

  @media ${device.mobile} {
    padding: 8px 16px;
    ${typo.XXS};
  }
`;

const ItemList = styled.ul`
  margin-left: 8px;
`;

const Item = styled.li`
  :before {
    content: '>';
    margin-right: 4px;
  }
  :not(:first-child) {
    margin-top: 8px;
  }
  > a {
    color: ${({ theme }) => theme.announcements.text};
  }
  > a:hover {
    color: ${({ theme }) => theme.announcements.textHovered};
  }
`;

const Announcements = ({
  announcements,
}: {
  announcements: Announcement[];
}) => {
  return (
    <Container>
      <div>お知らせ</div>
      <ItemList>
        {announcements.map(item => (
          <Item key={item.title}>
            <Texts.Link href={item.url}>{item.title}</Texts.Link>
          </Item>
        ))}
      </ItemList>
    </Container>
  );
};

export default Announcements;
