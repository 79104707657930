import { GlobalConfig } from '../../constants';

export const META_CONFIG = {
  '/mypage': {
    title: 'y.u mobile マイページ',
    canonical: `${GlobalConfig.clientUrl}/mypage`,
    description:
      'y.u mobileのマイページです。ご契約中のサービスのご利用状況の確認、データチャージ、SIMの各種お手続き、ご請求料金の確認、ご登録情報やお支払い方法などの変更ができます。',
    robots: 'all',
  },
  '/contact': {
    title: 'お問い合わせ | y.u mobile マイページ',
    canonical: `${GlobalConfig.clientUrl}/contact`,
    description:
      'y.u mobileが提供するMVNOサービスに関するお客様専用のお問い合わせ・サポートページです。',
    robots: 'all',
  },
  default: {
    title: 'マイページ',
    canonical: 'https://www.yumobile.jp',
    robots: 'noindex',
  },
};
