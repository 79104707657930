import React from 'react';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import { SimpleLayout } from '../Layout';
import { GlobalConfig } from '../../constants';

const EmailUnregisteredError: React.FC = () => {
  return (
    <SimpleLayout>
      <Texts.H1>U-NEXT ID作成（無料）</Texts.H1>
      <Texts.Body1>
        お客様が現在ログインしているU-NEXT
        IDは、メールアドレスが登録されていません。{'\n'}
        設定・サポートからメールアドレスを登録し、再度お申し込み手続きをお願いいたします。
      </Texts.Body1>
      <Button.Layout>
        <a href={`${GlobalConfig.myAccountUrl}/reset/email`}>
          <Button.Secondary>メールアドレスの登録へ</Button.Secondary>
        </a>
      </Button.Layout>
    </SimpleLayout>
  );
};

export default EmailUnregisteredError;
