import React from 'react';
import Link from 'next/link';
import useLogout from '../../hooks/useLogout';
import Button from '../Common/Button';
import Texts from '../Common/Texts';
import { SimpleLayout } from '../Layout';
import Boxes from '../Common/Boxes';

const HasApplicationError = () => {
  const logout = useLogout('/navlp');
  return (
    <SimpleLayout>
      <Texts.H1>お申し込み</Texts.H1>
      <Texts.Body2>
        お客様はすでに本サービスをお申し込み済みです。{'\n'}
        お申し込み内容はマイページからご確認ください。
      </Texts.Body2>
      <Boxes.Gray>
        <Texts.H4>新規プランのお申し込みをご希望の方</Texts.H4>
        <Texts.Body2>
          プラン契約ごとにU-NEXT IDが必要となります。
          {/* @ts-ignore */}
          <Button.TextLink onClick={logout}>ログアウト</Button.TextLink>
          し、U-NEXT IDを新たに作成し、お申し込みください。
        </Texts.Body2>
        <Texts.H4>追加SIMのお申し込みをご希望の方</Texts.H4>
        <Texts.Body2>
          マイページからお手続きいただけます。詳しくは
          <Link href="/mypage/contract" passHref>
            <Texts.Link>こちら</Texts.Link>
          </Link>
          。
        </Texts.Body2>
      </Boxes.Gray>
      <Button.Layout>
        <Link href="/mypage">
          <Button.Secondary>マイページへ</Button.Secondary>
        </Link>
      </Button.Layout>
    </SimpleLayout>
  );
};

export default HasApplicationError;
