import { gql } from '@apollo/client';
import { graphql } from '../gql/gql';

export const GET_ESCOTT_TOKEN_URL = graphql(`
  query getEScottTokenUrl {
    getEScottTokenUrl {
      tokenUrl
    }
  }
`);

export const GET_ESCOTT_TOKEN_STATUS = graphql(`
  query getEscottTokenStatus($token: String!) {
    getEscottTokenStatus(token: $token) {
      errorList {
        code
        message
      }
    }
  }
`);

export const GET_INITIAL_FEE = gql`
  query getInitialFee($simTypeEnum: SimTypeEnum!, $hasEntryCode: Boolean) {
    getApplicationFee(hasEntryCode: $hasEntryCode, simType: $simTypeEnum) {
      name
      feeWithTax
    }
  }
`;

export const DELETE_COOKIE = gql`
  mutation deleteCookie {
    deleteCookie
  }
`;

export const VERIFY_SIM_USER_SHARE_TOKEN = gql`
  query verifySimUserShareToken($token: String!) {
    verifySimUserApplicationConsentToken(token: $token) {
      status
      simUserApplicationId
      applicationId
      extras
    }
  }
`;

export const GET_SIM_USER_SHARE_TOKEN = gql`
  query getSimUserShareToken(
    $simUserApplicationId: ID!
    $applicationId: ID!
    $uploadToken: NumberString!
  ) {
    getSimUserApplicationConsentToken(
      simUserApplicationId: $simUserApplicationId
      applicationId: $applicationId
      extras: [$uploadToken]
    ) {
      token
    }
  }
`;
