import { useState, useCallback, useContext } from 'react';
import ReactModal from 'react-modal';
import { theme } from '../styles';
import { MediaContext } from '../context/MediaContext';

const overlayStyle = {
  backgroundColor: theme.modal.overlay,
  display: 'flex',
  alignItems: 'center',
  padding: 8,
};

const contentStyle = {
  position: 'relative',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  maxHeight: '100%',
  margin: '0 auto',
  maxWidth: 720,
  borderRadius: 20,
  padding: 40,
  boxSizing: 'border-box',
};

const mobileOverlayStyle = {
  ...overlayStyle,
  zIndex: 20,
};

const mobileContentStyle = {
  ...contentStyle,
  padding: 16,
};

/**
 * Example:
 *
 *  const [
 *    ReactModal,
 *    { modalIsOpen, openModal, closeModal, defaultStyle },
 *  ] = useModal();
 *
 *  return (
 *    <>
 *      <div>page</div>
 *      <button onClick={openModal}>open</button>
 *      <ReactModal
 *        isOpen={modalIsOpen}
 *        // required to close the modal when clicking outside
 *        onRequestClose={closeModal}
 *        style={defaultStyle}
 *      >
 *        <div>content</div>
 *        <button onClick={closeModal}>open</button>
 *      </ReactModal>
 *    </>
 *  );
 */

type Additives = {
  modalIsOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  defaultStyle: {};
};

const useModal = (isOpen?: boolean): [typeof ReactModal, Additives] => {
  const { isMobile } = useContext(MediaContext);

  const [modalIsOpen, setIsOpen] = useState(!!isOpen);

  const openModal = useCallback(() => setIsOpen(true), [setIsOpen]);

  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body');
  }

  return [
    ReactModal,
    {
      modalIsOpen,
      openModal,
      closeModal,
      defaultStyle: {
        overlay: isMobile ? mobileOverlayStyle : overlayStyle,
        content: isMobile ? mobileContentStyle : contentStyle,
      },
    },
  ];
};

export default useModal;
