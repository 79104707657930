import getConfig from 'next/config';
import STATUS from './status';
import { gql } from '@apollo/client';

export { STATUS };

export const GlobalConfig = getConfig().publicRuntimeConfig;

const encodedLoginCallbackUrl = encodeURIComponent(
  `${GlobalConfig.clientUrl}/login_callback`
);

export const getOAuthLoginLink = (backUrl = '/mypage') => {
  const encodedState = encodeURIComponent(`backUrl_${backUrl}`);
  return `${GlobalConfig.oauth2Url}/auth?client_id=unext&redirect_uri=${encodedLoginCallbackUrl}&response_type=code&scope=unext+offline&state=${encodedState}&nonce=some-random-nonce`;
};

export const getOAuthLogoutLink = (backUrl = '/mypage') => {
  return `${
    GlobalConfig.oauth2Url
  }/logout?client_id=unext&logout_uri=${encodeURIComponent(
    `${GlobalConfig.clientUrl}${backUrl}`
  )}`;
};

export const GA_MEASUREMENT_ID = 'UA-155002954-1';
export const GA4_TRACKING_ID = 'G-5Y2JDQ0RVF';

export const GET_DATA_USAGE_INFO = gql`
  query getDataUsageInfo(
    $usageStartDate: DateTime!
    $usageEndDate: DateTime!
    $monthlyUsageStartDate: DateTime!
    $monthlyUsageEndDate: DateTime!
  ) {
    getUser {
      id
      mobileServiceAccount {
        userPlatformId
        dataUsageInfo {
          currentRemainingCapacity
          usageHistory(startDate: $usageStartDate, endDate: $usageEndDate) {
            date
            usage
          }
        }
        autoDataChargeSetting {
          upperLimit
          monthlyDataChargeValue
        }
      }
    }
    getDataUsageHistory(
      startDate: $monthlyUsageStartDate
      endDate: $monthlyUsageEndDate
      groupByMonth: true
    ) {
      date
      usage
    }
  }
`;

export const GET_DATA_CHARGE_INFO = gql`
  query getDataChargeInfo {
    getDataChargeProducts {
      id
      priceIncludingTax
      discountAmountWithTax
      capacity
    }
    getUser {
      id
      points
    }
  }
`;

export const UPDATE_POINT = gql`
  query updatePoint {
    getUser {
      id
      points
      mobileServiceAccount {
        userPlatformId
        dataUsageInfo {
          currentRemainingCapacity
        }
      }
    }
  }
`;

export const MUTATE_DATA_CHARGE = gql`
  mutation chargeData($dataChargeProductId: ID!) {
    chargeData(dataChargeProduct: { id: $dataChargeProductId }) {
      status
    }
  }
`;

export const GET_AUTO_CHARGE_INFO = gql`
  query getAutoChargeInfo {
    getUser {
      id
      points
      mobileServiceAccount {
        autoDataChargeSetting {
          upperLimit
          versionNo
        }
      }
    }
  }
`;

export const MUTATE_AUTO_CHARGE_SETTING = gql`
  mutation configurAutoCharge($upperLimit: Int!, $versionNo: Int!) {
    configureAutoDataCharge(
      dataChargeSetting: { upperLimit: $upperLimit, versionNo: $versionNo }
    ) {
      autoDataChargeSetting {
        upperLimit
        versionNo
      }
    }
  }
`;

export const GET_CONTRACT_CONTENT = gql`
  query getContractContent {
    getAccount {
      userPlatformId
      runningPrice {
        id
        priceIncludingTax
      }
      simUsers {
        id
        customerInfo {
          id
          firstName
          lastName
        }
        simCard {
          serialNo
          phoneNumber
        }
        activeSimType {
          id
          simTypeEnum
        }
        activeVoicePlan {
          id
          service {
            id
            displayRunningName
          }
        }
        activeSimOptions {
          ... on RunningCallWaitingSimOption {
            id
            statusCode
            simOptionTypeEnum
            service {
              id
              displayRunningName
            }
          }
          ... on RunningVoiceMailSimOption {
            id
            statusCode
            simOptionTypeEnum
            service {
              id
              displayRunningName
            }
          }
          ... on RunningInternationalCallingSimOption {
            id
            statusCode
            simOptionTypeEnum
            service {
              id
              displayRunningName
            }
          }
          ... on RunningRoamingSimOption {
            id
            statusCode
            simOptionTypeEnum
            service {
              id
              displayRunningName
            }
          }
        }
        statusCode
      }
      activePlan {
        id
        planTypeEnum
        service {
          id
          displayRunningName
        }
        applicationDateTime
        serviceStartDateTime
      }
      activeOptions {
        runningServiceId
        optionTypeEnum
        service {
          id
          displayRunningName
        }
      }
    }
    getSimTypes {
      id
      simTypeEnum
      runningPrice {
        id
        priceIncludingTax
      }
    }
    getOptions {
      id
      displayRunningName
      optionTypeEnum
    }
  }
`;

export const GET_CONTRACT_DETAIL = gql`
  query getContractDetail(
    $id: ID!
    $customerContractDocumentId: ID!
    $planContractDocumentId: ID
  ) {
    getMobileServiceContract(
      contractDocumentId: $id
      customerContractDocumentId: $customerContractDocumentId
      planContractDocumentId: $planContractDocumentId
    ) {
      id
      name
      typeCode
      applicationDateTime
      serviceStartDateTime
      contractorName
      plan {
        name
        priceIncludingTax
      }
      simUsers {
        name
        simType {
          name
          priceIncludingTax
        }
        voicePlan {
          name
          priceIncludingTax
        }
        phoneNumber
      }
      options {
        name
        priceIncludingTax
      }
      administrativeFees {
        name
        priceIncludingTax
      }
      appliedCampaigns {
        code
        displayTitle
        campaignPdfUrl
      }
      contractDocumentUri
    }
  }
`;

export const GET_CONTRACT_HISTORY = gql`
  query getContractHistory {
    getMobileServiceContractHistory {
      id
      name
      applicationDateTime
      customerContractDocumentId
      planContractDocumentId
    }
  }
`;

export const GET_OPTION = gql`
  query getOption($optionID: ID) {
    getOption(optionID: $optionID) {
      id
      runningTypeCode
      optionTypeEnum
      displayRunningName
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const ADD_OPTION = gql`
  mutation addOption($option: OptionInput!, $entryParams: WebEntryParams!) {
    addOption(option: $option, entryParams: $entryParams) {
      userPlatformId
      activeOptions {
        runningServiceId
        ... on RunningIFilter {
          id
          oemlsLicenseKey
        }
      }
    }
  }
`;

export const GET_OPTION_CONTENT = gql`
  query getOptionContent($optionID: ID) {
    getAccount {
      userPlatformId
      activeOptions {
        runningServiceId
        optionTypeEnum
        applicationDateTime
        ... on RunningIFilter {
          id
          oemlsLicenseKey
        }
      }
    }
    getOption(optionID: $optionID) {
      id
      displayRunningName
      optionTypeEnum
    }
  }
`;

export const GET_OPTION_REMOVE_CONTENT = gql`
  query getOptionRemoveContent($optionID: ID) {
    getAccount {
      userPlatformId
      activeOptions {
        runningServiceId
        optionTypeEnum
        service {
          id
          displayRunningName
          runningTypeCode
        }
        ... on RunningIFilter {
          id
          versionNo
        }
      }
    }
    getOption(optionID: $optionID) {
      id
      displayRunningName
    }
  }
`;

export const REMOVE_OPTION = gql`
  mutation removeOption(
    $optionId: ID!
    $option: OptionInput!
    $versionNo: Int!
  ) {
    removeOption(optionId: $optionId, option: $option, versionNo: $versionNo) {
      userPlatformId
      activeOptions {
        runningServiceId
        ... on RunningIFilter {
          id
        }
      }
    }
  }
`;

export const GET_ACCOUNT = gql`
  query getAccount {
    getAccount {
      userPlatformId
      noOfActiveSimUsers
      simUsers {
        id
        mnpNumber {
          id
          statusCode
        }
      }
    }
  }
`;

export const CANCEL_PLAN = gql`
  mutation cancelPlan {
    cancelPlan {
      status
    }
  }
`;

export const GET_SIM_CONTENT = gql`
  query getSimContent {
    getAccount {
      userPlatformId
      simUsers {
        id
        simCard {
          serialNo
          phoneNumber
        }
        activeSimType {
          id
          simTypeEnum
          applicationDateTime
          serviceStartDateTime
          service {
            id
            displayRunningName
          }
        }
        activeVoicePlan {
          id
          upcomingVoicePlanName
          upcomingVoicePlanStartDate
          service {
            id
            displayRunningName
            runningPrice {
              id
              priceIncludingTax
            }
          }
        }
        activeSimOptions {
          ... on RunningCallWaitingSimOption {
            id
            statusCode
            service {
              id
            }
          }
          ... on RunningVoiceMailSimOption {
            id
            statusCode
            service {
              id
            }
          }
          ... on RunningInternationalCallingSimOption {
            id
            statusCode
            service {
              id
            }
          }
          ... on RunningRoamingSimOption {
            id
            statusCode
            service {
              id
            }
          }
        }
        mnpNumber {
          id
          statusCode
          number
          issueDate
          expiryDate
        }
        hasDelivery
        statusCode
      }
    }
    getSimOptions {
      id
      displayRunningName
      simOptionTypeEnum
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const GET_SIM_CONTENT_FOR_MNP = gql`
  query getSimContentForMNP {
    getAccount {
      userPlatformId
      simUsers {
        id
        statusCode
        mnpNumber {
          id
          statusCode
        }
      }
    }
  }
`;

export const ISSUE_MNP_NUMBER = gql`
  mutation issueMnpNumber($simUserId: ID!) {
    issueMnpNumber(simUserId: $simUserId) {
      id
    }
  }
`;

export const GET_TERMINATE_SIM_CONTENT = gql`
  query getSimContentForTerminate {
    getAccount {
      userPlatformId
      simUsers {
        id
        statusCode
        mnpNumber {
          id
          statusCode
        }
        versionNo
      }
    }
  }
`;

export const TERMINATE_SIM_USER = gql`
  mutation terminateSimUser($simUserId: Int!, $versionNo: Int!) {
    terminateSimUser(simUserId: $simUserId, versionNo: $versionNo) {
      status
      simUser {
        id
        statusCode
      }
    }
  }
`;

export const GET_VOICE_PLAN_INFO = gql`
  query getVoicePlanInfo {
    getAccount {
      userPlatformId
      simUsers {
        id
        activeSimType {
          id
          simTypeEnum
        }
        activeVoicePlan {
          id
          upcomingVoicePlanName
          upcomingVoicePlanStartDate
          service {
            id
            displayRunningName
            displayCaption
            runningPrice {
              id
              priceIncludingTax
            }
          }
          versionNo
        }
      }
    }
    getVoicePlans {
      id
      displayRunningName
      displayCaption
      runningTypeCode
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const ADD_VOICE_PLAN = gql`
  mutation addVoicePlan(
    $simUserId: ID!
    $voicePlan: VoicePlanInput!
    $landingPageDetails: LandingPageInput
  ) {
    addVoicePlan(
      simUserId: $simUserId
      voicePlan: $voicePlan
      landingPageDetails: $landingPageDetails
    ) {
      userPlatformId
      simUsers {
        id
        activeVoicePlan {
          id
          upcomingVoicePlanName
          upcomingVoicePlanStartDate
          versionNo
        }
      }
    }
  }
`;

export const UPDATE_VOICE_PLAN = gql`
  mutation updateVoicePlan(
    $simUserId: ID!
    $currentVoicePlanId: ID!
    $voicePlan: VoicePlanInput!
    $versionNo: Int!
    $landingPageDetails: LandingPageInput
  ) {
    updateVoicePlan(
      simUserId: $simUserId
      currentVoicePlanId: $currentVoicePlanId
      voicePlan: $voicePlan
      versionNo: $versionNo
      landingPageDetails: $landingPageDetails
    ) {
      userPlatformId
      simUsers {
        id
        activeVoicePlan {
          id
          upcomingVoicePlanName
          upcomingVoicePlanStartDate
          versionNo
        }
      }
    }
  }
`;

export const CANCEL_VOICE_PLAN_UPDATE = gql`
  mutation cancelVoicePlanUpdate(
    $simUserId: ID!
    $currentVoicePlanId: ID!
    $versionNo: Int!
  ) {
    cancelVoicePlanUpdate(
      simUserId: $simUserId
      currentVoicePlanId: $currentVoicePlanId
      versionNo: $versionNo
    ) {
      userPlatformId
      simUsers {
        id
        activeVoicePlan {
          id
          upcomingVoicePlanName
          upcomingVoicePlanStartDate
          versionNo
        }
      }
    }
  }
`;

export const GET_SIM_OPTION = gql`
  query getSimOption($simOptionId: ID!) {
    getAccount {
      userPlatformId
      simUsers {
        id
      }
    }
    getSimOption(simOptionID: $simOptionId) {
      id
      runningTypeCode
      displayRunningName
      simOptionTypeEnum
      runningPrice {
        id
        priceIncludingTax
      }
    }
  }
`;

export const ADD_SIM_OPTION = gql`
  mutation addSimOption(
    $simUserId: ID!
    $simOption: SimOptionInput!
    $entryLandingPageCode: String!
    $entryParams: WebEntryParams!
  ) {
    addSimOption(
      simUserId: $simUserId
      simOption: $simOption
      entryLandingPageCode: $entryLandingPageCode
      entryParams: $entryParams
    ) {
      userPlatformId
      simUsers {
        id
        statusCode
        activeSimOptions {
          ... on RunningCallWaitingSimOption {
            id
            service {
              id
            }
          }
          ... on RunningVoiceMailSimOption {
            id
            service {
              id
            }
          }
          ... on RunningInternationalCallingSimOption {
            id
            service {
              id
            }
          }
          ... on RunningRoamingSimOption {
            id
            service {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_SIM_OPTION_FOR_REMOVE = gql`
  query getSimOptionForRemove($simOptionId: ID!) {
    getSimOption(simOptionID: $simOptionId) {
      id
      displayRunningName
      simOptionTypeEnum
    }
    getAccount {
      userPlatformId
      simUsers {
        id
        activeSimOptions {
          ... on RunningCallWaitingSimOption {
            id
            service {
              id
            }
          }
          ... on RunningVoiceMailSimOption {
            id
            service {
              id
            }
          }
          ... on RunningInternationalCallingSimOption {
            id
            service {
              id
            }
          }
          ... on RunningRoamingSimOption {
            id
            service {
              id
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_SIM_OPTION = gql`
  mutation removeSimOption(
    $simUserId: ID!
    $runningSimOptionId: ID!
    $simOptionTypeEnum: SimOptionTypeEnum!
  ) {
    removeSimOption(
      simUserId: $simUserId
      runningSimOptionId: $runningSimOptionId
      simOptionTypeEnum: $simOptionTypeEnum
    ) {
      userPlatformId
      simUsers {
        id
        statusCode
        activeSimOptions {
          ... on RunningCallWaitingSimOption {
            id
            service {
              id
            }
          }
          ... on RunningVoiceMailSimOption {
            id
            service {
              id
            }
          }
          ... on RunningInternationalCallingSimOption {
            id
            service {
              id
            }
          }
          ... on RunningRoamingSimOption {
            id
            service {
              id
            }
          }
        }
      }
    }
  }
`;

export const CONSENT_SIM_USER_APPLICATION = gql`
  mutation consentSimUserApplication($token: String!) {
    consentSimUserApplication(token: $token) {
      status
    }
  }
`;

export const GET_AVAILABLE_PLANS = gql`
  query getAvailablePlans {
    getAccount {
      userPlatformId
      versionNo
      activePlan {
        id
        service {
          id
          includesVideoSubscription
        }
      }
      simUsers {
        id
        activeSimType {
          id
          service {
            id
            runningPrice {
              id
              priceIncludingTax
            }
          }
        }
      }
    }
    getAvailablePlans {
      availablePlans {
        id
        planTypeEnum
        displayRunningName
        displayCaption
        includesVideoSubscription
        runningTypeCode
        runningPrice {
          id
          priceIncludingTax
        }
      }
    }
  }
`;

export const UPDATE_PLAN = gql`
  mutation updatePlan(
    $plan: PlanInput!
    $landingPageDetails: LandingPageInput
    $versionNo: Int!
  ) {
    updatePlan(
      plan: $plan
      landingPageDetails: $landingPageDetails
      versionNo: $versionNo
    ) {
      userPlatformId
      activePlan {
        id
        planTypeEnum
        service {
          id
          includesVideoSubscription
        }
      }
      versionNo
    }
  }
`;

export const GET_DEVICE_ORDERS = gql`
  query getDevicePurchaseHistory {
    devicePurchaseEligibilityCheck {
      canPurchaseDevices
    }
    getDevicePurchaseHistory {
      id
      statusCode
      reservationDateTime
      purchaseDateTime
      configurationName
    }
  }
`;

export const GET_DEVICE_ORDER = gql`
  query getDevicePurchase($id: ID!) {
    getDevicePurchase(devicePurchaseId: $id) {
      id
      configurationName
      customerInfo {
        addressLine1
        addressLine2
        addressLine3
        firstName
        lastName
        phoneNumber
        postalCode
        prefecture
      }
      deliveryCompanyName
      deliveryTrackingNo
      deliveryTrackingUrl
      imei
      orderNo
      priceIncludingTax
      versionNo
      statusCode
      purchaseDateTime
      reservationDateTime
      seller
      shippingDateTime
    }
  }
`;

export const CANCEL_DEVICE_PURCHASE = gql`
  mutation cancelDevicePurchase($id: ID!, $versionNo: Int!) {
    cancelDevicePurchase(devicePurchaseId: $id, versionNo: $versionNo) {
      id
      statusCode
    }
  }
`;
